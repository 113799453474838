import type { SvpCategoryAdditional } from '@vgtv/api-client/lib/svp_category';

import { getProvider } from '../svpProvider';
import { getLogger } from '../logger';
import { fetchCategories as fetchCategoriesFromSvp } from '../svp';

import { requestAPI } from './shared/requestAPI';

const logger = getLogger('categoriesApi');

type CategoryMetadata = {
  verticalPoster?: string;
};

export async function updateCategoryMetadata(
  categoryId: number,
  metadata: Partial<CategoryMetadata>
) {
  try {
    return await requestAPI<SvpCategoryAdditional>(
      `/${getProvider()}/admin/categories/${categoryId}/metadata`,
      {
        method: 'PATCH',
        body: JSON.stringify(metadata),
        headers: { 'Content-Type': 'application/json' },
      }
    );
  } catch (error) {
    logger.error('Failed to update category', {
      categoryId,
      metadata,
    });
    throw error;
  }
}

export const fetchCategories = async ({ ids }: { ids: number[] }) => {
  if (!ids.length) {
    return [] as SvpCategoryAdditional[];
  }

  return fetchCategoriesFromSvp().then((categories) =>
    categories.filter((category) => ids.includes(category.id))
  );
};
