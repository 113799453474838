import {
  ComponentType,
  DataType,
  AssetDataSource,
  CategoryDataSource,
  ProfileSource,
  StayLiveSource,
} from '@vgtv/api-client/lib/component';

export const componentsConfiguration = {
  componentsTypes: [
    ComponentType.LIST,
    ComponentType.SLIDER,
    ComponentType.SPLASH,
    ComponentType.EDITORIAL,
    ComponentType.FILTERED_LIST,
    ComponentType.LIVE_ASSETS_SCHEDULE,
    ComponentType.CONTINUE_WATCHING,
    ComponentType.CAROUSEL,
  ],
  dataSources: {
    // aligned with `dataSchemaConfiguration` in API
    [ComponentType.LIST]: [
      {
        dataType: DataType.ASSETS,
        endpoints: [
          AssetDataSource.SVP_SEARCH,
          AssetDataSource.SVP_MOST_SEEN,
          AssetDataSource.SVP_ASSETS,
        ],
        preset: true,
      },
      {
        dataType: DataType.CATEGORIES,
        endpoints: [CategoryDataSource.SVP_CATEGORIES],
        preset: true,
      },
      {
        dataType: DataType.MIXED,
        preset: true,
      },
    ],
    [ComponentType.CONTINUE_WATCHING]: [
      {
        dataType: DataType.ASSETS,
        endpoints: [AssetDataSource.SVP_SEARCH, AssetDataSource.SVP_ASSETS],
      },
    ],
    [ComponentType.SLIDER]: [
      {
        dataType: DataType.ASSETS,
        endpoints: [
          AssetDataSource.SVP_SEARCH,
          AssetDataSource.SVP_MOST_SEEN,
          AssetDataSource.SVP_ASSETS,
        ],
        preset: true,
      },
      {
        dataType: DataType.MIXED,
        preset: true,
      },
      {
        dataType: DataType.STORIES,
        endpoints: [AssetDataSource.SVP_SEARCH],
        preset: true,
      },
      {
        dataType: DataType.CATEGORIES,
        endpoints: [CategoryDataSource.SVP_CATEGORIES],
        preset: true,
      },
      {
        dataType: DataType.PROFILES,
        endpoints: [ProfileSource.PROFILES],
        preset: true,
      },
      {
        dataType: DataType.STAYLIVE_STREAMS,
        endpoints: [StayLiveSource.STAYLIVE_CHANNEL],
      },
      {
        dataType: DataType.SEASONS,
      },
    ],
    [ComponentType.SPLASH]: [
      {
        dataType: DataType.ASSETS,
        preset: true,
      },
      {
        dataType: DataType.CATEGORIES,
        preset: true,
      },
    ],
    [ComponentType.CAROUSEL]: [
      {
        dataType: DataType.ASSETS,
        endpoints: [AssetDataSource.SVP_SEARCH],
        preset: true,
      },
      {
        dataType: DataType.CATEGORIES,
        preset: true,
      },
      {
        dataType: DataType.MIXED,
        preset: true,
      },
    ],
    [ComponentType.EDITORIAL]: [
      {
        dataType: DataType.ASSETS,
        preset: true,
      },
    ],
  },
} as const;
