import type { VFC } from 'react';
import React from 'react';

import { Select } from '../Select/Select';
import { useStayLiveChannels } from '../../../hooks/useStayLiveChannels';

type Props = {
  label: string;
  name: string;
  allowEmpty?: boolean;
  disabled?: boolean;
  size?: 'big' | 'small' | 'compact';
  className?: string;
};

export const StayliveChannelsSelect: VFC<Props> = ({ ...props }) => {
  const { data: channels = [], isLoading } = useStayLiveChannels();

  const options = channels.map(({ id, name }) => ({
    value: id.toString(),
    label: name,
  }));

  return <Select {...props} options={options} isLoading={isLoading} />;
};
