import { request } from '../../../utils';
import { config } from '../../../config';
import { getAuthToken } from '../../../helpers/authToken';
import { ValidationError } from '../../ValidationError';

type APIError = {
  code: string;
  exact: boolean;
  inclusive: boolean;
  message: string;
  path: string[];
  type: string;
};

type APIErrorResponse = {
  message: string;
  errors: APIError[];
};

export const isSuccessResponse = <T>(blob: T | APIErrorResponse): blob is T => {
  return !(blob && 'errors' in (blob as object));
};

export async function requestAPI<T>(
  path: string,
  fetchOptions: RequestInit = {}
) {
  const { headers = {} } = fetchOptions;

  const response = await request<T | APIErrorResponse>(
    `${config.apiHostname}${path}`,
    {
      ...fetchOptions,
      headers: { ...headers, Authorization: `Bearer JWT ${getAuthToken()}` },
    }
  );

  if (response.status >= 200 && response.status < 300) {
    if (response.status !== 204) {
      return response.json();
    }
  } else if (response.status === 401) {
    throw new Error(
      `It appears that your session is no longer valid. Refresh the page and login before continuing.`
    );
  } else {
    let error;
    try {
      error = new ValidationError((await response.json()) as APIErrorResponse);
    } catch {
      error = new ValidationError({
        message: response.statusText,
      });
    }
    throw error;
  }
}
