import { useEffect, useMemo, useState } from 'react';

/**
 * @param {array} items - items to paginate
 * @param {number} limit - number of items per page
 * @return {{paginated: array, setCurrentPage: function, pagesLength: number, currentPage: number}}
 */
function usePagination(items, limit) {
  const [currentPage, setCurrentPage] = useState(0);
  const [paginated, setPaginated] = useState([]);

  useEffect(() => {
    setCurrentPage(0);
  }, [items, limit]);

  useEffect(() => {
    const start = currentPage * limit;
    const end = start + limit;

    setPaginated(items.slice(start, end));
  }, [items, limit, currentPage]);

  const pagesLength = useMemo(
    () => Math.ceil(items.length / limit),
    [items, limit]
  );

  return {
    currentPage,
    paginated,
    setCurrentPage,
    pagesLength,
  };
}

export { usePagination };
