import React, { type VFC } from 'react';

import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Thumbnail } from '../Thumbnail/Thumbnail';
import { formatDateTime, formatDuration } from '../../utils';
import { Spacer } from '../Spacer/Spacer';

import styles from './AssetsList.module.scss';

type Props = {
  assets: SvpAssetAdditional[];
  useVerticalThumbnails?: boolean;
  getLink: (asset: SvpAssetAdditional) => string;
};

const emptyList: SvpAssetAdditional[] = [];
Object.freeze(emptyList);
export const AssetsList: VFC<Props> = ({
  assets,
  getLink,
  useVerticalThumbnails = false,
}) => {
  return (
    <div className={classNames(styles.assetsList)}>
      {assets.map((asset) => {
        const imageBase = useVerticalThumbnails
          ? asset.additional.metadata?.verticalPosterImage
          : asset.images?.main;
        const imageUrl = imageBase ? `${imageBase}?t[]=x360q80` : undefined;

        return (
          <Link to={getLink(asset)} key={asset.id} className={styles.item}>
            <Thumbnail
              className={styles.thumbnail}
              aspectRatio={useVerticalThumbnails ? '2/3' : '16/9'}
              url={imageUrl}
            />
            <div className={styles.details}>
              <div className={styles.title}>{asset.title}</div>
              <span className={styles.subtitle}>
                {asset.series && (
                  <strong>
                    S{asset.series.seasonNumber}E{asset.series.episodeNumber}
                  </strong>
                )}{' '}
                {asset.category.title}
              </span>
              <span className={styles.subtitle}>
                {asset.streamType === 'live'
                  ? 'Live stream'
                  : formatDuration(asset.duration)}
              </span>
              <Spacer />
              <span className={styles.muted}>
                {formatDateTime(asset.created * 1000)}
              </span>
            </div>
          </Link>
        );
      })}
    </div>
  );
};
