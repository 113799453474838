import React from 'react';
import PropTypes from 'prop-types';

import { Thumbnail } from '../../../Thumbnail/Thumbnail';

import styles from './Profile.module.scss';

function Profile({ profile, className }) {
  return (
    <div className={className}>
      {profile.images && (
        <Thumbnail
          url={`${profile.images.main}?t[]=180q80`}
          alt={profile.title}
          isRound
        />
      )}
      <div className={styles.title}>{profile.title}</div>
    </div>
  );
}

Profile.propTypes = {
  profile: PropTypes.shape({
    images: PropTypes.shape({ main: PropTypes.string.isRequired }),
    title: PropTypes.string,
  }),
  className: PropTypes.string,
};

export { Profile };
