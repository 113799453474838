import PropTypes from 'prop-types';
import React from 'react';

import { useHistory } from 'react-router-dom';

import { UserInitials } from '../../UserInitials/UserInitials';

import styles from './UserRow.module.scss';

function UserRow({ user }) {
  const history = useHistory();

  const { id, firstName, lastName, email, role } = user;

  return (
    <tr onClick={() => history.push(`/users/${id}`)} className={styles.row}>
      <td>
        <UserInitials user={user} className={styles.initials} />
        {firstName} {lastName}
      </td>
      <td>{email}</td>
      <td>{role}</td>
    </tr>
  );
}

UserRow.propTypes = {
  user: PropTypes.object.isRequired,
};

export { UserRow };
