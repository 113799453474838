import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';
import {
  AssetDataSource,
  ComponentType,
  DataType,
  StayLiveSource,
} from '@vgtv/api-client/lib/component';
import { Provider } from '@vgtv/api-client';

import { Select } from '../../Form/Select/Select';
import { capitalizeFirstLetter } from '../../../utils';
import { usePreviousValue } from '../../../hooks/usePreviousValue';
import { ComponentContext } from '../../../contexts/ComponentContext';
import { PresetPicker } from '../../PresetPicker/PresetPicker';
import { componentsConfiguration } from '../configuration';
import { StayliveChannelsSelect } from '../../Form/StayliveChannelsSelect/StayliveChannelsSelect';
import { getProvider } from '../../../services/svpProvider';

import { EndpointParamsPicker } from './EndpointParamsPicker/EndpointParamsPicker';

const deprecatedEndpoints = {
  [AssetDataSource.SVP_ASSETS]: AssetDataSource.SVP_SEARCH,
};

const DeprecationNotice = ({ oldValue, newValue }) => {
  return (
    <>
      <s>{oldValue}</s> (use {newValue} instead)
    </>
  );
};

DeprecationNotice.propTypes = {
  oldValue: PropTypes.string.isRequired,
  newValue: PropTypes.string.isRequired,
};

function DataSource({ inputsSize }) {
  const { values: component, setFieldValue } = useFormikContext();
  const provider = getProvider();

  const componentConfig = componentsConfiguration.dataSources[component.type];

  const configuration = componentConfig
    ? componentConfig.filter((dataSource) => {
        if (
          dataSource.dataType === DataType.STAYLIVE_STREAMS &&
          provider !== Provider.AB
        ) {
          return false;
        }

        if (
          dataSource.dataType === DataType.STORIES &&
          provider !== Provider.VGTV
        ) {
          return false;
        }

        return true;
      })
    : undefined;

  const data = component?.data;
  const dataType = data?.type ?? null; // assets, categories, etc.
  const dataPreset = data?.preset ?? [];
  const dataSource = data?.source ?? {};
  const dataSourceEndpoint = dataSource?.endpoint;

  const dataSourceConfiguration =
    dataType && configuration
      ? configuration.find((config) => config.dataType === dataType)
      : null;

  const previousEndpoint = usePreviousValue(dataSourceEndpoint);
  const previousDataType = usePreviousValue(dataType);

  // clear the params list when endpoint is changed
  useEffect(() => {
    if (previousEndpoint && dataSourceEndpoint !== previousEndpoint) {
      setFieldValue('data.source.params', {});
    }
  }, [setFieldValue, previousEndpoint, dataSourceEndpoint]);

  // clear the preset when data type has changed
  useEffect(() => {
    if (previousDataType && dataType !== previousDataType) {
      setFieldValue('data.preset', undefined);
    }
  }, [setFieldValue, previousDataType, dataType]);

  return !configuration ? null : (
    <ComponentContext.Provider value={{ component }}>
      <Select
        label="Data type"
        name="data.type"
        options={configuration.map(({ dataType }) => ({
          value: dataType,
          label: capitalizeFirstLetter(dataType),
        }))}
        size={inputsSize}
        allowEmpty={false}
      />
      {dataSourceConfiguration && dataSourceConfiguration.endpoints && (
        <Fragment>
          {dataPreset.length === 0 && (
            <Select
              label="Data source"
              name="data.source.endpoint"
              size={inputsSize}
              options={dataSourceConfiguration.endpoints.map((endpoint) => ({
                value: endpoint,
                label:
                  endpoint in deprecatedEndpoints ? (
                    <DeprecationNotice
                      oldValue={endpoint}
                      newValue={deprecatedEndpoints[endpoint]}
                    />
                  ) : (
                    endpoint
                  ),
              }))}
              allowEmpty={false}
            />
          )}
          {dataSource.endpoint &&
            dataPreset.length === 0 &&
            dataSource.endpoint !== StayLiveSource.STAYLIVE_CHANNEL && (
              <EndpointParamsPicker
                dataType={dataType}
                endpoint={dataSource.endpoint}
                params={dataSource.params}
                onSubmit={(params) => {
                  setFieldValue('data.source.params', params);
                }}
              />
            )}
          {dataSource.endpoint === StayLiveSource.STAYLIVE_CHANNEL && (
            <StayliveChannelsSelect
              label="Channel"
              name="data.source.params.channelId"
            />
          )}
        </Fragment>
      )}
      {dataSourceConfiguration && dataSourceConfiguration.preset && (
        <PresetPicker
          dataType={dataType}
          presetItems={dataPreset}
          onChange={(preset) => setFieldValue('data.preset', preset)}
          singleItem={component.type === ComponentType.SPLASH}
        />
      )}
    </ComponentContext.Provider>
  );
}

DataSource.propTypes = {
  configuration: PropTypes.arrayOf(PropTypes.object),
  inputsSize: PropTypes.string.isRequired,
};

export { DataSource };
