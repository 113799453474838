import { request } from '../utils';

export type StayLiveChannel = {
  id: number;
  name: string;
  path: string;
  image: string;
  expose: number;
  channelImage: string;
};

type StayLiveResponse = {
  message: {
    channels: StayLiveChannel[];
    collection: {
      name: string;
      id: number;
      image: string;
      header_image: string;
      description: string;
    };
  };
  statuscode: number;
  success: boolean;
};

async function fetchChannels() {
  const basePath = `https://api.staylive.tv/collections/feed/67`;

  try {
    const response = await request<StayLiveResponse>(basePath);

    const json = await response.json();

    return json.message.channels || [];
  } catch {
    return [];
  }
}

export { fetchChannels };
