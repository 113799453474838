import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { LabeledContainer, Button } from '@schibsted-svp/react-ui';
import get from 'lodash/get';

import SortableList from '../SortableList/SortableList';
import { mapItemsToSelectOption } from '../../helpers/mapItemsToSelectOption';

import { AddFilterDialog } from './AddFilterDialog/AddFilterDialog';
import { Filter } from './Filter/Filter';
import { defaultFilters } from './types';

import styles from './FiltersPicker.module.scss';

function FiltersPicker({
  filters = [],
  onChange,
  allowedFilters = defaultFilters,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const options = useMemo(
    () => mapItemsToSelectOption(allowedFilters),
    [allowedFilters]
  );

  function onDialogClose() {
    setIsOpen(false);
  }

  function onFilterAdd(filter) {
    onChange([...filters, filter]);
  }

  function onFilterRemove(filter) {
    const filterId = get(filter, [filter.type, 'id']);

    onChange(
      filters.filter((filter) => {
        const id = get(filter, [filter.type, 'id']);

        return id !== filterId;
      })
    );
  }

  return (
    <LabeledContainer label="Filters">
      <div className={styles.container}>
        {filters.length > 0 && (
          <SortableList
            className={styles.sortableList}
            compact
            items={filters}
            renderItem={(filter) => (
              <Filter filter={filter} onRemoveClick={onFilterRemove} />
            )}
            onChange={onChange}
            keyProperty={'id'}
          />
        )}
        <div className={styles.buttons}>
          <Button
            variant="standard"
            size="small"
            type="button"
            onClick={() => setIsOpen(true)}
          >
            + Add a filter
          </Button>
        </div>
        <AddFilterDialog
          isOpen={isOpen}
          onClose={onDialogClose}
          onAdd={onFilterAdd}
          filters={options}
        />
      </div>
    </LabeledContainer>
  );
}

FiltersPicker.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  allowedFilters: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

export { FiltersPicker };
