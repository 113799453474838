import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '@schibsted-svp/react-ui';

import styles from './UsePageTaxonomyCheckbox.module.scss';

function UsePageTaxonomyCheckbox({ taxonomy, checked, setFieldValue }) {
  return (
    <div className={styles.container}>
      <Checkbox
        label={`Use page ${taxonomy}`}
        checked={checked}
        onChange={(event) => {
          const value = event.target.checked;

          setFieldValue('options.usePageTaxonomy', value);
        }}
      />
    </div>
  );
}

UsePageTaxonomyCheckbox.propTypes = {
  taxonomy: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export { UsePageTaxonomyCheckbox };
