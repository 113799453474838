import { useRef, useEffect } from 'react';

export const usePreviousValue = <T>(value: T) => {
  const ref = useRef<T>(value);

  // store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // only re-run if value changes

  // return previous value (happens before update in useEffect above)
  return ref.current;
};
