import type { ChangeEvent, VFC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { Input } from '@schibsted-svp/react-ui';
import { DataType } from '@vgtv/api-client/lib/component';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

import * as svp from '../../../services/svp';
import { CenteredSpinner } from '../../CenteredSpinner/CenteredSpinner';
import { PresetItem } from '../PresetItem/PresetItem';
import { useDebounce } from '../../../hooks/useDebounce';
import { FormField } from '../../Form/shared/FormField/FormField';

import styles from './StoriesSearch.module.scss';

export const defaultStoryFilters = {
  tagsid: '57eff209-28df-4a1a-bf47-17c2fe4244c5',
  'additional.metadata.aspectRatio': '0.56',
};

type Props = {
  selectedStories: SvpAssetAdditional[];
  onAddClick: (item: SvpAssetAdditional) => void;
  onRemoveClick: (item: SvpAssetAdditional) => void;
  label?: string;
};

export const StoriesSearch: VFC<Props> = ({
  selectedStories = [],
  onAddClick,
  onRemoveClick,
  label = 'Search by title or id',
}) => {
  const [stories, setStories] = useState<SvpAssetAdditional[]>([]);
  const [query, setQuery] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const debouncedQuery = useDebounce(query, 300);

  const fetchStories = useCallback(async function (query: string) {
    setIsFetching(true);

    const assets = await svp.searchAssets({
      query,
      filter: { ...defaultStoryFilters },
    });

    setIsFetching(false);
    setStories(assets);
  }, []);

  useEffect(() => {
    if (debouncedQuery) {
      fetchStories(debouncedQuery);
    } else {
      setStories([]);
    }
  }, [fetchStories, debouncedQuery]);

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <FormField label={label}>
          <Input
            value={query}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              setQuery(value);
            }}
          />
        </FormField>
        {isFetching && <CenteredSpinner />}
        <ul className={styles.assetsList}>
          {stories.map((story) => {
            const isSelected = selectedStories
              .map((presetItem) => presetItem.id)
              .includes(story.id);

            return (
              <li key={story.id}>
                <PresetItem
                  item={story}
                  dataType={DataType.STORIES}
                  isSelected={isSelected}
                  onClick={() =>
                    isSelected ? onRemoveClick(story) : onAddClick(story)
                  }
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
