import React from 'react';

import {
  BrowserRouter as ReactRouter,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import { Header } from './components/Header/Header';
import { Pages } from './views/Pages';
import { Page } from './views/Page';
import { Components } from './views/Components';
import { Component } from './views/Component';
import { Tags } from './views/Tags';
import { Tag } from './views/Tag';
import { Asset } from './views/Asset';
import { Category } from './views/Category';
import { Container } from './components/Container/Container';
import { Editors } from './views/Editors';
import { Editor } from './views/Editor';
import { Users } from './views/Users';
import { User } from './views/User';
import { PromotionPictures } from './views/PromotionPictures';
import { Assets } from './views/Assets';

import styles from './Router.module.scss';

function Router() {
  return (
    <ReactRouter>
      <Header />
      <Container className={styles.container}>
        <Switch>
          <Redirect path="/login" to="/pages" />
          <Route path="/pages/:pageId" component={Page} />
          <Route path="/pages" component={Pages} />
          <Route path="/components/:componentId" component={Component} />
          <Route path="/components" component={Components} />
          <Route path="/tags/:tagId" component={Tag} />
          <Route path="/tags" component={Tags} />
          <Route path="/assets/:assetId/edit" component={Asset} />
          <Route path="/assets/:kind?" component={Assets} />
          <Route path="/categories/:categoryId?" component={Category} />
          <Route path="/editors/:editorId" component={Editor} />
          <Route path="/editors" component={Editors} />
          <Route path="/users/:userId" component={User} />
          <Route path="/users" component={Users} />
          <Route path="/pictures" component={PromotionPictures} />
          <Redirect from="/" exact to="/pages" />
        </Switch>
      </Container>
    </ReactRouter>
  );
}

export { Router };
