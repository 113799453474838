import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from '@schibsted-svp/react-ui';
import { Provider } from '@vgtv/api-client';

import { overrideProvider } from '../../../services/svpProvider';

function ProviderSelect({ children }) {
  const options = [
    {
      value: Provider.VGTV,
      label: 'VGTV',
    },
    {
      value: Provider.AB,
      label: 'ABTV',
    },
    {
      value: 'brandstudio',
      label: 'BrandStudio',
    },
  ];

  return (
    <Dropdown leftButtonRenderer={() => children}>
      {options.map((option) => (
        <Dropdown.Item
          key={option.value}
          itemKey={option.value}
          onClick={() => overrideProvider(option.value)}
        >
          {option.label}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
}

ProviderSelect.propTypes = {
  children: PropTypes.node,
};

export { ProviderSelect };
