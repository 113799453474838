import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import get from 'lodash/get';

import { Select } from '../Select/Select';
import { useDebounce } from '../../../hooks/useDebounce';

function AsyncSelect({
  fetchMany,
  fetchOne,
  mapItemToOption,
  isMulti = false,
  ...props
}) {
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitiallySelectedFetched, setIsInitiallySelectedFetched] =
    useState(false);
  const { values } = useFormikContext();
  const { name } = props;

  const debouncedQuery = useDebounce(query, 300);

  const handleOptionsFetch = useCallback(
    (items) => {
      setOptions(items.map(mapItemToOption));
    },
    [mapItemToOption]
  );

  const fetchOptions = useCallback(
    async function (query) {
      try {
        const items = await fetchMany(query);
        handleOptionsFetch(items);
      } catch (error) {
        console.error('Failed fetching async select items...', error);
      }
    },
    [fetchMany, handleOptionsFetch]
  );

  const fetchInitiallySelected = useCallback(
    async function (entityId) {
      setIsFetching(true);

      try {
        if (isMulti) {
          const items = await Promise.all(entityId.map((id) => fetchOne(id)));
          handleOptionsFetch(items);
        } else {
          const item = await fetchOne(entityId);
          handleOptionsFetch([item]);
        }
      } catch (error) {
        console.error(
          'Failed fetching async select initially selected items...',
          error
        );
      }

      setIsInitiallySelectedFetched(true);
      setIsFetching(false);
    },
    [fetchOne, handleOptionsFetch, isMulti]
  );

  useEffect(() => {
    if (debouncedQuery) {
      fetchOptions(debouncedQuery);
    } else if (!get(values, name)) {
      setOptions([]);
    }
  }, [debouncedQuery, fetchOptions, name, values]);

  useEffect(() => {
    const value = get(values, name);

    if (value && !isInitiallySelectedFetched) {
      fetchInitiallySelected(value);
    }
  }, [fetchInitiallySelected, isInitiallySelectedFetched, name, values]);

  return (
    <Select
      {...props}
      onInputChange={setQuery}
      options={options}
      isLoading={isFetching}
      isMulti={isMulti}
      placeholder="Start typing to get suggestions..."
    />
  );
}

AsyncSelect.propTypes = {
  fetchMany: PropTypes.func.isRequired,
  fetchOne: PropTypes.func.isRequired,
  mapItemToOption: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
};

export { AsyncSelect };
