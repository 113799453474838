import type { VFC } from 'react';
import React, { Fragment, useMemo, useState } from 'react';

import { Button } from '@schibsted-svp/react-ui';
import { Link } from 'react-router-dom';
import { PageType } from '@vgtv/api-client/lib/page';

import { Table } from '../Table/Table';
import { useUsers } from '../../hooks/useUsers';
import type { ExpandedPage } from '../../services/api/pages';

import { PageRow } from './PageRow/PageRow';
import { TemplateRow } from './TemplateRow/TemplateRow';

type Props = {
  pages: ExpandedPage[];
  loading?: boolean;
};
export const PagesTable: VFC<Props> = ({ pages, loading = false }) => {
  const [filter, setFilter] = useState('');

  const filtered = useMemo(
    () =>
      pages.filter((page) => {
        const { slug = '', category, tag, story } = page;

        const fields = [slug];

        if (category) {
          fields.push(category.title.toLowerCase());
        }

        if (tag) {
          fields.push(tag.title.toLowerCase());
        }

        if (story) {
          fields.push(story.title.toLowerCase());
        }

        if (page.meta) {
          const { title = '', description = '' } = page.meta;

          fields.push(title);
          fields.push(description);
        }

        // then check if the filter value is included in any of the fields
        return fields.reduce((included, field) => {
          included = included || field.includes(filter.toLowerCase());
          return included;
        }, false);
      }),
    [pages, filter]
  );

  const filteredPages = useMemo(
    () => filtered.filter((page) => page.type !== PageType.TEMPLATE),
    [filtered]
  );

  const filteredTemplates = useMemo(
    () => filtered.filter((page) => page.type === PageType.TEMPLATE),
    [filtered]
  );

  const { data: users = [] } = useUsers();

  return (
    <Fragment>
      <Table
        items={filteredPages}
        fixedItems={filteredTemplates}
        renderHeader={() => (
          <tr>
            <th>Type</th>
            <th>Components</th>
            <th>Meta tags</th>
            <th>Last update</th>
          </tr>
        )}
        renderRow={(page) =>
          page.type === PageType.TEMPLATE ? (
            <TemplateRow
              page={page}
              key={page.id}
              user={users.find(({ email }) => email === page.updatedBy)}
            />
          ) : (
            <PageRow
              page={page}
              key={page.id}
              user={users.find(({ email }) => email === page.updatedBy)}
            />
          )
        }
        renderActions={() => (
          <Link to="/pages/new">
            <Button type="button">Create a new page</Button>
          </Link>
        )}
        onFilterChange={setFilter}
        loading={loading}
      />
    </Fragment>
  );
};
