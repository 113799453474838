import type { VFC } from 'react';
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { ComponentForm } from '../../../ComponentForm/ComponentForm';
import type {
  ComponentDBO,
  ComponentInput,
} from '../../../../services/api/components';

import { ComponentBody } from './ComponentBody/ComponentBody';

import styles from './Component.module.scss';

type Props = {
  component: ComponentDBO;
  index: number;
  onAddClick: (index: number, component: Partial<ComponentDBO>) => void;
  onRemoveClick: (index: number) => void;
  onFormSubmit: (
    component:
      | (ComponentInput & { id: undefined })
      | (Partial<ComponentInput> & { id: string })
  ) => Promise<void>;
  onFormCancel: (index: number, component: Partial<ComponentDBO>) => void;
  onEditStart: () => void;
  onEditEnd: () => void;
  isEditable?: boolean;
};

export const Component: VFC<Props> = ({
  component,
  index,
  onAddClick,
  onRemoveClick,
  onFormCancel,
  onFormSubmit,
  onEditStart,
  onEditEnd,
  isEditable = true,
}) => {
  const isNew = component.id === undefined;
  const [isEditing, setIsEditing] = useState(isNew); // expand the form by default when creating a new component

  useEffect(() => {
    if (isNew) {
      onEditStart();
    }
  }, [isNew, onEditStart]);

  function onFormCancelClick() {
    setIsEditing(false);

    onFormCancel(index, component);
    onEditEnd();
  }

  async function onSubmit(
    data:
      | (ComponentInput & { id: undefined })
      | (Partial<ComponentInput> & { id: string })
  ) {
    await onFormSubmit(data);

    setIsEditing(false);
    onEditEnd();
  }

  function onEditClick() {
    if (isEditable) {
      setIsEditing(!isEditing);

      onEditStart();
    }
  }

  return (
    <div
      className={classNames(styles.component, {
        [styles.isEditing]: isEditing,
        [styles.isEditable]: isEditable,
      })}
    >
      {!isEditing ? (
        <ComponentBody
          component={component}
          index={index}
          onAddClick={onAddClick}
          onEditClick={onEditClick}
          onRemoveClick={onRemoveClick}
          isEditable={isEditable}
        />
      ) : (
        <ComponentForm
          component={component}
          onCancelClick={onFormCancelClick}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};
