import { getProvider } from '../svpProvider';
import { getLogger } from '../logger';

import { requestAPI } from './shared/requestAPI';

const logger = getLogger('imagesApi');

async function uploadImage(image) {
  const formData = new FormData();

  formData.append('image', image);

  try {
    const { url } = await requestAPI(`/${getProvider()}/images`, {
      method: 'POST',
      body: formData,
    });
    return url;
  } catch (error) {
    logger.error('Failed to upload image', {
      size: `${Math.round(image.size / 1000)}kB`,
      type: image.type,
    });
    throw error;
  }
}

export { uploadImage };
