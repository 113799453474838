import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@schibsted-svp/react-ui';
import { Link } from 'react-router-dom';

import { Table } from '../Table/Table';

import { ComponentRow } from './ComponentRow/ComponentRow';

/**
 * @param {object} props
 * @param {UserDBO[]} props.users
 * @param {ComponentDBO[]} props.components
 * @param {boolean} props.loading
 * @return {React.ReactElement}
 */
function ComponentsTable({ users, components, loading }) {
  const [filter, setFilter] = useState('');

  const filtered = useMemo(
    () =>
      components.filter((component) => {
        return (
          (component.header && component.header.includes(filter)) ||
          component.sharedName.includes(filter)
        );
      }),
    [components, filter]
  );

  return (
    <Fragment>
      <Table
        items={[...filtered]}
        renderHeader={() => (
          <tr>
            <th>Name</th>
            <th>Settings</th>
            <th>Last update</th>
          </tr>
        )}
        renderRow={(component) => (
          <ComponentRow
            component={component}
            key={component.id}
            user={users.find(({ email }) => email === component.updatedBy)}
          />
        )}
        renderActions={() => (
          <Link to="/components/new">
            <Button type="button">Create a new component</Button>
          </Link>
        )}
        onFilterChange={setFilter}
        loading={loading}
      />
    </Fragment>
  );
}

ComponentsTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  components: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
};

export { ComponentsTable };
