import { config } from '../../../config';
import { request } from '../../../utils';

import { buildSvpQueryParams } from './buildSvpQueryParams';

const { svpApiHostname, svpApiAppName } = config;

export type SvpFetchParams = Partial<
  Record<
    string,
    | string
    | number
    | boolean
    | Partial<Record<string, string | number | boolean>>
  >
>;

export async function requestSVP<T = never>({
  provider,
  path,
  params,
}: {
  provider: 'vgtv' | 'ab' | 'brandstudio';
  path: string;
  params?: SvpFetchParams;
}) {
  const basePath = `${svpApiHostname}${provider}`;
  const queryParams = buildSvpQueryParams(params);
  const queryString = `?appName=${svpApiAppName}${queryParams}`;

  try {
    const response = await request<T>(`${basePath}${path}${queryString}`);

    return await response.json();
  } catch {
    return {} as T;
  }
}
