import React from 'react';
import PropTypes from 'prop-types';

import { Spinner } from '@schibsted-svp/react-ui';

import styles from './CenteredSpinner.module.scss';

function CenteredSpinner({ size }) {
  return (
    <div className={styles.container}>
      <Spinner size={size} />
    </div>
  );
}

CenteredSpinner.propTypes = {
  size: PropTypes.oneOf(['small', 'big']),
};

export { CenteredSpinner };
