export class ValidationError extends Error {
  errors: Record<string | number, string>;

  constructor({
    message,
    errors = [],
  }: {
    message?: string;
    errors?: { message: string; path: string | string[] }[];
  } = {}) {
    super(message);

    this.errors = (errors ?? []).reduce(
      (errors, { message, path }) => {
        const normalizedPath = Array.isArray(path) ? path.join('.') : path;
        errors[normalizedPath] = message.replace(/^Path `[^`]*`/, 'This field');
        return errors;
      },
      {} as Record<string | number, string>
    );

    Error.captureStackTrace(this, ValidationError);
  }

  hasErrors() {
    return Object.keys(this.errors).length > 0;
  }
}
