import { useCallback, useEffect, useState, useRef } from 'react';

import { jwtDecode } from 'jwt-decode';

import { getAuthToken, setAuthToken } from '../helpers/authToken';

const TOKEN_EXPIRATION_CHECK_INTERVAL = 10000;

function getTokenExpirationTime(token) {
  const decoded = jwtDecode(token);

  return decoded.exp * 1000;
}

function isTokenExpired(expiresAt) {
  return Boolean(expiresAt && Date.now() > expiresAt);
}

const useOktaAuth = function (loginUrl = '') {
  const [token, setToken] = useState({
    value: null,
    expiresAt: null,
  });
  const [expired, setExpired] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const expirationCheckInterval = useRef(null);

  const redirectToLoginUrl = useCallback(
    () => setTimeout(() => (window.location.href = loginUrl), 1000),
    [loginUrl]
  );

  // fired on component mount - check if JWT is present in the url or localStorage and check its validity
  useEffect(() => {
    let tokenValue = window.location.hash.replace('#token=', '');

    if (!tokenValue) {
      tokenValue = getAuthToken();
    } else {
      // clean the hash fragment from the url (#token=*) without causing a page reload
      window.history.replaceState(null, null, ' ');
    }

    // redirect to login page - there's no token in the cache and in the url
    if (!tokenValue) {
      redirectToLoginUrl();
      return;
    }

    const expiresAt = getTokenExpirationTime(tokenValue);

    // redirect to login page - the token is expired when admin is entered
    if (isTokenExpired(expiresAt)) {
      setExpired(true);

      redirectToLoginUrl();
      return;
    }

    setAuthToken(tokenValue);

    setAuthenticated(true);
    setToken({ value: tokenValue, expiresAt });
  }, [redirectToLoginUrl]);

  // periodically check token for expiration
  useEffect(() => {
    if (token && !expired) {
      expirationCheckInterval.current = setInterval(() => {
        const isExpired = isTokenExpired(token.expiresAt);

        if (isExpired !== expired) {
          setExpired(isExpired);
        }
      }, TOKEN_EXPIRATION_CHECK_INTERVAL);
    } else {
      clearInterval(expirationCheckInterval.current);
      expirationCheckInterval.current = null;
    }
  }, [expired, token]);

  return {
    authenticated,
    expired,
  };
};

export { useOktaAuth };
