import React from 'react';

import { Select } from '../../../../Form/Select/Select';

const STREAM_TYPES = [
  { value: 'vod', label: 'VoD' },
  { value: 'live', label: 'Live' },
  { value: 'wasLive', label: 'Was live' },
];

export function StreamTypeSelect() {
  return (
    <Select
      label="Stream type"
      name="filter.streamType"
      isMulti
      options={STREAM_TYPES}
    />
  );
}
