import React, { useEffect, useState, type VFC } from 'react';

import { useHistory } from 'react-router-dom';
import { omit } from 'lodash';

import { PageForm } from '../components/PageForm/PageForm';
import { usePage } from '../hooks/usePage';
import type { ExpandedPage } from '../services/api/pages';
import {
  addPageComponent,
  createPage,
  removePageComponent,
  updatePage,
} from '../services/api/pages';
import { withFetchStatus } from '../services/notifier';

type Props = {
  match: {
    params?: {
      pageId: string;
    };
  };
};

export const Page: VFC<Props> = ({ match }) => {
  const history = useHistory();
  const [page, setPage] = useState({} as ExpandedPage);

  const { params: { pageId } = {} } = match;

  const {
    data: entity,
    isFetching,
    refetch,
  } = usePage(pageId === 'new' ? undefined : pageId);

  useEffect(() => {
    if (entity) {
      setPage(entity);
    }
  }, [entity]);

  async function onSubmit(values: Partial<ExpandedPage>) {
    const { id, ...rest } = omit(values, [
      '__v',
      'provider',
      'updatedAt',
      'createdAt',
      'updatedBy',
    ]);

    if (id) {
      await withFetchStatus(updatePage, {
        success: 'Page updated',
        error: 'Failed to update page',
      })(id, rest);
      history.push('/pages');
    } else {
      const page = await withFetchStatus(createPage, {
        success: 'Page created',
        error: 'Failed to create page',
      })(rest);

      history.replace(`/pages/${page.id}`);
    }
  }

  async function handlePageUpdate(
    values: Pick<ExpandedPage, 'id'> & Partial<ExpandedPage>
  ) {
    const { id, ...rest } = values;

    await withFetchStatus(updatePage, {
      success: 'Page updated',
      error: 'Failed to update page',
    })(id, rest);

    await refetch();
  }

  async function addComponent(componentId: string, index: number) {
    await withFetchStatus(addPageComponent, {
      error: 'Failed to add component',
    })(page.id, componentId, index);

    await refetch();
  }

  async function removeComponent(componentId: string) {
    await withFetchStatus(removePageComponent, {
      error: 'Failed to remove component',
    })(page.id, componentId);

    await refetch();
  }

  return (
    <PageForm
      page={page}
      onSubmit={onSubmit}
      updatePage={handlePageUpdate}
      addComponent={addComponent}
      removeComponent={removeComponent}
      isLoading={isFetching && !page.id}
      refetchPage={refetch}
    />
  );
};
