import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { formatDateTime } from '../../../utils';
import { Badge } from '../../Badge/Badge';
import { UserInitials } from '../../UserInitials/UserInitials';
import { useCategory } from '../../../hooks/useCategory';
import { Thumbnail } from '../../Thumbnail/Thumbnail';

import styles from './TagRow.module.scss';

/**
 * @param {object} props
 * @param {UserDBO} props.user
 * @param {EnrichmentDBO & { tag: CnpTag }} props.enrichment
 * @return {React.ReactElement}
 */
export function TagRow({ user, enrichment }) {
  const history = useHistory();
  const { tag } = enrichment;

  const { data: category } = useCategory(enrichment.categoryId);

  const { tagId } = enrichment;

  const hasCategory = Boolean(category);
  const mainImage = enrichment?.images?.main
    ? `${enrichment?.images?.main}?t[]=180q80`
    : undefined;
  const backgroundImage = enrichment?.images?.background
    ? `${enrichment?.images?.background}?t[]=180q80`
    : undefined;

  return (
    <tr onClick={() => history.push(`/tags/${tagId}`)} className={styles.row}>
      <td>
        <div className={styles.mainCellWrapper}>
          <Thumbnail
            aspectRatio="16/9"
            className={styles.backgroundImage}
            url={backgroundImage}
          />
          <Thumbnail
            aspectRatio="1/1"
            className={styles.mainImage}
            url={mainImage}
            isRound
          />
          {tag.title}
        </div>
      </td>
      <td>
        {hasCategory && <Badge variant="green">{category.title}</Badge>}
        {enrichment.isProfile && <Badge variant="grey">Profile</Badge>}
      </td>
      <td>
        <div className={styles.updated}>
          {formatDateTime(enrichment.updatedAt)}
          {user && <UserInitials user={user} className={styles.initials} />}
        </div>
      </td>
    </tr>
  );
}

TagRow.propTypes = {
  user: PropTypes.object.isRequired,
  enrichment: PropTypes.object.isRequired,
};
