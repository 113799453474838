import React, { Fragment } from 'react';

import { useFormikContext } from 'formik';

import { FiltersPicker } from '../../FiltersPicker/FiltersPicker';
import { ImageUploadInput } from '../../Form/ImageUploadInput/ImageUploadInput';
import { FILTER_CATEGORY, FILTER_TAG } from '../../FiltersPicker/types';

function ScheduledFields() {
  const { values: component, setFieldValue } = useFormikContext();

  return (
    <Fragment>
      <FiltersPicker
        filters={component.filters}
        allowedFilters={[FILTER_TAG, FILTER_CATEGORY]}
        onChange={(filters) => {
          setFieldValue('filters', filters);
        }}
      />
      <ImageUploadInput name="image" label="Image" aspectRatio={16 / 9} />
    </Fragment>
  );
}

export { ScheduledFields };
