import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog } from '@schibsted-svp/react-ui';

import { fetchSharedComponents } from '../../services/api/components';
import { CenteredSpinner } from '../CenteredSpinner/CenteredSpinner';
import { PageContext } from '../../contexts/PageContext';

import { Component } from './Component/Component';

function ComponentPicker({ isOpen, onClose, onPick }) {
  const [components, setComponents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { page } = useContext(PageContext);

  const excludedComponents =
    page && page.components ? page.components.map(({ id }) => id) : [];

  async function fetchComponents() {
    const components = await fetchSharedComponents();

    setComponents(components);
    setIsLoading(false);
  }

  async function onComponentClick(component) {
    await onPick(component);
    onClose();
  }

  useEffect(() => {
    if (isOpen) {
      fetchComponents();
    }
  }, [isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      heading="Select a shared component"
    >
      <Dialog.Section>
        {isLoading && <CenteredSpinner />}
        {components.map((component) => (
          <Component
            key={component.id}
            component={component}
            onClick={onComponentClick}
            isDisabled={excludedComponents.includes(component.id)}
          />
        ))}
      </Dialog.Section>
      <Dialog.Section variant="darker" mode="flexRight">
        <Button type="button" variant="standard" onClick={onClose}>
          Cancel
        </Button>
      </Dialog.Section>
    </Dialog>
  );
}

ComponentPicker.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPick: PropTypes.func.isRequired,
};

export { ComponentPicker };
