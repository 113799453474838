import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CollapsibleContent } from '@schibsted-svp/react-ui';
import classNames from 'classnames';

import styles from './CollapsibleSection.module.scss';

function CollapsibleSection({
  label,
  children,
  initiallyCollapsed = true,
  className,
}) {
  const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed);

  return (
    <CollapsibleContent
      label={label}
      expanded={!isCollapsed}
      onToggle={() => {
        setIsCollapsed(!isCollapsed);
      }}
    >
      <div className={classNames(styles.container, className)}>{children}</div>
    </CollapsibleContent>
  );
}

CollapsibleSection.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  initiallyCollapsed: PropTypes.bool,
  className: PropTypes.string,
};

export { CollapsibleSection };
