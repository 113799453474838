import React from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';
import { Checkbox } from '@schibsted-svp/react-ui';
import get from 'lodash/get';

function UpcomingAssetsCheckbox({ name }) {
  const { setFieldValue, values } = useFormikContext();
  const isChecked = get(values, name) === '{{current_timestamp}}';

  function onChange(event) {
    const { checked } = event.target;

    if (checked) {
      setFieldValue(name, '{{current_timestamp}}');
    } else {
      setFieldValue(name, null);
    }
  }

  return (
    <div className="container">
      <Checkbox
        label="Filter out upcoming assets"
        onChange={onChange}
        checked={isChecked}
      />
    </div>
  );
}

UpcomingAssetsCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  checkedAccessFilter: PropTypes.string,
};

export { UpcomingAssetsCheckbox };
