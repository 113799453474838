import type { ChangeEvent, VFC } from 'react';
import React, { Fragment, useMemo, useState } from 'react';

import { DataType } from '@vgtv/api-client/lib/component';

import { Input } from '../../Form/Input/Input';
import { CenteredSpinner } from '../../CenteredSpinner/CenteredSpinner';
import { PresetItem } from '../PresetItem/PresetItem';
import { useProfiles } from '../../../hooks/useProfiles';
import type { EnrichedTag } from '../../../services/api/enrichments';

import styles from './ProfilesSearch.module.scss';

type Props = {
  selectedProfiles: EnrichedTag[];
  onAddClick: (profile: EnrichedTag) => void;
  onRemoveClick: (profile: EnrichedTag) => void;
};

export const ProfilesSearch: VFC<Props> = ({
  selectedProfiles = [],
  onAddClick,
  onRemoveClick,
}) => {
  const { data: profiles = [] } = useProfiles();
  const [filter, setFilter] = useState('');

  const filteredProfiles = useMemo(() => {
    if (filter === '') {
      return profiles;
    }

    return profiles.filter((profile) =>
      profile.title.toLowerCase().includes(filter)
    );
  }, [profiles, filter]);

  return (
    <Fragment>
      <Input
        label="Search by title"
        name="profileTitle"
        value={filter}
        disabled={profiles.length === 0}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setFilter(event.target.value);
        }}
      />
      {profiles.length === 0 && <CenteredSpinner />}
      <ul className={styles.profilesList}>
        {filteredProfiles.map((profile) => {
          const isSelected = selectedProfiles
            .map((presetItem) => presetItem.id)
            .includes(profile.id);

          return (
            <li key={profile.id}>
              <PresetItem
                item={profile}
                dataType={DataType.PROFILES}
                isSelected={isSelected}
                onClick={() =>
                  isSelected ? onRemoveClick(profile) : onAddClick(profile)
                }
              />
            </li>
          );
        })}
      </ul>
    </Fragment>
  );
};
