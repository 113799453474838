import { useQuery } from '@tanstack/react-query';

import { fetchEditor } from '../services/api/editors';
import { withFetchStatus } from '../services/notifier';

const fetchEditorWithStatus = withFetchStatus(fetchEditor, {
  error: 'Failed to fetch editor',
});

export const useEditor = (editorId?: string) => {
  return useQuery(
    ['editor', editorId],
    () => fetchEditorWithStatus(editorId!),
    {
      enabled: Boolean(editorId),
      refetchOnWindowFocus: false,
    }
  );
};
