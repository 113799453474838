import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import { ComponentType, DataType } from '@vgtv/api-client/lib/component';
import { PageType } from '@vgtv/api-client/lib/page';

import { DataSourceBadge } from '../ComponentForm/DataSource/DataSourceBadge/DataSourceBadge';
import { EndpointParamsList } from '../ComponentForm/DataSource/EndpointParamsList/EndpointParamsList';
import { PageContext } from '../../contexts/PageContext';

import styles from './ComponentBadges.module.scss';

function ComponentBadges({ component, small }) {
  const data = component?.data;
  const dataSource = data?.source;
  const dataType = data?.type;
  const endpoint = dataSource?.endpoint;
  const hasPreset = Boolean(data?.preset2?.length);
  const usePageTaxonomy = component?.options?.usePageTaxonomy;
  const { page } = useContext(PageContext);

  let params = dataSource?.params ?? {};
  if (component.type === ComponentType.FILTERED_LIST) {
    params = {};

    const usePageCategory =
      usePageTaxonomy &&
      (!page ||
        page.type === PageType.CATEGORY ||
        (page.type === PageType.TEMPLATE &&
          page.forType === PageType.CATEGORY));

    if (component.categoryId || usePageCategory) {
      params.categoryId = usePageCategory
        ? 'from page'
        : component.categoryId.toString();
    }

    if (component.filters && component.filters.length > 0) {
      params.filters = '«preset»';
    }
  }

  return (
    <div className={styles.componentBadges}>
      {component.data && (
        <Fragment>
          <DataSourceBadge
            dataType={dataType}
            endpoint={endpoint}
            hasPreset={hasPreset}
            small={small}
          />
          {dataSource && !hasPreset && (
            <EndpointParamsList
              params={params}
              className={styles.paramsBadges}
              small={small}
            />
          )}
          {dataType === DataType.SEASONS && (
            <EndpointParamsList
              params={{ categoryId: component.categoryId }}
              className={styles.paramsBadges}
              small={small}
            />
          )}
        </Fragment>
      )}
      {component.type === ComponentType.FILTERED_LIST && (
        <EndpointParamsList
          params={params}
          className={styles.paramsBadges}
          small={small}
        />
      )}
    </div>
  );
}

ComponentBadges.propTypes = {
  component: PropTypes.object.isRequired,
  small: PropTypes.bool,
};

export { ComponentBadges };
