import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '../Select/Select';
import { useEditors } from '../../../hooks/useEditors';

function EditorsSelect({ ...props }) {
  const { data: editors = [], isLoading } = useEditors();

  const options = editors.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return <Select {...props} options={options} isLoading={isLoading} />;
}

EditorsSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  allowEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['big', 'small', 'compact']),
  className: PropTypes.string,
};

export { EditorsSelect };
