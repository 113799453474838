import { localStorageHelper } from './localStorage';

const TOKEN_STORAGE_KEY = 'apiToken';

let token = null;

function getAuthToken() {
  return token || localStorageHelper.getItem(TOKEN_STORAGE_KEY);
}

function setAuthToken(value) {
  token = value;
  localStorageHelper.setItem(TOKEN_STORAGE_KEY, value);
}

export { getAuthToken, setAuthToken };
