import React from 'react';

import { Spinner } from '@schibsted-svp/react-ui';

import { Container } from '../Container/Container';

import styles from './LoginMessage.module.scss';

function LoginMessage() {
  return (
    <Container className={styles.container}>
      <p className={styles.content}>Logging in...</p>
      <Spinner />
    </Container>
  );
}

export { LoginMessage };
