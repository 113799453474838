import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import { isFunction } from '../../utils';

import styles from './SortableList.module.scss';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default class SortableList extends Component {
  constructor(props) {
    super(props);

    // store the items in state, so that we can visually reorder them before the props are updated
    this.state = {
      items: props.items,
    };
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.props.items,
      result.source.index,
      result.destination.index
    );

    this.setState({ items });

    if (isFunction(this.props.onChange)) {
      this.props.onChange(items);
    }
  };

  // since we're storing items in state, we need to update it manually whenever items are removed or added
  static getDerivedStateFromProps(props, state) {
    const { items: nextItems } = props;
    const { items: previousItems } = state;

    if (nextItems.length !== previousItems.length) {
      return { items: nextItems };
    } else {
      const previousValues = previousItems.map((item) => JSON.stringify(item));
      const nextValues = nextItems.map((item) => JSON.stringify(item));

      if (previousValues.join('') !== nextValues.join('')) {
        return { items: nextItems };
      }
    }

    return { items: previousItems };
  }

  render() {
    const {
      renderItem,
      keyProperty,
      className,
      disabled,
      itemsDisabled,
      compact = false,
    } = this.props;
    const { items } = this.state;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className={classNames(
                styles.list,
                {
                  [styles.isOver]: snapshot.isDraggingOver,
                  [styles.isDisabled]: disabled,
                  [styles.isCompact]: compact,
                },
                className
              )}
              ref={provided.innerRef}
            >
              {items.map((item, index) => {
                const id =
                  keyProperty && item[keyProperty]
                    ? item[keyProperty].toString()
                    : index.toString();

                return (
                  <Draggable
                    key={id}
                    draggableId={id}
                    index={index}
                    isDragDisabled={disabled || itemsDisabled}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={classNames(styles.item, {
                          [styles.isDragged]: snapshot.isDragging,
                          [styles.isCompact]: compact,
                        })}
                      >
                        {renderItem(item, index)}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

SortableList.propTypes = {
  items: PropTypes.array.isRequired,
  keyProperty: PropTypes.string,
  renderItem: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  itemsDisabled: PropTypes.bool,
  compact: PropTypes.bool,
};
