import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog } from '@schibsted-svp/react-ui';

import { EndpointParamsList } from '../EndpointParamsList/EndpointParamsList';

import { EndpointParamsForm } from './EndpointParamsForm/EndpointParamsForm';

import styles from './EndpointParamsPicker.module.scss';

function EndpointParamsPicker({ endpoint, dataType, params = {}, onSubmit }) {
  const [isOpen, setIsOpen] = useState(false);

  function onButtonClick() {
    setIsOpen(true);
  }

  function onDialogClose() {
    setIsOpen(false);
  }

  function onFormSubmit(params) {
    setIsOpen(false);

    onSubmit(params);
  }

  const hasParams = Object.keys(params).length > 0;

  return (
    <Fragment>
      <div className={styles.container}>
        <EndpointParamsList params={params} />
        <Button
          type="button"
          variant="standard"
          size="small"
          onClick={onButtonClick}
        >
          {hasParams ? 'Change' : 'Set'} parameters
        </Button>
      </div>
      <Dialog
        isOpen={isOpen}
        onClose={onDialogClose}
        heading={`Set parameters for ${endpoint}`}
        className={styles.dialog}
      >
        <EndpointParamsForm
          dataType={dataType}
          endpoint={endpoint}
          params={params}
          onSubmit={onFormSubmit}
          onCancel={onDialogClose}
        />
      </Dialog>
    </Fragment>
  );
}

EndpointParamsPicker.propTypes = {
  endpoint: PropTypes.string.isRequired,
  dataType: PropTypes.string.isRequired,
  params: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export { EndpointParamsPicker };
