import { useQuery } from '@tanstack/react-query';

import { fetchUsers } from '../services/api/users';
import { withFetchStatus } from '../services/notifier';

const fetchUsersWithStatus = withFetchStatus(fetchUsers, {
  error: 'Failed to fetch the list of users',
});

export const useUsers = () => {
  return useQuery(['users'], fetchUsersWithStatus, {
    initialData: [],
    refetchOnWindowFocus: false,
  });
};
