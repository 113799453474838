import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { fetchStories, fetchStoryById } from '../../../services/api/stories';
import { AsyncSelect } from '../AsyncSelect/AsyncSelect';

function StoriesSelect(props) {
  const mapItemToOption = useCallback(
    ({ id, title }) => ({
      value: id.toString(),
      label: title,
    }),
    []
  );

  const searchStories = useCallback((query) => fetchStories({ query }), []);

  return (
    <AsyncSelect
      fetchMany={searchStories}
      fetchOne={fetchStoryById}
      mapItemToOption={mapItemToOption}
      {...props}
    />
  );
}

StoriesSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  allowEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['big', 'small', 'compact']),
  className: PropTypes.string,
};

export { StoriesSelect };
