import { useQuery } from '@tanstack/react-query';

import { withFetchStatus } from '../services/notifier';
import { fetchCategories } from '../services/svp/categories';

const fetchCategoriesWithStatus = withFetchStatus(fetchCategories, {
  error: 'Failed to fetch the list of categories',
});

export const useSvpCategories = (params?: Record<string, any>) => {
  return useQuery(
    ['svp-categories', params],
    () => fetchCategoriesWithStatus(params),
    { enabled: Boolean(params), refetchOnWindowFocus: false }
  );
};
