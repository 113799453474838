import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'formik';
import { Checkbox as SvpCheckbox } from '@schibsted-svp/react-ui';

import styles from './Checkbox.module.scss';

function Checkbox({ label, ...props }) {
  return (
    <div className={styles.container}>
      <Field {...props}>
        {({ field, meta }) => (
          <SvpCheckbox
            {...field}
            {...props}
            checked={field.value}
            label={label}
            error={typeof meta.error === 'string' ? meta.error : undefined}
          />
        )}
      </Field>
    </div>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export { Checkbox };
