import type { VFC } from 'react';
import React from 'react';

import { ComponentContext } from '../../../contexts/ComponentContext';
import { ComponentBadges } from '../../ComponentBadges/ComponentBadges';
import { getComponentTypeLabel } from '../../../helpers/getComponentTypeLabel';
import type { ComponentDBO } from '../../../services/api/components';

import styles from './ComponentsPreview.module.scss';

type Props = {
  components: ComponentDBO[];
};

export const ComponentsPreview: VFC<Props> = ({ components }) => {
  return (
    <ul className={styles.componentsList}>
      {components.map((component) => {
        const { id, type, header } = component;

        return (
          <li key={id}>
            <ComponentContext.Provider value={{ component }}>
              <div className={styles.component}>
                <span className={styles.componentType}>
                  {getComponentTypeLabel(type)}
                </span>
                {header && (
                  <span className={styles.componentHeader}>"{header}"</span>
                )}
                <ComponentBadges component={component} small />
              </div>
            </ComponentContext.Provider>
          </li>
        );
      })}
    </ul>
  );
};
