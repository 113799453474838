import type { VFC } from 'react';
import React, { useState } from 'react';

import SortableList from '../../SortableList/SortableList';
import type {
  ComponentDBO,
  ComponentInput,
} from '../../../services/api/components';

import { AddComponentButton } from './AddComponentButton/AddComponentButton';
import { Component } from './Component/Component';

import styles from './ComponentsList.module.scss';

type Props = {
  components: ComponentDBO[];
  onComponentAddClick: (
    index: number,
    component: Partial<ComponentDBO>
  ) => void;
  onComponentRemoveClick: (index: number) => void;
  onComponentsReorder: (components: ComponentDBO[]) => Promise<void>;
  onComponentFormSubmit: (
    component:
      | (ComponentInput & { id: undefined })
      | (Partial<ComponentInput> & { id: string }),
    index: number
  ) => Promise<void>;
  onComponentFormCancel: (
    index: number,
    componentDraft: Partial<ComponentDBO>
  ) => void;
  isDisabled?: boolean;
};

export const ComponentsList: VFC<Props> = ({
  components,
  onComponentAddClick,
  onComponentRemoveClick,
  onComponentsReorder,
  onComponentFormSubmit,
  onComponentFormCancel,
  isDisabled = false,
}) => {
  const [isSavingOrder, setIsSavingOrder] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  async function onReorder(data: ComponentDBO[]) {
    setIsSavingOrder(true);

    await onComponentsReorder(data);

    setIsSavingOrder(false);
  }

  function onComponentEditStart() {
    setIsEditing(true);
  }

  function onComponentEditEnd() {
    setIsEditing(false);
  }

  return components.length > 0 ? (
    <SortableList
      items={components}
      renderItem={(item: ComponentDBO, index: number) => (
        <Component
          component={item}
          index={index}
          onAddClick={onComponentAddClick}
          onRemoveClick={onComponentRemoveClick}
          onFormSubmit={(data) => onComponentFormSubmit(data, index)}
          onFormCancel={onComponentFormCancel}
          onEditStart={onComponentEditStart}
          onEditEnd={onComponentEditEnd}
          isEditable={!isEditing}
        />
      )}
      onChange={onReorder}
      keyProperty="id"
      disabled={isSavingOrder}
      itemsDisabled={isEditing}
    />
  ) : (
    <div className={styles.emptyList}>
      <AddComponentButton
        index={0}
        label="Add the first component"
        onClick={onComponentAddClick}
        size="big"
        className={styles.addButton}
        disabled={isDisabled}
      />
    </div>
  );
};
