import React, { Fragment, type VFC } from 'react';

import { ImageUploadInput } from '../components/Form/ImageUploadInput/ImageUploadInput';
import { Form } from '../components/Form/Form';

export const PromotionPictures: VFC = () => {
  return (
    <Fragment>
      <Form initialValues={{ name: 'my name' }}>
        <ImageUploadInput
          label="Promotion picture"
          name="image"
          displayImageUrl={true}
          aspectRatio={10 / 2}
        />
      </Form>
    </Fragment>
  );
};
