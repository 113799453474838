import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { LabeledContainer, Button } from '@schibsted-svp/react-ui';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import get from 'lodash/get';

import { uploadImage } from '../../../services/api/images';
import { blobToDataUrl, loadImage } from '../../../utils';
import { withFetchStatus } from '../../../services/notifier';

import { ImageDropzone } from './ImageDropzone/ImageDropzone';
import { ImageCropper } from './ImageCropper/ImageCropper';

import styles from './ImageUploadInput.module.scss';

function ImageUploadInput({
  label,
  name,
  aspectRatio = 16 / 9,
  displayImageUrl = false,
}) {
  const { values, errors, setFieldValue } = useFormikContext();
  const [droppedImageDataUrl, setDroppedImageDataUrl] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(get(values, name));
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const invertedAspectRatioPadding = useMemo(
    () => `${(1 / aspectRatio) * 100}%`,
    [aspectRatio]
  );

  const hasUploadedImage = Boolean(uploadedImage);
  const imageUrl = useMemo(
    () => (uploadedImage ? `${uploadedImage}?t[]=580q80` : null),
    [uploadedImage]
  );

  const hasError = typeof errors[name] === 'string';

  const showImageUrl = displayImageUrl && imageUrl;

  const preloadImage = useCallback(async (imageUrl) => {
    await loadImage(imageUrl);
    setIsImageLoaded(true);
  }, []);

  useEffect(() => {
    if (imageUrl) {
      preloadImage(imageUrl);
    }
  }, [imageUrl, preloadImage]);

  function onCropperClose() {
    setDroppedImageDataUrl(null);
  }

  function onRemoveImage() {
    setFieldValue(name, null);
    setUploadedImage(null);
    setDroppedImageDataUrl(null);
  }

  return (
    <LabeledContainer label={label}>
      <div className={styles.container}>
        <div className={styles.imagePreview}>
          {hasUploadedImage && isImageLoaded ? (
            <img src={imageUrl} alt="" />
          ) : (
            <div
              className={styles.aspectRatio}
              style={{ paddingBottom: invertedAspectRatioPadding }}
            />
          )}
        </div>
        <ImageDropzone
          className={classNames(styles.dropzone, {
            [styles.hasError]: hasError,
          })}
          aspectRatio={aspectRatio}
          onDrop={async (image) => {
            const imageDataUrl = await blobToDataUrl(image);
            setDroppedImageDataUrl(imageDataUrl);
          }}
        />
      </div>
      {hasUploadedImage && isImageLoaded && (
        <Button
          type="button"
          onClick={onRemoveImage}
          variant="standard"
          size="small"
          className={styles.removeButton}
        >
          Remove image
        </Button>
      )}
      <ImageCropper
        onClose={onCropperClose}
        imageDataUrl={droppedImageDataUrl}
        aspectRatio={aspectRatio}
        buttonLabel="Upload"
        onCrop={async (imageBlob) => {
          const url = await withFetchStatus(uploadImage, {
            success: 'Image uploaded',
            error: 'Failed to upload image',
          })(imageBlob);

          setFieldValue(name, url);
          setDroppedImageDataUrl(null);
          setUploadedImage(url);
        }}
      />
      {hasError && <p className={styles.error}>{errors[name]}</p>}
      {showImageUrl && (
        <p>
          <b>Image url:</b>
          {imageUrl}
        </p>
      )}
    </LabeledContainer>
  );
}

ImageUploadInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  aspectRatio: PropTypes.number,
  displayImageUrl: PropTypes.bool,
};

export { ImageUploadInput };
