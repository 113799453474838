import React, { type VFC } from 'react';

import { UsersTable } from '../components/UsersTable/UsersTable';
import { useUsers } from '../hooks/useUsers';

export const Users: VFC = () => {
  const { data: users = [], isLoading } = useUsers();

  return <UsersTable users={users} loading={isLoading} />;
};
