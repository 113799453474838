import React from 'react';
import PropTypes from 'prop-types';

import { LabeledContainer } from '@schibsted-svp/react-ui';
import classNames from 'classnames';

import styles from './FormField.module.scss';

function FormField({ label, children, className }) {
  return (
    <LabeledContainer
      label={label}
      className={classNames(styles.container, className)}
    >
      {children}
    </LabeledContainer>
  );
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
};

export { FormField };
