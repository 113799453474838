import PropTypes from 'prop-types';
import React from 'react';

import { useHistory } from 'react-router-dom';

import { formatDateTime } from '../../../utils';
import { Thumbnail } from '../../Thumbnail/Thumbnail';

import styles from './EditorRow.module.scss';

/**
 * @param {object} props
 * @param {object} props.editor
 * @return {React.ReactElement}
 */
function EditorRow({ editor }) {
  const history = useHistory();

  const { id, name, image, updatedAt } = editor;
  const imageUrl = image ? `${image}?t[]=180q80` : undefined;

  return (
    <tr onClick={() => history.push(`/editors/${id}`)} className={styles.row}>
      <td>
        <Thumbnail
          url={imageUrl}
          isRound
          aspectRatio="1/1"
          className={styles.image}
        />
      </td>
      <td>{name}</td>
      <td>
        <div className={styles.updated}>{formatDateTime(updatedAt)}</div>
      </td>
    </tr>
  );
}

EditorRow.propTypes = {
  editor: PropTypes.object.isRequired,
};

export { EditorRow };
