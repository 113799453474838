import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog } from '@schibsted-svp/react-ui';

import styles from '../ComponentForm.module.scss';
import { Input } from '../../Form/Input/Input';
import { Form } from '../../Form/Form';
import { SubmitButton } from '../../Form/SubmitButton/SubmitButton';

function SaveToSharedButton({ disabled, onSubmit }) {
  const [isOpen, setIsOpen] = useState(false);

  function onClick() {
    setIsOpen(!isOpen);
  }

  function onDialogClose() {
    setIsOpen(false);
  }

  return (
    <Fragment>
      <Button
        size="small"
        type="button"
        variant="standard"
        className={styles.saveToCommon}
        onClick={onClick}
        disabled={disabled}
      >
        Save to shared components
      </Button>
      <Dialog
        isOpen={isOpen}
        onClose={onDialogClose}
        heading={'Save to shared components'}
      >
        <Form
          initialValues={{ isShared: true, sharedName: null }}
          onSubmit={onSubmit}
        >
          <Dialog.Section>
            <Input label="Shared name" name="sharedName" />
          </Dialog.Section>
          <Dialog.Section variant="darker" mode="flexRight">
            <Button type="button" onClick={onDialogClose} variant="standard">
              Cancel
            </Button>
            <SubmitButton>Save</SubmitButton>
          </Dialog.Section>
        </Form>
      </Dialog>
    </Fragment>
  );
}

SaveToSharedButton.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export { SaveToSharedButton };
