import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Button } from '@schibsted-svp/react-ui';

import { ComponentBody } from '../../PageForm/ComponentsList/Component/ComponentBody/ComponentBody';

import styles from './Component.module.scss';

function Component({ component, onClick, isDisabled = false }) {
  const [isAdding, setIsAdding] = useState(false);

  async function onComponentClick() {
    setIsAdding(true);
    await onClick(component);
    setIsAdding(false);
  }

  return (
    // eslint-disable-next-line
    <div
      className={classNames(styles.component, {
        [styles.isDisabled]: isDisabled,
      })}
      onClick={!isDisabled ? onComponentClick : undefined}
    >
      <ComponentBody component={component} />
      <Button
        type="button"
        variant="standard"
        size="small"
        className={styles.addButton}
        loading={isAdding}
        iconOnly
      >
        +
      </Button>
    </div>
  );
}

Component.propTypes = {
  component: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export { Component };
