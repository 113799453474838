import React from 'react';
import PropTypes from 'prop-types';

import { Thumbnail } from '../../../Thumbnail/Thumbnail';

import styles from './Asset.module.scss';

// TODO: open preview in player on click

function Asset({ asset, className }) {
  return (
    <div className={className}>
      <Thumbnail url={`${asset.images.main}?t[]=161x91q80`} alt={asset.title} />
      <div className={styles.title}>{asset.title}</div>
    </div>
  );
}

Asset.propTypes = {
  asset: PropTypes.shape({
    images: PropTypes.shape({ main: PropTypes.string.isRequired }),
    title: PropTypes.string,
  }),
  className: PropTypes.string,
};

export { Asset };
