import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../Form/Input/Input';
import { EditorsSelect } from '../../Form/EditorsSelect/EditorsSelect';

function EditorialFields({ inputsSize }) {
  return (
    <Fragment>
      <EditorsSelect label="Editor" name="editor" size={inputsSize} />
      <Input type="textarea" label="Content" name="content" size="small" />
    </Fragment>
  );
}

EditorialFields.propTypes = {
  inputsSize: PropTypes.string.isRequired,
};

export { EditorialFields };
