import type { CnpTag } from '@vgtv/api-client/lib/cnp_tag';

import { getProvider } from '../svpProvider';
import { getLogger } from '../logger';

import { isSuccessResponse, requestAPI } from './shared/requestAPI';
import { createUrlPartial, toSearchParams } from './shared/utils';

const logger = getLogger('tagsApi');

export const fetchTags = async ({
  query,
  ids,
}: {
  query?: string;
  ids?: string[];
}) => {
  if ((query && query.length < 3) || (ids && ids.length < 1)) {
    return [] as CnpTag[];
  }

  const params = toSearchParams({ query, ids });

  try {
    const result = await requestAPI<CnpTag[]>(
      createUrlPartial(`/${getProvider()}/admin/tags`, params)
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result.errors[0].message;
  } catch (error) {
    logger.error('Failed to fetch the list of tags');
    throw error;
  }
};

export const fetchTagById = async (tagId: string) => {
  try {
    const result = await requestAPI<CnpTag>(
      `/${getProvider()}/admin/tags/${tagId}`
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to fetch tag', { tagId });
    throw error;
  }
};
