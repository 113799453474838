import type { VFC } from 'react';
import React, { Fragment } from 'react';

import { useHistory } from 'react-router-dom';
import { PageType } from '@vgtv/api-client/lib/page';

import { Badge } from '../../Badge/Badge';
import { ComponentsPreview } from '../ComponentsPreview/ComponentsPreview';
import { formatDateTime } from '../../../utils';
import { PageContext } from '../../../contexts/PageContext';
import { UserInitials } from '../../UserInitials/UserInitials';
import type { UserDBO } from '../../../services/api/users';
import type { ExpandedPage } from '../../../services/api/pages';

import styles from './PageRow.module.scss';

type Props = {
  page: ExpandedPage;
  user?: UserDBO;
};

const getPageTaxonomy = (page: ExpandedPage) => {
  switch (page.type) {
    case PageType.CATEGORY:
      return page.category;
    case PageType.TAG:
      return page.tag;
    case PageType.STORY:
      return page.story;
    default:
      return null;
  }
};

export const PageRow: VFC<Props> = ({ page, user }) => {
  const history = useHistory();

  const taxonomy = getPageTaxonomy(page);
  const taxonomyLabel =
    page.type === PageType.CUSTOM ? `/${page.slug}` : taxonomy?.title;

  const {
    id,
    meta: { title, description, keywords } = {},
    components,
    useDefaultTemplate,
  } = page;

  return (
    <PageContext.Provider value={{ page }}>
      <tr onClick={() => history.push(`/pages/${id}`)} className={styles.row}>
        <td>
          <span className={styles.pageType}>{page.type}</span>
          <Badge>{taxonomyLabel}</Badge>
        </td>
        <td>
          {useDefaultTemplate ? (
            <span className={styles.defaultTemplate}>
              {page.type} page template
            </span>
          ) : (
            <ComponentsPreview components={components} />
          )}
        </td>
        <td>
          {page.meta && (
            <Fragment>
              {title && (
                <Badge variant="green" title={title}>
                  title
                </Badge>
              )}
              {description && (
                <Badge variant="green" title={description}>
                  description
                </Badge>
              )}
              {keywords && (
                <Badge variant="green" title={keywords}>
                  keywords
                </Badge>
              )}
            </Fragment>
          )}
        </td>
        <td>
          <div className={styles.updated}>
            {formatDateTime(page.updatedAt)}
            {user && <UserInitials user={user} className={styles.initials} />}
          </div>
        </td>
      </tr>
    </PageContext.Provider>
  );
};
