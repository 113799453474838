import type { VFC } from 'react';
import React, { Fragment } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Button } from '@schibsted-svp/react-ui';

import { ComponentForm } from '../components/ComponentForm/ComponentForm';
import { CenteredSpinner } from '../components/CenteredSpinner/CenteredSpinner';
import { ContainerHeader } from '../components/Container/ContainerHeader/ContainerHeader';
import { useComponent } from '../hooks/useComponent';
import { createComponent, updateComponent } from '../services/api/components';
import { withFetchStatus } from '../services/notifier';

type Props = {
  match: {
    params?: {
      componentId: string;
    };
  };
};

export const Component: VFC<Props> = ({ match }) => {
  const history = useHistory();

  const { params: { componentId } = {} } = match;

  const {
    data: component,
    isFetching,
    refetch,
  } = useComponent(componentId === 'new' ? undefined : componentId);

  const onSubmit = async ({ id, ...payload }: any) => {
    if (!id) {
      const component = await withFetchStatus(createComponent, {
        success: 'Component created',
        error: 'Failed to create component',
      })({
        ...payload,
        isShared: true,
      });

      history.replace(`/components/${component.id}`);
    } else {
      await withFetchStatus(updateComponent, {
        success: 'Component updated',
        error: 'Failed to update component',
      })(id, payload);
      await refetch();
    }
  };

  return (
    <Fragment>
      <ContainerHeader>
        <Link to="/components">
          <Button type="button" variant="standard">
            Back to components
          </Button>
        </Link>
      </ContainerHeader>
      {isFetching ? (
        <CenteredSpinner />
      ) : (
        <ComponentForm component={component} isStandalone onSubmit={onSubmit} />
      )}
    </Fragment>
  );
};
