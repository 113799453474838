import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';
import { Radio, InputError } from '@schibsted-svp/react-ui';
import get from 'lodash/get';

import { FormField } from '../shared/FormField/FormField';

import styles from './RadioGroup.module.scss';

function RadioGroup({ name, label, options, disabled }) {
  const { values, errors, setFieldValue } = useFormikContext();

  const formValue = useMemo(() => get(values, name), [name, values]);
  const formError = useMemo(() => get(errors, name), [name, errors]);

  return (
    <FormField label={label}>
      {options.map(({ value, label }) => (
        <div className={styles.radio} key={value.toString()}>
          <Radio
            name={name}
            label={label}
            value={value}
            disabled={disabled}
            checked={value === formValue}
            onChange={() => setFieldValue(name, value)}
          />
        </div>
      ))}
      {typeof formError === 'string' && <InputError>{formError}</InputError>}
    </FormField>
  );
}

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  disabled: PropTypes.bool,
};

export { RadioGroup };
