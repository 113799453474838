import React, { useContext } from 'react';

import { Button } from '@schibsted-svp/react-ui';

import { config } from '../../../config';
import { UserContext } from '../../../contexts/UserContext';
import { UserInitials } from '../../UserInitials/UserInitials';

import styles from './SideMenu.module.scss';

const { svpAdminUrl } = config;

function SideMenu() {
  const { user } = useContext(UserContext);

  return (
    <div className={styles.SideMenuWrapper}>
      <a
        className={styles.streamLink}
        href={svpAdminUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button type="button" variant="standard">
          <span className={styles.streamLinkLabel}>
            <img
              src="/images/stream-logo.svg"
              alt="Stream logo"
              className={styles.streamLogo}
            />
            Stream
          </span>
        </Button>
      </a>

      <UserInitials user={user} size="big" />
    </div>
  );
}

// SideMenu.propTypes = {};

export { SideMenu };
