import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isNumber, isString } from '../../../../utils';
import { Badge } from '../../../Badge/Badge';
import { PageContext } from '../../../../contexts/PageContext';
import { ComponentContext } from '../../../../contexts/ComponentContext';

import { applyPageTaxonomyParams } from './applyPageTaxonomyParams';

import styles from './EndpointParamsList.module.scss';

function EndpointParamsList({
  params = {},
  className,
  small = false,
  inline = false,
}) {
  const { page } = useContext(PageContext);
  const { component } = useContext(ComponentContext);

  const paramsWithTaxonomy = applyPageTaxonomyParams(page, component, params);

  // flatten the params object
  const paramsList = Object.keys(paramsWithTaxonomy).reduce(
    (accumulator, param) => {
      const value = paramsWithTaxonomy[param];

      if (value === null) {
        return accumulator;
      }

      if (isString(value) || isNumber(value)) {
        accumulator.push({
          name: param,
          value,
        });
      } else {
        Object.keys(value).forEach((key) => {
          if (value[key] !== null) {
            accumulator.push({
              name: key,
              value: value[key],
            });
          }
        });
      }

      return accumulator;
    },
    []
  );

  return (
    <div
      className={classNames(styles.container, className, {
        [styles.isInline]: inline,
      })}
    >
      {paramsList.map(({ name, value }, index) => (
        <Badge variant="grey" key={index} small={small}>
          {name}: {Array.isArray(value) ? value.join(', ') : value}
        </Badge>
      ))}
    </div>
  );
}

EndpointParamsList.propTypes = {
  params: PropTypes.object,
  className: PropTypes.string,
  small: PropTypes.bool,
  inline: PropTypes.bool,
};

export { EndpointParamsList };
