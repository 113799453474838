import set from 'lodash/set';
import { PageType } from '@vgtv/api-client/lib/page';
import {
  AssetDataSource,
  CategoryDataSource,
  ProfileSource,
} from '@vgtv/api-client/lib/component';

/**
 * Overrides data source params based on page taxonomy if component.usePageTaxonomy is enabled.
 * Used to present correct (overridden) badges in components preview and list.
 * @param {PageDBO} [page]
 * @param {ComponentDBO} component
 * @param {object} params
 */
function applyPageTaxonomyParams(page, component, params) {
  const pageType = page?.type;
  const templateType =
    pageType === PageType.TEMPLATE ? page?.forType : undefined;

  const hasCategoryTaxonomy =
    pageType === PageType.CATEGORY || templateType === PageType.CATEGORY;

  const hasTagTaxonomy =
    pageType === PageType.TAG || templateType === PageType.TAG;

  const hasStoryTaxonomy =
    pageType === PageType.STORY || templateType === PageType.STORY;

  // clone nested objects to avoid mutations of the original one
  const paramsWithTaxonomy = { ...params, filter: { ...params.filter } };

  if (component.options?.usePageTaxonomy) {
    const endpoint = component.data?.source?.endpoint;
    if (hasCategoryTaxonomy) {
      if (
        [
          AssetDataSource.SVP_ASSETS,
          AssetDataSource.SVP_SEARCH,
          AssetDataSource.SVP_MOST_SEEN,
        ].includes(endpoint)
      ) {
        set(paramsWithTaxonomy, 'filter.categoryId', '«from page»');
      }

      if (endpoint === CategoryDataSource.SVP_CATEGORIES) {
        set(paramsWithTaxonomy, 'filter.parentId', '«from page»');
      }

      if (endpoint === ProfileSource.PROFILES) {
        set(paramsWithTaxonomy, 'categoryId', '«from page»');
      }
    }

    if (hasTagTaxonomy) {
      if (endpoint === AssetDataSource.SVP_ASSETS) {
        set(paramsWithTaxonomy, 'filter.tagId', '«from page»');
      }

      if (endpoint === AssetDataSource.SVP_SEARCH) {
        set(paramsWithTaxonomy, 'filter.tagsid', '«from page»');
      }
    }

    if (hasStoryTaxonomy) {
      if (endpoint === AssetDataSource.SVP_ASSETS) {
        set(paramsWithTaxonomy, 'filter.storyId', '«from page»');
      }

      if (endpoint === AssetDataSource.SVP_SEARCH) {
        set(paramsWithTaxonomy, 'filter.stories', '«from page»');
      }
    }
  }

  return paramsWithTaxonomy;
}

export { applyPageTaxonomyParams };
