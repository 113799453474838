import type { VFC } from 'react';
import React, { Fragment, useState } from 'react';

import classNames from 'classnames';
import { Button, Dropdown } from '@schibsted-svp/react-ui';
import type { ButtonSize } from '@schibsted-svp/react-ui/lib/es/Button/Button';

import { componentsConfiguration } from '../../../ComponentForm/configuration';
import { ComponentPicker } from '../../../ComponentPicker/ComponentPicker';
import { getComponentTypeLabel } from '../../../../helpers/getComponentTypeLabel';
import type { ComponentDBO } from '../../../../services/api/components';

import styles from './AddComponentButton.module.scss';

type Props = {
  index: number;
  label?: string;
  className?: string;
  onClick: (index: number, component: Partial<ComponentDBO>) => void;
  size?: ButtonSize;
  disabled?: boolean;
};

export const AddComponentButton: VFC<Props> = ({
  index,
  label,
  className,
  onClick,
  disabled = false,
  size = 'small',
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function onDialogClose() {
    setIsDialogOpen(false);
  }

  return (
    <Fragment>
      <Dropdown
        className={classNames(styles.dropdown, className)}
        mainButtonRenderer={(onClick) => (
          <Button
            type="button"
            onClick={onClick}
            iconOnly={label === undefined}
            size={size}
            variant="standard"
            disabled={disabled}
          >
            + {label}
          </Button>
        )}
      >
        {componentsConfiguration.componentsTypes.map((type) => (
          <Dropdown.Item
            key={type}
            itemKey={type}
            onClick={() => onClick(index, { type })}
          >
            {getComponentTypeLabel(type)}
          </Dropdown.Item>
        ))}
        <Dropdown.Item itemKey="shared" onClick={() => setIsDialogOpen(true)}>
          Shared
        </Dropdown.Item>
      </Dropdown>
      <ComponentPicker
        isOpen={isDialogOpen}
        onClose={onDialogClose}
        onPick={(component) => onClick(index, component)}
      />
    </Fragment>
  );
};
