import { AssetDataSource } from '@vgtv/api-client/lib/component';
import { type SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

import { getProvider } from '../svpProvider';

import { requestSVP, type SvpFetchParams } from './shared/requestSVP';

export const SVP_ASSET_ADDITIONAL = [
  'settings',
  'chapters',
  'cuePoints',
  'externalId',
  'externalCategoryId',
  'nextAsset',
  'sourceFiles',
  'subtitles',
  'metadata',
  'access',
  'tags',
  'stories',
].join('|');

export async function fetchAssetsFromEndpoint(
  endpoint: AssetDataSource,
  params: SvpFetchParams
) {
  if (endpoint === AssetDataSource.SVP_ASSETS) {
    return fetchAssets(params);
  }

  if (endpoint === AssetDataSource.SVP_MOST_SEEN) {
    return fetchMostSeenAssets(params);
  }

  if (endpoint === AssetDataSource.SVP_SEARCH) {
    return searchAssets(params);
  }
}

export async function fetchAssets(params: SvpFetchParams) {
  const filter: Record<string, string> =
    (params.filter as Record<string, string>) ?? {};
  filter.assetType = 'video';

  const json = await requestSVP<{
    _embedded: { assets: SvpAssetAdditional[] };
  }>({
    provider: getProvider(),
    path: '/assets',
    params: {
      limit: 10,
      ...params,
      filter,
      additional: SVP_ASSET_ADDITIONAL,
    },
  });

  return json._embedded ? json._embedded.assets : [];
}

export async function fetchMostSeenAssets(params: SvpFetchParams) {
  const filter: Record<string, string> =
    (params.filter as Record<string, string>) ?? {};
  filter.assetType = 'video';

  const json = await requestSVP<{
    _embedded: { assets: SvpAssetAdditional[] };
  }>({
    provider: getProvider(),
    path: '/assets/most-seen',
    params: {
      limit: 10,
      ...params,
      filter,
      additional: SVP_ASSET_ADDITIONAL,
    },
  });

  return json._embedded ? json._embedded.assets : [];
}

export async function searchAssets(params: SvpFetchParams) {
  const filter: Record<string, string> =
    (params.filter as Record<string, string>) ?? {};
  filter.assetType = 'video';

  const json = await requestSVP<{
    _embedded: { assets: SvpAssetAdditional[] };
  }>({
    provider: getProvider(),
    path: '/search',
    params: {
      limit: 10,
      ...params,
      filter,
      additional: SVP_ASSET_ADDITIONAL,
    },
  });

  return json._embedded ? json._embedded.assets : [];
}

export async function fetchAssetById(
  assetId: number,
  params: SvpFetchParams = {}
) {
  const filter: Record<string, string> =
    (params.filter as Record<string, string>) ?? {};
  filter.assetType = 'video';

  const json = await requestSVP<SvpAssetAdditional>({
    provider: getProvider(),
    path: `/assets/${assetId}`,
    params: {
      ...params,
      filter,
      additional: SVP_ASSET_ADDITIONAL,
    },
  });

  return json;
}
