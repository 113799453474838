import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Input, LabeledContainer } from '@schibsted-svp/react-ui';

const TableFilter = ({ onFilterChange }) => {
  const [filter, setFilter] = useState('');

  const onChange = useCallback(
    (event) => {
      const value = event.target.value || '';

      setFilter(value);
      onFilterChange(value);
    },
    [setFilter, onFilterChange]
  );

  return (
    <LabeledContainer label="Filter">
      <Input onChange={onChange} value={filter} />
    </LabeledContainer>
  );
};

TableFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
};

export { TableFilter };
