import { useQuery } from '@tanstack/react-query';

import type { EditorDBO } from '../services/api/editors';
import { fetchEditors } from '../services/api/editors';
import { withFetchStatus } from '../services/notifier';

const fetchEditorsWithStatus = withFetchStatus(fetchEditors, {
  error: 'Failed to fetch the list of editors',
});

export const useEditors = () => {
  return useQuery(['editors'], fetchEditorsWithStatus, {
    initialData: [] as EditorDBO[],
    refetchOnWindowFocus: false,
  });
};
