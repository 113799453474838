import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { CenteredSpinner } from '../CenteredSpinner/CenteredSpinner';
import { Input } from '../Form/Input/Input';
import { ImageUploadInput } from '../Form/ImageUploadInput/ImageUploadInput';
import { SubmitButton } from '../Form/SubmitButton/SubmitButton';
import { Form } from '../Form/Form';

import { FlightTimes } from './FlightTimes/FlightTimes';

function AssetForm({ asset, onSubmit, isLoading = false }) {
  const isLiveAsset = asset.streamType === 'live';

  return (
    <Form initialValues={asset} onSubmit={onSubmit}>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <Fragment>
          <Input
            name="title"
            label="Asset title"
            disabled
            value={asset.title}
          />
          {isLiveAsset && <FlightTimes />}
          <ImageUploadInput
            name="additional.metadata.verticalPosterImage"
            label="Vertical poster image"
            aspectRatio={2 / 3}
          />
        </Fragment>
      )}
      <SubmitButton>Save</SubmitButton>
    </Form>
  );
}

AssetForm.propTypes = {
  asset: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export { AssetForm };
