import { useQuery } from '@tanstack/react-query';

import { withFetchStatus } from '../services/notifier';
import type { StayLiveChannel } from '../services/staylive';
import { fetchChannels } from '../services/staylive';

const fetchChannelsWithStatus = withFetchStatus(fetchChannels, {
  error: 'Failed to fetch StayLive channels',
});

export const useStayLiveChannels = () => {
  return useQuery(['staylive'], fetchChannelsWithStatus, {
    initialData: [] as StayLiveChannel[],
    refetchOnWindowFocus: false,
  });
};
