import { getProvider } from '../svpProvider';
import { getLogger } from '../logger';

import { isSuccessResponse, requestAPI } from './shared/requestAPI';

const logger = getLogger('usersApi');

export type UserDBO = {
  id: string;
  provider: 'vgtv' | 'ab' | 'brandstudio';
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

const fetchUsers = async () => {
  try {
    const result = await requestAPI<UserDBO[]>(`/${getProvider()}/admin/users`);
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to fetch users');
    return [];
  }
};

async function fetchUser(userId: UserDBO['id']) {
  try {
    const result = await requestAPI<UserDBO>(
      `/${getProvider()}/admin/users/${userId}`
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to fetch the user', { userId });
    throw error;
  }
}

async function createUser(
  fields: Pick<UserDBO, 'firstName' | 'lastName' | 'email'>
) {
  try {
    const result = await requestAPI<UserDBO>(`/${getProvider()}/admin/users`, {
      method: 'POST',
      body: JSON.stringify(fields),
      headers: { 'Content-Type': 'application/json' },
    });
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to create user', { fields });
    throw error;
  }
}

async function updateUser(
  userId: UserDBO['id'],
  fields: Partial<Pick<UserDBO, 'firstName' | 'lastName' | 'role'>>
) {
  try {
    const result = await requestAPI<UserDBO>(
      `/${getProvider()}/admin/users/${userId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(fields),
        headers: { 'Content-Type': 'application/json' },
      }
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to update user', { userId, fields });
    throw error;
  }
}

async function fetchCurrentUser() {
  try {
    const result = await requestAPI<UserDBO>(
      `/${getProvider()}/admin/users/me`
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    if (error instanceof Error && error.message !== 'Invalid JWT') {
      logger.warn('Failed to fetch the current user');
    }
    throw error;
  }
}

export { fetchUsers, fetchUser, createUser, updateUser, fetchCurrentUser };
