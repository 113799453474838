import type { ChangeEvent, VFC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { Input } from '@schibsted-svp/react-ui';
import { DataType } from '@vgtv/api-client/lib/component';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

import * as svp from '../../../services/svp';
import { CenteredSpinner } from '../../CenteredSpinner/CenteredSpinner';
import { PresetItem } from '../PresetItem/PresetItem';
import { useDebounce } from '../../../hooks/useDebounce';
import { FormField } from '../../Form/shared/FormField/FormField';

import styles from './AssetsSearch.module.scss';

type Props = {
  selectedAssets: SvpAssetAdditional[];
  onAddClick: (asset: SvpAssetAdditional) => void;
  onRemoveClick?: (asset: SvpAssetAdditional) => void;
  label?: string;
};

export const AssetsSearch: VFC<Props> = ({
  selectedAssets = [],
  onAddClick,
  onRemoveClick,
  label = 'Search by title or id',
}) => {
  const [assets, setAssets] = useState<SvpAssetAdditional[]>([]);
  const [query, setQuery] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const debouncedQuery = useDebounce(query, 300);

  const fetchAssets = useCallback(async function (query) {
    setIsFetching(true);

    const assets = await svp.searchAssets({ query });

    setIsFetching(false);
    setAssets(assets);
  }, []);

  useEffect(() => {
    if (debouncedQuery) {
      fetchAssets(debouncedQuery);
    } else {
      setAssets([]);
    }
  }, [fetchAssets, debouncedQuery]);

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <FormField label={label}>
          <Input
            value={query}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              setQuery(value);
            }}
          />
        </FormField>
        {isFetching && <CenteredSpinner />}
        <ul className={styles.assetsList}>
          {assets.map((asset) => {
            const isSelected = selectedAssets
              .map((presetItem) => presetItem.id)
              .includes(asset.id);

            return (
              <li key={asset.id}>
                <PresetItem
                  item={asset}
                  dataType={DataType.ASSETS}
                  isSelected={isSelected}
                  onClick={() =>
                    isSelected ? onRemoveClick?.(asset) : onAddClick(asset)
                  }
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
