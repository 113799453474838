import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

import { getProvider } from '../svpProvider';
import { getLogger } from '../logger';

import { requestAPI } from './shared/requestAPI';

const logger = getLogger('assetsApi');

type AssetMetadata = {
  eventStartTime?: number;
  studioStartTime?: number;
} & { [k: string]: string };

export async function updateAssetMetadata(
  assetId: number,
  metadata: Partial<AssetMetadata>
) {
  try {
    return await requestAPI<SvpAssetAdditional>(
      `/${getProvider()}/admin/assets/${assetId}/metadata`,
      {
        method: 'PATCH',
        body: JSON.stringify(metadata),
        headers: { 'Content-Type': 'application/json' },
      }
    );
  } catch (error) {
    logger.error('Failed to update asset metadata', { assetId, metadata });
    throw error;
  }
}
