import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { ChangeEvent, VFC } from 'react';

import { Link } from 'react-router-dom';
import { type SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import { Button, Input } from '@schibsted-svp/react-ui';

import { getProvider } from '../services/svpProvider';
import type { SvpFetchParams } from '../services/svp/shared/requestSVP';
import { ContainerHeader } from '../components/Container/ContainerHeader/ContainerHeader';
import { FormField } from '../components/Form/shared/FormField/FormField';
import { AssetsList } from '../components/AssetsList/AssetsList';
import { useDebounce } from '../hooks/useDebounce';
import * as svp from '../services/svp';
import { CenteredSpinner } from '../components/CenteredSpinner/CenteredSpinner';
import { Spacer } from '../components/Spacer/Spacer';

type Props = {
  match: {
    params?: {
      kind: string;
    };
  };
};

const filters: Record<
  string,
  Record<
    string,
    {
      label: string;
      useVerticalThumbnails?: boolean;
      searchParams?: SvpFetchParams;
    }
  >
> = {
  vgtv: {
    documentaries: {
      label: 'Documentaries',
      useVerticalThumbnails: true,
      searchParams: {
        filter: {
          categoryId: 121,
          streamType: 'vod',
          duration: '>500000',
        },
        sort: 'created|published',
      },
    },
    series: {
      label: 'Series',
      searchParams: {
        filter: {
          'category.isSeries': 'true',
          streamType: 'vod',
          duration: '>500000',
        },
        sort: 'created',
      },
    },
  },
};

const buttons = Object.entries(filters[getProvider()] ?? {}).map(
  ([key, { label }]) => ({ key, label })
);

if (buttons.length) {
  buttons.unshift({ key: '', label: 'All' });
}

export const Assets: VFC<Props> = ({ match }) => {
  const { params: { kind = '' } = {} } = match;

  const [assets, setAssets] = useState<SvpAssetAdditional[]>([]);
  const [query, setQuery] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const debouncedQuery = useDebounce(query, 300);

  const options = useMemo(() => {
    const provider = getProvider();
    return filters[provider]?.[kind] || {};
  }, [kind]);

  const fetchAssets = useCallback(
    async function (query: string) {
      setIsFetching(true);
      const params: SvpFetchParams = {
        ...options.searchParams,
      };

      if (query) {
        params.query = query;
      } else {
        params.limit = 30;
        params.sort = 'published|created';
      }

      const assets = await svp.searchAssets(params);

      setIsFetching(false);
      setAssets(assets);
    },
    [options]
  );

  useEffect(() => {
    fetchAssets(debouncedQuery);
  }, [fetchAssets, debouncedQuery]);

  const getAssetLink = useCallback((asset: SvpAssetAdditional) => {
    return `/assets/${asset.id}/edit`;
  }, []);

  return (
    <>
      <ContainerHeader>
        <FormField label="Search by title or ID">
          <Input
            value={query}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              setQuery(value);
            }}
          />
        </FormField>
        <Spacer />
        {buttons.map(({ key, label }) => (
          <Link to={`/assets${key ? `/${key}` : ''}`} key={key}>
            <Button
              type="button"
              variant={key === kind ? undefined : 'standard'}
            >
              {label}
            </Button>
          </Link>
        ))}
      </ContainerHeader>
      {isFetching ? (
        <CenteredSpinner />
      ) : (
        <AssetsList
          useVerticalThumbnails={options.useVerticalThumbnails}
          assets={assets}
          getLink={getAssetLink}
        />
      )}
    </>
  );
};
