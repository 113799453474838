import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, Select } from '@schibsted-svp/react-ui';
import { useFormikContext } from 'formik';

import { TagsSelect } from '../../Form/TagsSelect/TagsSelect';
import { StoriesSelect } from '../../Form/StoriesSelect/StoriesSelect';
import { FormField } from '../../Form/shared/FormField/FormField';
import { CategoriesSelect } from '../../Form/CategoriesSelect/CategoriesSelect';
import { FILTER_TAG, FILTER_STORY, FILTER_CATEGORY } from '../types';

// the tags, stories and categories async select boxes are now coupled with formik,
// thus -> the input name and clearing the formik state when a filter is set,
// it would be ideal if they could be used outside of a formik form
const FORMIK_FIELD_NAME = 'filter';

function AddFilterDialog({ isOpen = false, onClose, onAdd, filters }) {
  const [type, setType] = useState(filters[0]);
  const [filter, setFilter] = useState(null);
  const { setFieldValue } = useFormikContext();
  const typeValue = type.value;

  function onChange(option) {
    setFilter(option);
  }

  function onAddClick() {
    const values = {
      type: typeValue,
    };

    if (typeValue === FILTER_TAG) {
      values.tag = {
        id: filter.value,
        title: filter.label,
      };
    }

    if (typeValue === FILTER_STORY) {
      values.story = {
        id: filter.value,
        title: filter.label,
      };
    }

    if (typeValue === FILTER_CATEGORY) {
      values.category = {
        id: filter.value,
        title: filter.label,
      };
    }

    onAdd(values);
    setFieldValue(FORMIK_FIELD_NAME, undefined);
    onClose();
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} heading="Add a filter">
      <Dialog.Section>
        <FormField label="Type">
          <Select
            options={filters}
            onChange={(option) => {
              setType(option);
            }}
            value={type}
          />
        </FormField>
        {typeValue === FILTER_TAG && (
          <TagsSelect
            label="Tag"
            value={filter}
            onChange={onChange}
            name={FORMIK_FIELD_NAME}
          />
        )}
        {typeValue === FILTER_STORY && (
          <StoriesSelect
            label="Story"
            value={filter}
            onChange={onChange}
            name={FORMIK_FIELD_NAME}
          />
        )}
        {typeValue === FILTER_CATEGORY && (
          <CategoriesSelect
            label="Category"
            name={FORMIK_FIELD_NAME}
            value={filter}
            onChange={onChange}
          />
        )}
      </Dialog.Section>
      <Dialog.Section variant="darker" mode="flexRight">
        <Button type="button" onClick={onClose} variant="standard">
          Cancel
        </Button>
        <Button type="button" onClick={onAddClick} disabled={filter === null}>
          Add
        </Button>
      </Dialog.Section>
    </Dialog>
  );
}

AddFilterDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
};

export { AddFilterDialog };
