import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@schibsted-svp/react-ui';
import { Link } from 'react-router-dom';

import { Table } from '../Table/Table';

import { UserRow } from './UserRow/UserRow';

function UsersTable({ users, loading }) {
  const [filter, setFilter] = useState('');

  const filtered = useMemo(
    () =>
      users.filter(
        (user) =>
          user.firstName.toLowerCase().includes(filter.toLowerCase()) ||
          user.lastName.toLowerCase().includes(filter.toLowerCase()) ||
          user.email.toLowerCase().includes(filter.toLowerCase()) ||
          user.role.toLowerCase().includes(filter.toLowerCase())
      ),
    [users, filter]
  );

  return (
    <Fragment>
      <Table
        items={[...filtered]}
        renderHeader={() => (
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
          </tr>
        )}
        renderRow={(user) => <UserRow user={user} key={user.id} />}
        renderActions={() => (
          <Link to="/users/new">
            <Button type="button">Create a new user</Button>
          </Link>
        )}
        onFilterChange={setFilter}
        loading={loading}
      />
    </Fragment>
  );
}

UsersTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

export { UsersTable };
