import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isFunction from 'lodash/isFunction';
import isBoolean from 'lodash/isBoolean';

// TODO: use individual imports instead of re-exporting these

export {
  isString,
  isNumber,
  isFunction,
  isBoolean as isBool,
  capitalize as capitalizeFirstLetter,
};

const formatDuration = (ms: number) => {
  const s = ms / 1000;
  const seconds = Math.floor(s) % 60;
  const minutes = Math.floor(s / 60) % 60;
  const hours = Math.floor(s / 60 / 60);

  return (
    [
      [hours, 'h'],
      [minutes, 'm'],
      [seconds, 's'],
    ] as const
  )
    .filter(([v]) => v > 0)
    .map(([v, l]) => String(v) + l)
    .join(' ');
};

function formatDateTime(date: Date | string | number) {
  return dayjs(date).format('YYYY-MM-DD HH:mm');
}

async function request<T>(
  ...fetchParams: Parameters<typeof fetch>
): Promise<Omit<Response, 'json'> & { json(): Promise<T> }> {
  const [response] = await Promise.all([
    fetch(...fetchParams),
    new Promise((resolve) => setTimeout(resolve, 500)), // improves UX
  ]);

  return response;
}

function insertAtIndex<T>(array: T[], index: number, element: T) {
  return [...array.slice(0, index), element, ...array.slice(index)];
}

function updateAtIndex<T>(array: T[], index: number, element: T) {
  return [...array.slice(0, index), element, ...array.slice(index + 1)];
}

function removeAtIndex<T>(array: T[], index: number) {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

function blobToDataUrl(blob: Blob): Promise<FileReader['result']> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onabort = reject;
    reader.onerror = reject;

    reader.readAsDataURL(blob);
  });
}

async function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      resolve(image);
    };
    image.onerror = reject;

    image.src = src;
  });
}

export {
  formatDuration,
  formatDateTime,
  request,
  insertAtIndex,
  updateAtIndex,
  removeAtIndex,
  blobToDataUrl,
  loadImage,
};
