import type { VFC } from 'react';
import React from 'react';

import classNames from 'classnames';

import styles from './Thumbnail.module.scss';

type Props = {
  url?: string;
  alt?: string;
  isRound?: boolean;
  aspectRatio?: `${number}/${number}`;
  className?: string;
};

export const Thumbnail: VFC<Props> = ({
  url,
  alt = '',
  isRound,
  aspectRatio: aspectRatioProp = '16/9',
  className,
}) => {
  const aspectRatio = isRound ? '1/1' : aspectRatioProp;

  return (
    <div
      style={{ aspectRatio }}
      className={classNames(styles.thumbnail, className, {
        [styles.isRound]: isRound,
        [styles.isBroken]: !url,
      })}
    >
      <img src={url} alt={alt} />
    </div>
  );
};
