import { useQuery } from '@tanstack/react-query';

import { fetchUser } from '../services/api/users';
import { withFetchStatus } from '../services/notifier';

const fetchUserWithStatus = withFetchStatus(fetchUser, {
  error: 'Failed to fetch user',
});

export const useUser = (userId?: string) => {
  return useQuery(['user', userId], () => fetchUserWithStatus(userId!), {
    enabled: Boolean(userId),
    refetchOnWindowFocus: false,
  });
};
