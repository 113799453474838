import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Checkbox, Spinner } from '@schibsted-svp/react-ui';

import styles from './UpdatingCheckbox.module.scss';

function UpdatingCheckbox({ value, onChange, disabled, label }) {
  const [isUpdating, setIsUpdating] = useState(false);

  async function update(value) {
    setIsUpdating(true);

    await onChange(value);

    setIsUpdating(false);
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.isUpdating]: isUpdating,
      })}
    >
      <Spinner size={'small'} className={styles.spinner} />
      <Checkbox
        label={label}
        checked={value}
        onChange={async (event) => {
          const value = event.currentTarget.checked;

          await update(value);
        }}
        disabled={disabled || isUpdating}
        className={styles.checkbox}
      />
    </div>
  );
}

UpdatingCheckbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export { UpdatingCheckbox };
