import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { Button } from '@schibsted-svp/react-ui';

import { ContainerHeader } from '../Container/ContainerHeader/ContainerHeader';
import { CenteredSpinner } from '../CenteredSpinner/CenteredSpinner';
import { Input } from '../Form/Input/Input';
import { SubmitButton } from '../Form/SubmitButton/SubmitButton';
import { Form } from '../Form/Form';
import { ImageUploadInput } from '../Form/ImageUploadInput/ImageUploadInput';

function CategoryForm({ category, onSubmit, isLoading = false }) {
  return (
    <Form initialValues={category} onSubmit={onSubmit}>
      <ContainerHeader>
        <Link to="/categories">
          <Button type="button" variant="standard">
            Back
          </Button>
        </Link>
      </ContainerHeader>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <Fragment>
          <Input
            name="title"
            label="Category title"
            disabled
            value={category.title}
          />
          <ImageUploadInput
            name="additional.metadata.verticalPoster.value"
            label="Vertical poster image"
            aspectRatio={2 / 3}
          />
        </Fragment>
      )}
      <SubmitButton>Save</SubmitButton>
    </Form>
  );
}

CategoryForm.propTypes = {
  category: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export { CategoryForm };
