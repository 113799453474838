import type { VFC } from 'react';
import React from 'react';

import { useHistory } from 'react-router-dom';

import { ComponentsPreview } from '../ComponentsPreview/ComponentsPreview';
import { formatDateTime } from '../../../utils';
import { UserInitials } from '../../UserInitials/UserInitials';
import type { UserDBO } from '../../../services/api/users';
import type { ExpandedPage } from '../../../services/api/pages';

import styles from './TemplateRow.module.scss';

type Props = {
  page: ExpandedPage;
  user?: UserDBO;
};

export const TemplateRow: VFC<Props> = ({ page, user }) => {
  const history = useHistory();

  const { id, components } = page;

  return (
    <tr onClick={() => history.push(`/pages/${id}`)} className={styles.row}>
      <td>
        <span className={styles.pageType}>{page.forType} page template</span>
      </td>
      <td>
        <ComponentsPreview components={components} />
      </td>
      <td />
      <td>
        <div className={styles.updated}>
          {formatDateTime(page.updatedAt)}
          {user && <UserInitials user={user} className={styles.initials} />}
        </div>
      </td>
    </tr>
  );
};
