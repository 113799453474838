import PropTypes from 'prop-types';
import React from 'react';

import { useHistory } from 'react-router-dom';

import { capitalizeFirstLetter, formatDateTime } from '../../../utils';
import { ComponentContext } from '../../../contexts/ComponentContext';
import { ComponentBadges } from '../../ComponentBadges/ComponentBadges';
import { Badge } from '../../Badge/Badge';
import { UserInitials } from '../../UserInitials/UserInitials';

import styles from './ComponentRow.module.scss';

/**
 * @param {object} props
 * @param {ComponentDBO} props.component
 * @param {UserDBO} props.user
 * @return {React.ReactElement}
 */
function ComponentRow({ component, user }) {
  const history = useHistory();

  const { id, type, header, sharedName } = component;

  return (
    <ComponentContext.Provider value={{ component }}>
      <tr
        onClick={() => history.push(`/components/${id}`)}
        className={styles.row}
      >
        <td>{sharedName}</td>
        <td>
          <div className={styles.componentDetails}>
            <span className={styles.componentType}>
              {capitalizeFirstLetter(type)}
            </span>
            {header && (
              <span className={styles.componentHeader}>"{header}"</span>
            )}
            <ComponentBadges component={component} small />
            {component.options && component.options.usePageTaxonomy && (
              <Badge small>«page taxonomy»</Badge>
            )}
          </div>
        </td>
        <td>
          <div className={styles.updated}>
            {formatDateTime(component.updatedAt)}
            {user && <UserInitials user={user} className={styles.initials} />}
          </div>
        </td>
      </tr>
    </ComponentContext.Provider>
  );
}

ComponentRow.propTypes = {
  component: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export { ComponentRow };
