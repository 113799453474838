import type { VFC } from 'react';

import Initials, {
  type InitialsProps,
} from '@schibsted-svp/react-ui/lib/es/Initials/Initials';
import classNames from 'classnames';

import type { UserDBO } from '../../services/api/users';

import styles from './UserInitials.module.scss';

export const UserInitials: VFC<
  Omit<InitialsProps, 'children'> & { user?: UserDBO }
> = ({ user, className, ...props }) => {
  if (!user) {
    return null;
  }

  const fullName = `${user.firstName} ${user.lastName}`;
  return (
    <Initials className={classNames(styles.root, className)} {...props}>
      {fullName}
    </Initials>
  );
};
