import { useQuery } from '@tanstack/react-query';

import { fetchPages } from '../services/api/pages';
import { withFetchStatus } from '../services/notifier';

const fetchPagesWithStatus = withFetchStatus(fetchPages, {
  error: 'Failed to fetch the list of pages',
});

export const usePages = () => {
  return useQuery(['pages'], fetchPagesWithStatus, {
    initialData: [],
    refetchOnWindowFocus: false,
  });
};
