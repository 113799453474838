import { useQuery } from '@tanstack/react-query';

import {
  type EnrichmentDBO,
  fetchTagsEnrichments,
} from '../services/api/enrichments';
import { withFetchStatus } from '../services/notifier';

const fetchEnrichmentsWithStatus = withFetchStatus(fetchTagsEnrichments, {
  error: 'Failed to fetch the list of enrichments',
});

export const useTagsEnrichments = () => {
  return useQuery<EnrichmentDBO[]>(
    ['enrichments'],
    fetchEnrichmentsWithStatus,
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );
};
