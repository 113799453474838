import type { VFC } from 'react';
import React from 'react';

import classNames from 'classnames';
import { Button } from '@schibsted-svp/react-ui';
import { DataType } from '@vgtv/api-client/lib/component';
import type { SvpCategoryAdditional } from '@vgtv/api-client/lib/svp_category';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

import { Thumbnail } from '../../Thumbnail/Thumbnail';
import { getItemDataType } from '../helpers';
import type { EnrichedTag } from '../../../services/api/enrichments';
import { formatDuration } from '../../../utils';

import styles from './PresetItem.module.scss';

export type PresetElement =
  | SvpCategoryAdditional
  | SvpAssetAdditional
  | EnrichedTag;

const getElementImageUrl = (item: PresetElement, itemDataType?: string) => {
  switch (itemDataType) {
    case 'category': {
      const category = item as SvpCategoryAdditional;
      return category.additional
        ? `${category.additional.image}?t[]=161x91q80`
        : undefined;
    }
    case 'asset': {
      const asset = item as SvpAssetAdditional;
      return asset.images ? `${asset.images.main}?t[]=161x91q80` : undefined;
    }
    case 'story': {
      const story = item as EnrichedTag;
      return story.images ? `${story.images.main}?t[]=180q80` : undefined;
    }
    case 'profile': {
      const profile = item as EnrichedTag;
      return profile.images ? `${profile.images.main}?t[]=180q80` : undefined;
    }
  }
};

const getElementLabels = (item: PresetElement, itemDataType?: string) => {
  let labels: string[] = [];
  switch (itemDataType) {
    case 'category': {
      const category = item as SvpCategoryAdditional;
      labels.push(category.isSeries ? 'Series' : 'Category');
      if (!category.showCategory) {
        labels.push('Archived');
      }
      break;
    }
    case 'asset': {
      const asset = item as SvpAssetAdditional;
      labels.push(asset.series ? 'Episode' : 'Asset');
      labels.push(asset.category.title);
      if (asset.streamType !== 'live') {
        labels.push(formatDuration(asset.duration));
      } else {
        labels.push('Live');
      }
      if (!asset.category.showCategory || asset.status !== 'active') {
        labels.push('Archived');
      }
      break;
    }
    case 'story': {
      labels.push('Story');
      break;
    }
    case 'profile': {
      labels.push('Profile');
      break;
    }
  }

  return labels.join(' • ');
};

type Props = {
  item: PresetElement;
  dataType: DataType;
  onClick: (item: PresetElement) => void;
  isDraggable?: boolean;
  isSelected?: boolean;
};

export const PresetItem: VFC<Props> = ({
  item,
  dataType,
  onClick,
  isDraggable = false,
  isSelected = false,
}) => {
  const itemDataType = getItemDataType(item, dataType);
  let imageUrl = getElementImageUrl(item, itemDataType);
  const labels = getElementLabels(item, itemDataType);

  let title = item.title;

  const hasImage = imageUrl !== undefined;

  return (
    <div
      className={classNames(styles.container, {
        [styles.draggable]: isDraggable,
        [styles.selected]: isSelected,
        [styles.disabled]: !hasImage,
      })}
      onClick={!isDraggable ? () => onClick(item) : undefined}
      title={!hasImage ? 'No image' : undefined}
    >
      <div className={styles.details}>
        <div
          className={classNames(styles.thumbnail, {
            [styles.noImage]: !hasImage,
          })}
        >
          {imageUrl && (
            <Thumbnail
              url={imageUrl}
              isRound={dataType === DataType.PROFILES}
              aspectRatio={dataType === DataType.STORIES ? '9/16' : undefined}
            />
          )}
        </div>
        <div className={styles.title}>
          {title}
          {labels && <div className={styles.subtitle}>{labels}</div>}
        </div>
      </div>
      <Button
        type="button"
        variant="standard"
        size="compact"
        className={styles.button}
        onClick={isDraggable ? () => onClick(item) : undefined}
      >
        {isSelected ? '× Remove' : '+ Add'}
      </Button>
    </div>
  );
};
