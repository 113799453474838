import { useQuery } from '@tanstack/react-query';

import { fetchPage } from '../services/api/pages';
import { withFetchStatus } from '../services/notifier';

const fetchPageWithStatus = withFetchStatus(fetchPage, {
  error: 'Failed to fetch the page',
});

export const usePage = (pageId?: string) => {
  return useQuery(['page', pageId], () => fetchPageWithStatus(pageId!), {
    enabled: Boolean(pageId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
