import React from 'react';
import PropTypes from 'prop-types';

import { TagsSelect } from '../Form/TagsSelect/TagsSelect';
import { ImageUploadInput } from '../Form/ImageUploadInput/ImageUploadInput';
import { SubmitButton } from '../Form/SubmitButton/SubmitButton';
import { Form } from '../Form/Form';
import { Input } from '../Form/Input/Input';
import { Checkbox } from '../Form/Checkbox/Checkbox';
import { CategoriesSelect } from '../Form/CategoriesSelect/CategoriesSelect';
import { useTag } from '../../hooks/useTag';

import styles from './TagForm.module.scss';

function TagForm({ enrichment = {}, onSubmit }) {
  const isNew = enrichment.id === undefined;
  const { data: tag } = useTag(enrichment.tagId);

  return (
    <Form initialValues={enrichment} onSubmit={onSubmit}>
      {isNew && <TagsSelect label="Tag" name="tagId" />}
      {!isNew && (
        <Input label="Tag" value={tag?.title ?? ''} name="" disabled />
      )}
      <CategoriesSelect label="Related category" name="categoryId" />
      <Checkbox label="Use as a person profile" name="isProfile" />
      <ImageUploadInput label="Main image" name="images.main" aspectRatio={1} />
      <ImageUploadInput label="Background image" name="images.background" />
      <Input
        label="Description"
        name="description"
        type="textarea"
        size="big"
      />
      <div className={styles.buttons}>
        <SubmitButton>{isNew ? 'Create tag enrichment' : 'Save'}</SubmitButton>
      </div>
    </Form>
  );
}

TagForm.propTypes = {
  enrichment: PropTypes.shape({ tagId: PropTypes.string.isRequired }),
  onSubmit: PropTypes.func.isRequired,
};

export { TagForm };
