import React, { type VFC } from 'react';

import { EditorsTable } from '../components/EditorsTable/EditorsTable';
import { useEditors } from '../hooks/useEditors';

export const Editors: VFC = () => {
  const { data: editors = [], isLoading } = useEditors();

  return <EditorsTable editors={editors} loading={isLoading} />;
};
