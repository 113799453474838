import type { VFC } from 'react';
import React, { useCallback } from 'react';

import classNames from 'classnames';
import { Button } from '@schibsted-svp/react-ui';
import { useFormikContext } from 'formik';
import { ComponentType, DataType } from '@vgtv/api-client/lib/component';
import { omit } from 'lodash';
import get from 'lodash/get';

import { Form } from '../Form/Form';
import { SubmitButton } from '../Form/SubmitButton/SubmitButton';
import { Input } from '../Form/Input/Input';
import { Select } from '../Form/Select/Select';
import { CollapsibleSection } from '../Form/CollapsibleSection/CollapsibleSection';
import { Checkbox } from '../Form/Checkbox/Checkbox';
import { RadioGroup } from '../Form/RadioGroup/RadioGroup';
import { getComponentTypeLabel } from '../../helpers/getComponentTypeLabel';
import { CategoriesSelect } from '../Form/CategoriesSelect/CategoriesSelect';
import type {
  ComponentDBO,
  ComponentInput,
} from '../../services/api/components';
import { isAsset, isCategory } from '../PresetPicker/helpers';
import type { PresetElement } from '../PresetPicker/PresetItem/PresetItem';

import { componentsConfiguration } from './configuration';
import { DataSource } from './DataSource/DataSource';
import { SaveToSharedButton } from './SaveToSharedButton/SaveToSharedButton';
import { FilteredListFields } from './FilteredListFields/FilteredListFields';
import { SplashFields } from './SplashFields/SplashFields';
import { EditorialFields } from './EditorialFields/EditorialFields';
import { ScheduledFields } from './ScheduledFields/ScheduledFields';

import styles from './ComponentForm.module.scss';

const toPresetUri = (blob: PresetElement) => {
  if (isAsset(blob)) {
    return `asset:${blob.id}`;
  }
  if (isCategory(blob)) {
    return `category:${blob.id}`;
  }
  return `tag:${blob.tagId}`;
};

const LayoutOptions = () => {
  const { values } = useFormikContext<Partial<ComponentDBO>>();

  const useVerticalPosters = values?.options?.useVerticalPosters;
  const componentType = values?.type;
  const dataType = values?.data?.type;

  const hasCheckbox =
    dataType === DataType.ASSETS ||
    dataType === DataType.SEASONS ||
    dataType === DataType.CATEGORIES ||
    dataType === DataType.MIXED ||
    componentType === ComponentType.FILTERED_LIST ||
    componentType === ComponentType.LIVE_ASSETS_SCHEDULE;
  const hasRadio = componentType === ComponentType.LIST;
  const hasSortByFlightTimes =
    dataType === DataType.ASSETS &&
    (componentType === ComponentType.SLIDER ||
      componentType === ComponentType.CAROUSEL);
  const hasSortByEpisodeNumber = dataType === DataType.SEASONS;
  const hasHideSearchField = componentType === ComponentType.FILTERED_LIST;

  if (!hasCheckbox && !hasRadio) {
    return null;
  }

  return (
    <CollapsibleSection label="Options" initiallyCollapsed={false}>
      {hasCheckbox &&
        componentType !== ComponentType.LIVE_ASSETS_SCHEDULE &&
        componentType !== ComponentType.CAROUSEL &&
        dataType !== DataType.CATEGORIES &&
        dataType !== DataType.MIXED && (
          <Checkbox
            label="Use vertical posters"
            name="options.useVerticalPosters"
          />
        )}
      {hasRadio && (
        <RadioGroup
          label="Assets layout"
          name="options.layout"
          disabled={useVerticalPosters}
          options={[
            {
              label: 'Vertical (asset details below the image)',
              value: 'vertical',
            },
            {
              label: 'Horizontal (asset details next to the image)',
              value: 'horizontal',
            },
          ]}
        />
      )}
      {hasSortByFlightTimes && (
        <Checkbox
          label="Sort by flight times"
          name="options.sortByFlightTimes"
        />
      )}
      {hasHideSearchField && (
        <Checkbox label="Hide search field" name="options.hideSearchField" />
      )}
      {componentType === ComponentType.LIVE_ASSETS_SCHEDULE && (
        <Checkbox label="Full-fledged" name="options.isFullFledged" />
      )}
      {hasSortByEpisodeNumber && (
        <RadioGroup
          label="Sort by episode number"
          name="options.sortByEpisodeNumber"
          options={[
            {
              label: 'Ascending (oldest episodes first)',
              value: 'asc',
            },
            {
              label: 'Descending (latest episodes first)',
              value: 'desc',
            },
          ]}
        />
      )}
      {componentType === ComponentType.SLIDER &&
        (dataType === DataType.CATEGORIES ||
          dataType === DataType.ASSETS ||
          dataType === DataType.MIXED) && (
          <>
            <Checkbox
              label="Use large thumbnails"
              name="options.useLargeThumbnails"
            />
            <Checkbox
              label="Skip metadata (do not display title and description below thumbnails)"
              name="options.skipMetadata"
            />
          </>
        )}
    </CollapsibleSection>
  );
};

type Props = {
  component?: Partial<ComponentDBO>;
  onCancelClick?: () => void;
  onSubmit: (
    componentDraft:
      | (ComponentInput & { id: undefined })
      | (Partial<ComponentInput> & { id: string })
  ) => Promise<void>;
  isStandalone?: boolean;
};

export const ComponentForm: VFC<Props> = ({
  component = {},
  onCancelClick,
  onSubmit,
  isStandalone = false,
}) => {
  const isNew = !component.id;
  const inputsSize = isStandalone ? 'big' : 'small';

  const componentsTypesSelectOptions =
    componentsConfiguration.componentsTypes.map((type) => ({
      value: type,
      label: getComponentTypeLabel(type),
    }));

  const handleSubmit = useCallback(
    (
      draft:
        | (ComponentInput & { id: undefined })
        | (Partial<ComponentInput> & { id: string })
    ) => {
      const preset = get(draft.data, 'preset', []) as PresetElement[];

      return onSubmit({
        ...draft,
        data: {
          ...omit(draft.data, 'preset'),
          preset2: preset.map(toPresetUri),
        },
      });
    },
    [onSubmit]
  );

  return (
    <Form
      initialValues={component}
      onSubmit={handleSubmit}
      className={classNames(styles.container, {
        [styles.isStandalone]: isStandalone,
      })}
    >
      {({ values: { data = {}, type: componentType } }) => (
        <>
          {isStandalone && <Input label="Shared name" name="sharedName" />}
          <Select
            label="Type"
            name="type"
            disabled={!isNew}
            options={componentsTypesSelectOptions}
            size={inputsSize}
          />
          {componentType !== ComponentType.SPLASH &&
            componentType !== ComponentType.CAROUSEL &&
            data.type !== DataType.SEASONS && (
              <Input label="Header" name="header" size={inputsSize} />
            )}
          {componentType === ComponentType.LIVE_ASSETS_SCHEDULE && (
            <ScheduledFields />
          )}
          {componentType === ComponentType.EDITORIAL && (
            <EditorialFields inputsSize={inputsSize} />
          )}
          <DataSource inputsSize={inputsSize} />
          {data.type === DataType.SEASONS && (
            <CategoriesSelect
              label="Series"
              name="categoryId"
              params={{ filter: 'isSeries::true' }}
            />
          )}
          {componentType === ComponentType.SPLASH && (
            <SplashFields inputsSize={inputsSize} />
          )}
          {componentType === ComponentType.FILTERED_LIST && (
            <FilteredListFields isStandalone={isStandalone} />
          )}
          {componentType !== ComponentType.SPLASH &&
            componentType !== ComponentType.CONTINUE_WATCHING && (
              <LayoutOptions />
            )}
          <div className={styles.buttons}>
            {!isNew && !isStandalone && (
              <SaveToSharedButton
                disabled={component.isShared}
                onSubmit={(values) => onSubmit({ ...values, id: component.id })}
              />
            )}
            {typeof onCancelClick === 'function' && (
              <Button
                size="small"
                type="button"
                variant="standard"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            )}
            <SubmitButton
              size={isStandalone ? 'big' : 'small'}
              className={styles.submit}
            >
              {isNew ? 'Create component' : 'Save'}
            </SubmitButton>
          </div>
        </>
      )}
    </Form>
  );
};
