import { useQuery } from '@tanstack/react-query';

import { fetchAssetById } from '../services/svp';
import { withFetchStatus } from '../services/notifier';

const defaultParams = { additional: 'metadata' };

const fetchAssetWithStatus = withFetchStatus(fetchAssetById, {
  error: 'Failed to fetch asset',
});

export const useAsset = (assetId?: number) => {
  return useQuery(
    ['asset', assetId],
    () => fetchAssetWithStatus(assetId!, defaultParams),
    { enabled: Boolean(assetId), refetchOnWindowFocus: false }
  );
};
