import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { DataType } from '@vgtv/api-client/lib/component';

import { fetchAssetsFromEndpoint } from '../../../../../../services/svp/assets';
import { fetchCategories } from '../../../../../../services/svp/categories';
import { Slider } from '../../../../../Slider/Slider';
import { Asset } from '../../../../../Slider/items/Asset/Asset';
import { Category } from '../../../../../Slider/items/Category/Category';
import { fetchProfiles } from '../../../../../../services/api/enrichments';
import { Profile } from '../../../../../Slider/items/Profile/Profile';
import { Story } from '../../../../../Slider/items/Story/Story';
import { defaultStoryFilters } from '../../../../../PresetPicker/StoriesSearch/StoriesSearch';
import { isTruthy } from '../../../../../../helpers/types';

function EndpointItemsSlider({ endpoint, params, dataType }) {
  const [items, setItems] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const fetchItems = useCallback(() => {
    if (dataType === DataType.ASSETS) {
      return fetchAssetsFromEndpoint(endpoint, params);
    }

    if (dataType === DataType.STORIES) {
      return fetchAssetsFromEndpoint(endpoint, {
        ...params,
        filter: {
          ...params?.filter,
          ...defaultStoryFilters,
          tagsid: [defaultStoryFilters.tagsid, params?.filter?.tagsid]
            .filter(isTruthy)
            .join(','),
        },
      });
    }

    if (dataType === DataType.CATEGORIES) {
      return fetchCategories(params);
    }

    if (dataType === DataType.PROFILES) {
      return fetchProfiles(params.categoryId || undefined);
    }
  }, [dataType, endpoint, params]);

  const fetch = useCallback(
    async function () {
      setIsFetching(true);

      const items = await fetchItems();

      setItems(items);
      setIsFetching(false);
    },
    [fetchItems]
  );

  const renderItem = useCallback(
    (item) => {
      if (dataType === DataType.ASSETS) {
        return <Asset asset={item} />;
      }

      if (dataType === DataType.STORIES) {
        return <Story asset={item} />;
      }

      if (dataType === DataType.CATEGORIES) {
        return <Category category={item} />;
      }

      if (dataType === DataType.PROFILES) {
        return <Profile profile={item} />;
      }
    },
    [dataType]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Slider
      items={items}
      renderItem={renderItem}
      isFetching={isFetching}
      isSmall={dataType === DataType.PROFILES}
    />
  );
}

EndpointItemsSlider.propTypes = {
  endpoint: PropTypes.string.isRequired,
  params: PropTypes.object,
  dataType: PropTypes.string.isRequired,
};

export { EndpointItemsSlider };
