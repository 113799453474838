import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Button } from '@schibsted-svp/react-ui';

import styles from './TablePagination.module.scss';

const TablePagination = ({ currentPage, pagesLength, setCurrentPage }) => {
  return (
    <div className={styles.pagination}>
      {pagesLength > 1 && (
        <Fragment>
          <Button
            type="button"
            size="small"
            variant="standard"
            disabled={currentPage === 0}
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
          >
            Previous page
          </Button>
          <div className={styles.counter}>
            {currentPage + 1} / {pagesLength}
          </div>
          <Button
            type="button"
            size="small"
            variant="standard"
            disabled={currentPage === pagesLength - 1}
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
          >
            Next page
          </Button>
        </Fragment>
      )}
    </div>
  );
};

TablePagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pagesLength: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

export { TablePagination };
