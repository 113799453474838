import React, { Fragment, useContext } from 'react';

import PropTypes from 'prop-types';
import { Button, Dialog, LabeledContainer } from '@schibsted-svp/react-ui';
import { useFormikContext } from 'formik';
import { PageType } from '@vgtv/api-client/lib/page';
import {
  AssetDataSource,
  CategoryDataSource,
  ComponentType,
  DataType,
  ProfileSource,
} from '@vgtv/api-client/lib/component';

import { Select } from '../../../../Form/Select/Select';
import { CategoriesSelect } from '../../../../Form/CategoriesSelect/CategoriesSelect';
import { FormValuesProvider } from '../../../../Form/FormValuesProvider/FormValuesProvider';
import { SubmitButton } from '../../../../Form/SubmitButton/SubmitButton';
import { Form } from '../../../../Form/Form';
import { PageContext } from '../../../../../contexts/PageContext';
import { StoriesSelect } from '../../../../Form/StoriesSelect/StoriesSelect';
import { TagsSelect } from '../../../../Form/TagsSelect/TagsSelect';

import { EndpointItemsSlider } from './EndpointItemsSlider/EndpointItemsSlider';
import { UsePageTaxonomyCheckbox } from './UsePageTaxonomyCheckbox/UsePageTaxonomyCheckbox';
import { SubscriptionsCheckbox } from './SubscriptionsCheckbox/SubscriptionsCheckbox';
import { UpcomingAssetsCheckbox } from './UpcomingAssetsCheckbox/UpcomingAssetsCheckbox';
import { StreamTypeSelect } from './StreamTypeSelect';

function EndpointParamsForm({
  dataType,
  endpoint,
  params,
  onSubmit,
  onCancel,
}) {
  const { values, setFieldValue } = useFormikContext();
  const { page = {} } = useContext(PageContext);

  const component = values;

  const isShared = component.isShared && page.type === undefined;

  const canUsePageTaxonomy =
    isShared ||
    (page.type !== PageType.CUSTOM &&
      [
        ComponentType.LIST,
        ComponentType.SLIDER,
        ComponentType.EDITORIAL,
      ].includes(component.type));

  const isTemplate = page.type === PageType.TEMPLATE;

  const hasCategoryTaxonomy =
    page.type === PageType.CATEGORY ||
    (isTemplate && page.forType === PageType.CATEGORY);

  const hasTagTaxonomy =
    page.type === PageType.TAG || (isTemplate && page.forType === PageType.TAG);

  const hasStoryTaxonomy =
    page.type === PageType.STORY ||
    (isTemplate && page.forType === PageType.STORY);

  const usePageTaxonomy =
    component.id !== undefined ||
    (component.options && component.options.usePageTaxonomy !== undefined) // so we can change this option for a new component with no id
      ? component.options.usePageTaxonomy
      : true; // since this defaults to true for new components

  const isUsingPageCategory = hasCategoryTaxonomy && usePageTaxonomy;
  const isUsingPageTag = hasTagTaxonomy && usePageTaxonomy;
  const isUsingPageStory = hasStoryTaxonomy && usePageTaxonomy;

  function renderCategoriesSelect(name, label) {
    return (
      <Fragment>
        <CategoriesSelect
          label={label}
          name={name}
          disabled={isUsingPageCategory}
        />
        {canUsePageTaxonomy && hasCategoryTaxonomy && (
          <UsePageTaxonomyCheckbox
            taxonomy={'category'}
            checked={usePageTaxonomy}
            setFieldValue={setFieldValue}
          />
        )}
      </Fragment>
    );
  }

  return (
    <Form initialValues={params} onSubmit={onSubmit}>
      <Dialog.Section>
        {isShared && (
          <UsePageTaxonomyCheckbox
            taxonomy={'taxonomy'}
            checked={usePageTaxonomy}
            setFieldValue={setFieldValue}
          />
        )}
        {endpoint === AssetDataSource.SVP_ASSETS && (
          <Fragment>
            <StreamTypeSelect />
            {renderCategoriesSelect('filter.categoryId', 'Category')}
            <TagsSelect
              isMulti
              label="Tag"
              name="filter.tag"
              disabled={isUsingPageTag}
            />
            {canUsePageTaxonomy && hasTagTaxonomy && (
              <UsePageTaxonomyCheckbox
                taxonomy={'tag'}
                checked={usePageTaxonomy}
                setFieldValue={setFieldValue}
              />
            )}
            <StoriesSelect
              label="Story"
              name="filter.story"
              disabled={isUsingPageStory}
            />
            {canUsePageTaxonomy && hasStoryTaxonomy && (
              <UsePageTaxonomyCheckbox
                taxonomy={'story'}
                checked={usePageTaxonomy}
                setFieldValue={setFieldValue}
              />
            )}
          </Fragment>
        )}
        {endpoint === AssetDataSource.SVP_SEARCH &&
          dataType !== DataType.STORIES && (
            <Fragment>
              <StreamTypeSelect />
              {renderCategoriesSelect('filter.categoryId', 'Category')}
              <TagsSelect
                isMulti
                label="Tag"
                name="filter.tagsid"
                disabled={isUsingPageTag}
              />
              {canUsePageTaxonomy && hasTagTaxonomy && (
                <UsePageTaxonomyCheckbox
                  taxonomy={'tag'}
                  checked={usePageTaxonomy}
                  setFieldValue={setFieldValue}
                />
              )}
              <StoriesSelect
                label="Story"
                name="filter.stories"
                disabled={isUsingPageStory}
              />
              {canUsePageTaxonomy && hasStoryTaxonomy && (
                <UsePageTaxonomyCheckbox
                  taxonomy={'story'}
                  checked={usePageTaxonomy}
                  setFieldValue={setFieldValue}
                />
              )}
              <LabeledContainer label="Required subscriptions">
                <SubscriptionsCheckbox name="filter.access" />
              </LabeledContainer>
              <UpcomingAssetsCheckbox name="filter.flightTimesStart" />
            </Fragment>
          )}
        {endpoint === AssetDataSource.SVP_SEARCH &&
          dataType === DataType.STORIES && (
            <Fragment>
              {renderCategoriesSelect('filter.categoryId', 'Category')}
              <TagsSelect
                isMulti
                label="Tag"
                name="filter.tagsid"
                disabled={isUsingPageTag}
              />
            </Fragment>
          )}
        {endpoint === AssetDataSource.SVP_MOST_SEEN && (
          <Fragment>
            <Select
              label="Interval"
              name="interval"
              options={[
                { value: 'hour', label: 'Last hour' },
                { value: 'day', label: 'Last 24 hours' },
                { value: 'week', label: 'This week' },
                { value: 'month', label: 'This month' },
              ]}
            />
            {renderCategoriesSelect('filter.categoryId', 'Category')}
          </Fragment>
        )}
        {endpoint === CategoryDataSource.SVP_CATEGORIES && (
          <Fragment>
            {renderCategoriesSelect('filter.parentId', 'Parent category')}
          </Fragment>
        )}
        {endpoint === ProfileSource.PROFILES &&
          renderCategoriesSelect('categoryId', 'Related category')}
        <LabeledContainer label="Preview">
          <FormValuesProvider
            component={EndpointItemsSlider}
            endpoint={endpoint}
            dataType={dataType}
            valuesPropName="params"
          />
        </LabeledContainer>
      </Dialog.Section>
      <Dialog.Section variant="darker" mode="flexRight">
        <Button type="button" onClick={onCancel} variant="standard">
          Cancel
        </Button>
        <SubmitButton>Set</SubmitButton>
      </Dialog.Section>
    </Form>
  );
}

EndpointParamsForm.propTypes = {
  endpoint: PropTypes.string.isRequired,
  dataType: PropTypes.string.isRequired,
  params: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export { EndpointParamsForm };
