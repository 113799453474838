import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

import styles from './ImageDropzone.module.scss';

function ImageDropzone({ onDrop, className }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        onDrop(acceptedFiles[0]);
      }
    },
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png'],
    },
  });

  return (
    <div
      className={classNames(
        styles.dropzone,
        {
          [styles.isDragActive]: isDragActive,
        },
        className
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className={styles.label}>
        Drop only one file to upload
        <br /> or <span className={styles.link}>browse</span>
      </div>
    </div>
  );
}

ImageDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export { ImageDropzone };
