export const createUrlPartial = (path: string, query?: URLSearchParams) => {
  if (query) {
    const queryString = query.toString();
    if (queryString.length > 0) {
      return `${path}?${queryString}`;
    }
  }

  return path;
};

export const toSearchParams = (
  params: Record<
    string,
    string | number | boolean | (string | number)[] | undefined
  >
) => {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      searchParams.set(key, String(value));
    }
  });
  return searchParams;
};
