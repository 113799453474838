import type { VFC } from 'react';
import React, { Fragment, useState } from 'react';

import { Button, LabeledContainer } from '@schibsted-svp/react-ui';

import { AddComponentButton } from '../../AddComponentButton/AddComponentButton';
import { ComponentContext } from '../../../../../contexts/ComponentContext';
import { ComponentBadges } from '../../../../ComponentBadges/ComponentBadges';
import { getComponentTypeLabel } from '../../../../../helpers/getComponentTypeLabel';
import type { ComponentDBO } from '../../../../../services/api/components';

import styles from './ComponentBody.module.scss';

type Props = {
  component: ComponentDBO;
  index: number;
  onAddClick: (index: number, component: Partial<ComponentDBO>) => void;
  onEditClick: () => void;
  onRemoveClick: (index: number) => void;
  isEditable: boolean;
};

export const ComponentBody: VFC<Props> = ({
  component,
  index,
  onAddClick,
  onEditClick,
  onRemoveClick,
  isEditable,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);

  async function onRemoveButtonClick() {
    setIsRemoving(true);

    await onRemoveClick(index);

    setIsRemoving(false);
  }

  const componentLabel = `${getComponentTypeLabel(component.type)}${
    component.header ? ` "${component.header}"` : ''
  }`;

  return (
    <ComponentContext.Provider value={{ component }}>
      <div className={styles.container}>
        <LabeledContainer label={componentLabel}>
          <ComponentBadges component={component} />
        </LabeledContainer>
        {isEditable && (
          <Fragment>
            <div className={styles.componentButtons}>
              <Button
                type="button"
                onClick={onEditClick}
                size="small"
                variant="standard"
              >
                Edit
              </Button>
              <Button
                type="button"
                onClick={onRemoveButtonClick}
                iconOnly
                size="small"
                variant="standard"
                className={styles.removeButton}
                loading={isRemoving}
              >
                &times;
              </Button>
            </div>
            <AddComponentButton
              index={index}
              className={styles.buttonBefore}
              onClick={onAddClick}
            />
            <AddComponentButton
              index={index + 1}
              className={styles.buttonAfter}
              onClick={onAddClick}
            />
          </Fragment>
        )}
      </div>
    </ComponentContext.Provider>
  );
};
