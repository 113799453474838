import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './Badge.module.scss';

function Badge({ children, variant = 'grey', small = false, ...props }) {
  if (!children) {
    return null;
  }

  return (
    <span
      className={classNames(styles.badge, {
        [styles.grey]: variant === 'grey',
        [styles.green]: variant === 'green',
        [styles.small]: small,
      })}
      {...props}
    >
      {children}
    </span>
  );
}

Badge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  variant: PropTypes.oneOf(['grey', 'green']),
  small: PropTypes.bool,
};

export { Badge };
