import React, { useContext } from 'react';

import { Link, NavLink } from 'react-router-dom';
import { Dropdown } from '@schibsted-svp/react-ui';

import { UserContext } from '../../../contexts/UserContext';

import styles from './Menu.module.scss';

function Menu() {
  const { user } = useContext(UserContext);

  return (
    <nav className={styles.Menu}>
      <NavLink
        to={`/pages`}
        className={styles.MenuItem}
        activeClassName={styles.MenuItemActive}
      >
        Pages
      </NavLink>
      <NavLink
        to={`/components`}
        className={styles.MenuItem}
        activeClassName={styles.MenuItemActive}
      >
        Components
      </NavLink>
      <NavLink
        to={`/tags`}
        className={styles.MenuItem}
        activeClassName={styles.MenuItemActive}
      >
        Tags
      </NavLink>
      <NavLink
        to={`/assets`}
        className={styles.MenuItem}
        activeClassName={styles.MenuItemActive}
      >
        Assets
      </NavLink>
      <NavLink
        to={`/categories`}
        className={styles.MenuItem}
        activeClassName={styles.MenuItemActive}
      >
        Categories
      </NavLink>
      <NavLink
        to={`/editors`}
        className={styles.MenuItem}
        activeClassName={styles.MenuItemActive}
      >
        Editors
      </NavLink>
      <Dropdown variant="standard" label="Features">
        <Link to="/pictures">
          <Dropdown.Item itemKey="2">Promotion picture</Dropdown.Item>
        </Link>
        {user?.role === 'admin' && (
          <Link to="/users">
            <Dropdown.Item itemKey="1">Users</Dropdown.Item>
          </Link>
        )}
      </Dropdown>
    </nav>
  );
}

Menu.propTypes = {};

export { Menu };
