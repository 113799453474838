import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';
import { PageType } from '@vgtv/api-client/lib/page';

import { PageContext } from '../../../contexts/PageContext';
import { CategoriesSelect } from '../../Form/CategoriesSelect/CategoriesSelect';
import { UsePageTaxonomyCheckbox } from '../DataSource/EndpointParamsPicker/EndpointParamsForm/UsePageTaxonomyCheckbox/UsePageTaxonomyCheckbox';
import { FiltersPicker } from '../../FiltersPicker/FiltersPicker';
import { Input } from '../../Form/Input/Input';

function FilteredListFields({ isStandalone }) {
  const { values: component, setFieldValue } = useFormikContext();
  const { page } = useContext(PageContext);

  const showCheckbox =
    isStandalone ||
    page.type === PageType.CATEGORY ||
    (page.type === PageType.TEMPLATE && page.forType === PageType.CATEGORY);

  const usePageTaxonomy = component?.options?.usePageTaxonomy ?? true;

  return (
    <Fragment>
      <CategoriesSelect
        label="Search within category"
        name="categoryId"
        disabled={usePageTaxonomy}
      />
      {showCheckbox && (
        <UsePageTaxonomyCheckbox
          setFieldValue={setFieldValue}
          taxonomy="category"
          checked={usePageTaxonomy}
        />
      )}
      <FiltersPicker
        filters={component.filters}
        onChange={(filters) => {
          setFieldValue('filters', filters);
        }}
      />
      <Input label="Search field placeholder" name="options.placeholder" />
    </Fragment>
  );
}

FilteredListFields.propTypes = {
  isStandalone: PropTypes.bool,
};

export { FilteredListFields };
