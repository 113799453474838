import React, { Fragment, type VFC } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Button } from '@schibsted-svp/react-ui';
import { pick } from 'lodash';

import { CenteredSpinner } from '../components/CenteredSpinner/CenteredSpinner';
import { ContainerHeader } from '../components/Container/ContainerHeader/ContainerHeader';
import { UserForm } from '../components/UserForm/UserForm';
import { useUser } from '../hooks/useUser';
import type { UserDBO } from '../services/api/users';
import { createUser, updateUser } from '../services/api/users';
import { withFetchStatus } from '../services/notifier';

type Props = {
  match: {
    params?: {
      userId: string;
    };
  };
};

export const User: VFC<Props> = ({ match }) => {
  const history = useHistory();

  const { params: { userId } = {} } = match;

  const { data: entity, isFetching, refetch } = useUser(userId);

  async function onSubmit(data: UserDBO) {
    const userId = data.id;

    if (!userId) {
      const user = await withFetchStatus(createUser, {
        success: 'User created',
        error: 'Failed to create user',
      })(pick(data, ['firstName', 'lastName', 'email']));

      history.replace(`/users/${user.id}`);
    } else {
      await withFetchStatus(updateUser, {
        success: 'User updated',
        error: 'Failed to update user',
      })(userId, pick(data, ['firstName', 'lastName', 'role']));
    }

    await refetch();
  }

  return (
    <Fragment>
      <ContainerHeader>
        <Link to="/users">
          <Button type="button" variant="standard">
            Back to users
          </Button>
        </Link>
      </ContainerHeader>
      {isFetching ? (
        <CenteredSpinner />
      ) : entity ? (
        <UserForm user={entity} onSubmit={onSubmit} />
      ) : null}
    </Fragment>
  );
};
