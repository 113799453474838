import React, { type VFC } from 'react';

import { TagsTable } from '../components/TagsTable/TagsTable';
import { useUsers } from '../hooks/useUsers';
import { useTagsEnrichments } from '../hooks/useTagsEnrichments';

export const Tags: VFC = () => {
  const { data: users = [] } = useUsers();

  const { data: enrichments = [], isLoading } = useTagsEnrichments();

  return (
    <TagsTable users={users} enrichments={enrichments} loading={isLoading} />
  );
};
