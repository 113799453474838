import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@schibsted-svp/react-ui';
import { Link } from 'react-router-dom';

import { Table } from '../Table/Table';
import { fetchTags } from '../../services/api/tags';

import { TagRow } from './TagRow/TagRow';

/**
 * @param {object} props
 * @param {EnrichmentDBO[]} props.enrichments
 * @param {boolean} props.loading
 * @return {React.ReactElement}
 */
function TagsTable({ users, enrichments, loading }) {
  const [filter, setFilter] = useState('');
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!enrichments.length) {
      return;
    }

    fetchTags({ ids: enrichments.map((enrichment) => enrichment.tagId) })
      .then((tags) => {
        setItems(
          enrichments.map((enrichment) => ({
            ...enrichment,
            tag: tags.find((tag) => tag.id === enrichment.tagId) ?? {
              title: '«missing tag»',
            },
          }))
        );
      })
      .catch(console.error);
  }, [enrichments]);

  const filtered = useMemo(
    () =>
      items.filter((item) =>
        item.tag.title.toLowerCase().includes(filter.toLowerCase())
      ),
    [items, filter]
  );

  return (
    <Fragment>
      <Table
        items={[...filtered]}
        renderHeader={() => (
          <tr>
            <th>Tag</th>
            <th></th>
            <th>Last update</th>
          </tr>
        )}
        renderRow={(enrichment) => (
          <TagRow
            user={users.find(({ id }) => id === enrichment.updatedBy)}
            enrichment={enrichment}
            key={enrichment.tagId}
          />
        )}
        renderActions={() => (
          <Link to="/tags/new">
            <Button type="button">Create a new tag enrichment</Button>
          </Link>
        )}
        onFilterChange={setFilter}
        loading={loading}
      />
    </Fragment>
  );
}

TagsTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  enrichments: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
};

export { TagsTable };
