import type { CnpTag, CnpTagId } from '@vgtv/api-client/lib/cnp_tag';

import { getProvider } from '../svpProvider';
import { getLogger } from '../logger';

import { isSuccessResponse, requestAPI } from './shared/requestAPI';
import { fetchTags } from './tags';
import { createUrlPartial, toSearchParams } from './shared/utils';

const logger = getLogger('enrichmentsApi');

export type EnrichmentDBO = {
  id: string;
  tagId: string;
  categoryId?: number;
  isProfile: boolean;
  images: { [key: string]: string };
  provider: 'vgtv' | 'ab' | 'brandstudio';
  createdAt: number;
  updatedAt: number;
};

export type EnrichedTag = CnpTag &
  Pick<
    EnrichmentDBO,
    'isProfile' | 'provider' | 'categoryId' | 'tagId' | 'images'
  >;

export async function fetchTagsEnrichments() {
  try {
    const result = await requestAPI<EnrichmentDBO[]>(
      `/${getProvider()}/admin/enrichments`
    );

    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to fetch tags enrichments');
    throw error;
  }
}

export async function fetchTagEnrichment(tagId: string) {
  try {
    const result = await requestAPI<EnrichmentDBO>(
      `/${getProvider()}/admin/enrichments/${tagId}`
    );

    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to fetch tag enrichment', { tagId });
    throw error;
  }
}

export async function createTagEnrichment(fields: Record<string, unknown>) {
  try {
    const result = await requestAPI<EnrichmentDBO>(
      `/${getProvider()}/admin/enrichments`,
      {
        method: 'POST',
        body: JSON.stringify(fields),
        headers: { 'Content-Type': 'application/json' },
      }
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to create tag enrichment', { fields });
    throw error;
  }
}

export async function updateTagEnrichment(
  tagId: string,
  fields: Record<string, unknown>
) {
  try {
    const result = await requestAPI<EnrichmentDBO>(
      `/${getProvider()}/admin/enrichments/${tagId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(fields),
        headers: { 'Content-Type': 'application/json' },
      }
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to update tag', { tagId, fields });
    throw error;
  }
}

export async function fetchProfiles(
  categoryId?: number
): Promise<EnrichedTag[]> {
  const params = toSearchParams({ categoryId });

  try {
    const enrichments = await requestAPI<EnrichmentDBO[]>(
      createUrlPartial(`/${getProvider()}/admin/enrichments/profiles`, params)
    );

    if (isSuccessResponse(enrichments)) {
      if (enrichments) {
        const ids = enrichments.map((enrichment) => enrichment.tagId);
        const tags = await fetchTags({ ids });
        if (tags && isSuccessResponse(tags)) {
          return tags.map((tag) => ({
            ...enrichments.find((enrichment) => enrichment.tagId === tag.id)!,
            ...tag,
            id: tag.id as CnpTagId,
          }));
        }
      }

      throw new Error('Failed to fetch enrichments or tags');
    }

    throw enrichments;
  } catch (error) {
    logger.error('Failed to fetch profiles', { categoryId });
    throw error;
  }
}
