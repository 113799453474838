import { isString, isNumber, isBool } from '../../../utils';

/**
 * Builds a query string in the format accepted by the SVP API for nested parameters.
 * For example given an object of parameters:
 * {
 *   interval: 'day',
 *   filter: {
 *     categoryId: 1,
 *     streamType: 'vod'
 *   }
 * }
 * it should output this query string:
 * &interval=day&filter=categoryId::1|streamType::vod
 * @param {Object} params
 * @return {string}
 */
export function buildSvpQueryParams(params = {}) {
  const queryParams = Object.keys(params).reduce((queryParams, param) => {
    const paramValue = params[param];

    if (paramValue === null) {
      return queryParams;
    }

    if (isString(paramValue) || isNumber(paramValue) || isBool(paramValue)) {
      queryParams.push(`${param}=${paramValue}`);
    } else {
      const subqueryParams = Object.keys(paramValue).reduce(
        (subqueryParams, key) => {
          const value = paramValue[key];

          if (key === 'flightTimesStart') {
            if (value === '{{current_timestamp}}') {
              subqueryParams.push(
                `flightTimes.start<=${Math.floor(Date.now() / 1000)}`
              );
            }
          } else if (value !== null) {
            if (/^(::|<>|<|>)/.test(value)) {
              subqueryParams.push(`${key}${value}`);
            } else {
              subqueryParams.push(`${key}::${value}`);
            }
          }

          return subqueryParams;
        },
        []
      );

      queryParams.push(`${param}=${subqueryParams.join('|')}`);
    }

    return queryParams;
  }, []);

  if (queryParams.length > 0) {
    return `&${queryParams.join('&')}`;
  } else {
    return '';
  }
}
