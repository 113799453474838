import React, { type VFC } from 'react';

import { pick } from 'lodash';
import { type SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import { Link } from 'react-router-dom';
import { Button } from '@schibsted-svp/react-ui';

import { updateAssetMetadata } from '../services/api/assets';
import { AssetForm } from '../components/AssetForm/AssetForm';
import { useAsset } from '../hooks/useAsset';
import { ContainerHeader } from '../components/Container/ContainerHeader/ContainerHeader';
import { withFetchStatus } from '../services/notifier';

type Props = {
  match: {
    params?: {
      assetId: string;
    };
  };
};

export const Asset: VFC<Props> = ({ match }) => {
  const { params: { assetId } = {} } = match;

  const {
    data: entity,
    isFetching,
    refetch,
  } = useAsset(assetId ? parseInt(assetId, 10) : undefined);

  async function onSubmit(asset: SvpAssetAdditional) {
    await withFetchStatus(updateAssetMetadata, {
      success: 'Asset updated successfully',
      error: 'Failed to update asset',
    })(
      entity!.id,
      pick(asset.additional.metadata, [
        'verticalPosterImage',
        'eventStartTime',
        'studioStartTime',
      ])
    );

    await refetch();
  }

  if (!entity) {
    return null;
  }

  return (
    <>
      <ContainerHeader>
        <Link to="/assets">
          <Button type="button" variant="standard">
            Back
          </Button>
        </Link>
      </ContainerHeader>

      <AssetForm asset={entity} onSubmit={onSubmit} isLoading={isFetching} />
    </>
  );
};
