import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { CenteredSpinner } from '../CenteredSpinner/CenteredSpinner';

import styles from './Slider.module.scss';

function Slider({ items, isFetching = false, renderItem, isSmall }) {
  return (
    <div
      className={classNames(styles.slider, {
        [styles.isLoading]: isFetching,
        [styles.isSmall]: isSmall,
      })}
    >
      {isFetching ? (
        <CenteredSpinner />
      ) : (
        items.map((item, index) => (
          <div className={styles.item} key={index}>
            {renderItem(item)}
          </div>
        ))
      )}
    </div>
  );
}

Slider.propTypes = {
  items: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  renderItem: PropTypes.func,
  isSmall: PropTypes.bool,
};

export { Slider };
