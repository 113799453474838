import React from 'react';

import { PagesTable } from '../components/PagesTable/PagesTable';
import { usePages } from '../hooks/usePages';

export const Pages = () => {
  const { isLoading, data: pages = [] } = usePages();

  return <PagesTable pages={pages} loading={isLoading} />;
};
