import React, { type VFC } from 'react';

import { ComponentsTable } from '../components/ComponentsTable/ComponentsTable';
import { useUsers } from '../hooks/useUsers';
import { useSharedComponents } from '../hooks/useSharedComponents';

export const Components: VFC = () => {
  const { data: users = [] } = useUsers();
  const { data: components = [], isLoading } = useSharedComponents();

  return (
    <ComponentsTable
      components={components}
      loading={isLoading}
      users={users}
    />
  );
};
