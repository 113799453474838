/**
 * Checks the availability of localStorage and does an extra check for safari private mode
 */
const isLocalStorageAvailable = (() => {
  if (typeof window !== 'undefined' && window.localStorage) {
    let isPrivateMode = false;

    try {
      localStorage.setItem('foo', 'foo');
      localStorage.removeItem('foo');
    } catch {
      isPrivateMode = true;
    }

    return !isPrivateMode;
  }

  return false;
})();

/**
 * Wrapper for localStorage that handles catching possible errors thrown by the storage
 * The function/IIFE isLocalStorageAvailable is run immediately on init and if it's false
 * we assume no local storage is available, and so subsequent calls to set/get will return undefined/null respectively
 */
const localStorageHelper = {
  setItem(key, item) {
    if (!isLocalStorageAvailable) {
      return;
    }

    try {
      localStorage.setItem(key, item);
    } catch {}
  },

  getItem(key) {
    if (!isLocalStorageAvailable) {
      return null;
    }

    return localStorage.getItem(key);
  },

  removeItem(key) {
    if (!isLocalStorageAvailable) {
      return;
    }

    localStorage.removeItem(key);
  },

  clear() {
    if (!isLocalStorageAvailable) {
      return;
    }

    localStorage.clear();
  },
};

export { localStorageHelper };
