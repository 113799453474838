import type { VFC } from 'react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { LabeledContainer, Button } from '@schibsted-svp/react-ui';
import { Link } from 'react-router-dom';
import { PageType } from '@vgtv/api-client/lib/page';
import { ComponentType } from '@vgtv/api-client/lib/component';

import { Select } from '../Form/Select/Select';
import { Form } from '../Form/Form';
import { Input } from '../Form/Input/Input';
import { CollapsibleSection } from '../Form/CollapsibleSection/CollapsibleSection';
import { SubmitButton } from '../Form/SubmitButton/SubmitButton';
import { CenteredSpinner } from '../CenteredSpinner/CenteredSpinner';
import { insertAtIndex, removeAtIndex, updateAtIndex } from '../../utils';
import { UpdatingCheckbox } from '../UpdatingCheckbox/UpdatingCheckbox';
import { PageContext } from '../../contexts/PageContext';
import { ContainerHeader } from '../Container/ContainerHeader/ContainerHeader';
import type {
  ComponentDBO,
  ComponentInput,
} from '../../services/api/components';
import {
  createComponent,
  updateComponent,
} from '../../services/api/components';
import type { ExpandedPage, PageDBO } from '../../services/api/pages';
import { updatePage } from '../../services/api/pages';
import { withFetchStatus } from '../../services/notifier';

import { ComponentsList } from './ComponentsList/ComponentsList';
import { PageTaxonomy } from './PageTaxonomy/PageTaxonomy';

import styles from './PageForm.module.scss';

type Props = {
  page: ExpandedPage;
  props?: Record<string, string | number | boolean>;
  isLoading?: boolean;
  onSubmit: (values: Omit<PageDBO, 'components'>) => Promise<void>;
  updatePage: (
    values: Pick<ExpandedPage, 'id'> & Partial<ExpandedPage>
  ) => Promise<void>;
  addComponent: (componentId: string, index: number) => Promise<void>;
  removeComponent: (componentId: string) => Promise<void>;
  refetchPage: () => Promise<unknown>;
};
export const PageForm: VFC<Props> = ({
  page,
  isLoading = false,
  onSubmit,
  updatePage: handlePageUpdate,
  addComponent,
  removeComponent,
  refetchPage,
}) => {
  const [components, setComponents] = useState<ComponentDBO[]>([]);

  const isNew = page.id === undefined;
  const isTemplate = !isNew && page.type === PageType.TEMPLATE;
  const useDefaultTemplate =
    page.useDefaultTemplate !== undefined ? page.useDefaultTemplate : true;

  useEffect(() => {
    if (!isNew) {
      setComponents(page.components);
    }
  }, [page, isNew]);

  async function onComponentAddClick(
    index: number,
    component: Partial<ComponentDBO>
  ) {
    const isNew = component.id === undefined;

    // otherwise all new components in a custom page have usePageTaxonomy: true, which is confusing
    if (
      isNew &&
      (page.type === PageType.CUSTOM ||
        (component.type === ComponentType.FILTERED_LIST &&
          page.type !== PageType.CATEGORY))
    ) {
      component.options = {
        ...(component.options || {}),
        usePageTaxonomy: false,
      };
    }

    const hasLayoutOption = component.type === ComponentType.LIST;

    if (isNew && hasLayoutOption) {
      component.options = {
        ...(component.options || {}),
        layout: 'vertical',
      };
    }

    if (component.id) {
      await addComponent(component.id, index);
    }

    setComponents(insertAtIndex(components, index, component as ComponentDBO));
  }

  async function onComponentRemoveClick(index: number) {
    const component = components[index];

    await removeComponent(component.id);

    setComponents(removeAtIndex(components, index));
  }

  async function onComponentFormSubmit(
    {
      id,
      ...payload
    }:
      | (ComponentInput & { id: undefined })
      | (Partial<ComponentInput> & { id: string }),
    index: number
  ) {
    let component;
    if (!id) {
      component = await withFetchStatus(createComponent, {
        success: 'Component created',
        error: 'Failed to create component',
      })({
        ...(payload as any),
        pageId: page.id,
        index,
      });
    } else {
      component = await withFetchStatus(updateComponent, {
        success: 'Component updated',
        error: 'Failed to update component',
      })(id, payload);
    }

    setComponents(updateAtIndex(components, index, component));
  }

  async function onFormSubmit(values: PageDBO) {
    // Skip empty array of components not to remove them from the page
    const { components, ...rest } = values;
    await onSubmit(rest);
  }

  async function updateUseDefaultTemplate(useDefaultTemplate: boolean) {
    return handlePageUpdate({ id: page.id, useDefaultTemplate });
  }

  async function onComponentFormCancel(
    index: number,
    componentDraft: Partial<ComponentDBO>
  ) {
    // remove the component from local state if it hasn't been created yet
    if (componentDraft.id === undefined) {
      setComponents(removeAtIndex(components, index));
    }
  }

  async function onComponentsReorder(data: ComponentDBO[]) {
    setComponents(data);

    await withFetchStatus(updatePage, {
      error: 'Failed to reorder components of page',
    })(page.id, {
      components: data.map(({ id }) => id),
    });

    await refetchPage();
  }

  const value = useMemo(() => ({ page }), [page]);

  return (
    <PageContext.Provider value={value}>
      <div className={styles.container}>
        <ContainerHeader>
          <Link to="/pages">
            <Button type="button" variant="standard">
              Back to pages
            </Button>
          </Link>
        </ContainerHeader>
        {isLoading ? (
          <CenteredSpinner />
        ) : (
          <div className={styles.formContainer}>
            <div className={styles.form}>
              {isTemplate ? (
                <Fragment>
                  <p>
                    This is the default template for <b>{page.forType} page</b>.
                  </p>
                  <br />
                  <p>
                    If the <b>Use default template</b> checkbox is checked on a{' '}
                    {page.forType} page
                    <br />
                    the following components will be used:
                  </p>
                </Fragment>
              ) : (
                <Form initialValues={page} onSubmit={onFormSubmit}>
                  <Select
                    label="Page type"
                    name="type"
                    options={[
                      { value: PageType.CUSTOM, label: 'Custom' },
                      { value: PageType.CATEGORY, label: 'Category' },
                      { value: PageType.TAG, label: 'Tag' },
                      { value: PageType.STORY, label: 'Story' },
                    ]}
                    allowEmpty={false}
                    disabled={!isNew}
                  />
                  <PageTaxonomy isNew={isNew} />
                  <CollapsibleSection
                    label="Meta tags (optional)"
                    initiallyCollapsed={false}
                  >
                    <Input label="Title" name="meta.title" size="small" />
                    <Input label="Keywords" name="meta.keywords" size="small" />
                    <Input
                      label="Description"
                      name="meta.description"
                      type="textarea"
                      size="small"
                    />
                  </CollapsibleSection>
                  <div className={styles.buttonsContainer}>
                    <SubmitButton>{isNew ? 'Create' : 'Save'}</SubmitButton>
                  </div>
                </Form>
              )}
            </div>
            <div className={styles.components}>
              <LabeledContainer label="Components">
                {!isTemplate && page.type !== PageType.CUSTOM && (
                  <UpdatingCheckbox
                    label="Use default template"
                    value={useDefaultTemplate}
                    disabled={isNew}
                    onChange={updateUseDefaultTemplate}
                  />
                )}
                <ComponentsList
                  components={components}
                  onComponentAddClick={onComponentAddClick}
                  onComponentRemoveClick={onComponentRemoveClick}
                  onComponentsReorder={onComponentsReorder}
                  onComponentFormSubmit={onComponentFormSubmit}
                  onComponentFormCancel={onComponentFormCancel}
                  isDisabled={isNew}
                />
              </LabeledContainer>
            </div>
          </div>
        )}
      </div>
    </PageContext.Provider>
  );
};
