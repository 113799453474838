import { getProvider } from '../svpProvider';
import { getLogger } from '../logger';

import { isSuccessResponse, requestAPI } from './shared/requestAPI';

const logger = getLogger('editorsApi');

export type EditorDBO = {
  id: string;
  name: string;
  image: string;
  provider: 'vgtv' | 'ab' | 'brandstudio';
};

async function fetchEditors() {
  try {
    const result = await requestAPI<EditorDBO[]>(
      `/${getProvider()}/admin/editors`
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to fetch editors');
    throw error;
  }
}

async function fetchEditor(editorId: EditorDBO['id']) {
  try {
    const result = await requestAPI<EditorDBO>(
      `/${getProvider()}/admin/editors/${editorId}`
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to fetch the list of editors');
    throw error;
  }
}

async function createEditor(fields: Pick<EditorDBO, 'name' | 'image'>) {
  try {
    const result = await requestAPI<EditorDBO>(
      `/${getProvider()}/admin/editors`,
      {
        method: 'POST',
        body: JSON.stringify(fields),
        headers: { 'Content-Type': 'application/json' },
      }
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to create editor', { fields });
    throw error;
  }
}

async function updateEditor(
  editorId: EditorDBO['id'],
  fields: Partial<Pick<EditorDBO, 'name' | 'image'>>
) {
  try {
    const result = await requestAPI<EditorDBO>(
      `/${getProvider()}/admin/editors/${editorId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(fields),
        headers: { 'Content-Type': 'application/json' },
      }
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to update editor', { editorId, fields });
    throw error;
  }
}

export { fetchEditors, fetchEditor, createEditor, updateEditor };
