import { config } from '../config.js';

import { datadogLogs } from './datadog';

export const getLogger = (loggerName) => {
  const logger = datadogLogs.createLogger(loggerName, {
    level: config.datadog.logs.level,
  });

  return Object.fromEntries(
    ['error', 'warn', 'log', 'info', 'debug'].map((level) => [
      level,
      (message, extras) => {
        console[level](`[${loggerName}]:`, message, extras);
        logger[level](message, extras);
      },
    ])
  );
};
