import React from 'react';
import PropTypes from 'prop-types';

import { isFunction } from '../../utils';
import { Separator } from '../Separator/Separator';
import { CenteredSpinner } from '../CenteredSpinner/CenteredSpinner';

import { usePagination } from './TablePagination/usePagination';
import { TableFilter } from './TableFilter/TableFilter';
import { TablePagination } from './TablePagination/TablePagination';

import styles from './Table.module.scss';

function Table({
  items = [],
  fixedItems = [],
  renderHeader,
  renderRow,
  pageLimit = 10,
  onFilterChange,
  renderActions,
  loading = false,
}) {
  const pagination = usePagination(items, pageLimit);

  const { paginated } = pagination;

  const hasFilter = isFunction(onFilterChange);
  const hasActions = isFunction(renderActions);

  return (
    <div>
      <header className={styles.tableHeader}>
        {hasFilter && (
          <div className={styles.tableFilter}>
            <TableFilter onFilterChange={onFilterChange} />
          </div>
        )}
        {hasActions && renderActions()}
      </header>
      {(hasFilter || hasActions) && <Separator />}
      <table className={styles.table} cellPadding={0} cellSpacing={0}>
        <thead className={styles.tableHead}>{renderHeader()}</thead>
        <tbody className={styles.tableBody}>
          {paginated.map((item) => renderRow(item))}
          {fixedItems.map((item) => renderRow(item))}
        </tbody>
      </table>
      {loading && <CenteredSpinner />}
      <TablePagination {...pagination} />
    </div>
  );
}

Table.propTypes = {
  items: PropTypes.array.isRequired,
  fixedItems: PropTypes.array,
  renderHeader: PropTypes.func.isRequired,
  renderRow: PropTypes.func.isRequired,
  pageLimit: PropTypes.number,
  onFilterChange: PropTypes.func,
  renderActions: PropTypes.func,
  loading: PropTypes.bool,
};

export { Table };
