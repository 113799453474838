import React, { Fragment, type VFC } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Button } from '@schibsted-svp/react-ui';
import { pick } from 'lodash';

import type { EditorDBO } from '../services/api/editors';
import { createEditor, updateEditor } from '../services/api/editors';
import { CenteredSpinner } from '../components/CenteredSpinner/CenteredSpinner';
import { ContainerHeader } from '../components/Container/ContainerHeader/ContainerHeader';
import { EditorForm } from '../components/EditorForm/EditorForm';
import { useEditor } from '../hooks/useEditor';
import { withFetchStatus } from '../services/notifier';

type Props = {
  match: {
    params?: {
      editorId: string;
    };
  };
};

export const Editor: VFC<Props> = ({ match }) => {
  const history = useHistory();
  const { params: { editorId } = {} } = match;

  const { data: entity, isFetching, refetch } = useEditor(editorId);

  async function onSubmit(data: EditorDBO) {
    const editorId = data.id;
    const fields = pick(data, ['name', 'image']);

    if (!editorId) {
      const editor = await withFetchStatus(createEditor, {
        success: 'Editor created',
        error: 'Failed to create editor',
      })(fields);

      history.replace(`/editors/${editor.id}`);
    } else {
      await withFetchStatus(updateEditor, {
        success: 'Editor updated successfully',
        error: 'Failed to update editor',
      })(editorId, fields);
    }

    await refetch();
  }

  return (
    <Fragment>
      <ContainerHeader>
        <Link to="/editors">
          <Button type="button" variant="standard">
            Back to editors
          </Button>
        </Link>
      </ContainerHeader>
      {isFetching ? (
        <CenteredSpinner />
      ) : entity ? (
        <EditorForm editor={entity} onSubmit={onSubmit} />
      ) : null}
    </Fragment>
  );
};
