import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';
import { Checkbox } from '@schibsted-svp/react-ui';
import get from 'lodash/get';

import styles from './SubscriptionsCheckbox.module.scss';

const options = [
  { label: 'Sports', name: 'sport' },
  { label: 'VG+', name: 'plus' },
];

function SubscriptionsCheckbox({ name }) {
  const { setFieldValue, values } = useFormikContext();

  const value = get(values, name);
  const subscription = useMemo(() => (value ? value.split(',') : []), [value]);

  function onChange(event, checkboxName) {
    const { checked } = event.target;
    if (checked) {
      const newSubscription = [...subscription, checkboxName];
      setFieldValue(name, newSubscription.join(','));
    } else {
      const newSubscription = subscription.filter((a) => a !== checkboxName);
      setFieldValue(
        name,
        newSubscription.join(',') ? newSubscription.join(',') : undefined
      );
    }
  }

  return (
    <div className={styles.container}>
      {options.map((checkbox) => (
        <Checkbox
          label={checkbox.label}
          key={checkbox.label}
          checked={subscription.includes(checkbox.name)}
          onChange={(event) => onChange(event, checkbox.name)}
        />
      ))}
    </div>
  );
}

SubscriptionsCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  checkedAccessFilter: PropTypes.string,
};

export { SubscriptionsCheckbox };
