import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@schibsted-svp/react-ui';
import { useFormikContext } from 'formik';

function SubmitButton({ children, ...props }) {
  const { isSubmitting = false } = useFormikContext();

  return (
    <Button type="submit" loading={isSubmitting} {...props}>
      {children}
    </Button>
  );
}

SubmitButton.propTypes = {
  children: PropTypes.string,
};

export { SubmitButton };
