import type { VFC } from 'react';
import React from 'react';

import { useFormikContext } from 'formik';
import { PageType } from '@vgtv/api-client/lib/page';

import { Input } from '../../Form/Input/Input';
import { CategoriesSelect } from '../../Form/CategoriesSelect/CategoriesSelect';
import { TagsSelect } from '../../Form/TagsSelect/TagsSelect';
import { StoriesSelect } from '../../Form/StoriesSelect/StoriesSelect';
import type { ExpandedPage } from '../../../services/api/pages';

type Props = { isNew?: boolean };

export const PageTaxonomy: VFC<Props> = ({ isNew = false }) => {
  const { values } = useFormikContext<ExpandedPage>();

  if (!values) {
    return null;
  }

  const { type } = values;

  switch (type) {
    case PageType.CUSTOM:
      return <Input label="Slug" name="slug" disabled={!isNew} />;
    case PageType.CATEGORY:
      return (
        <CategoriesSelect label="Category" name="category" disabled={!isNew} />
      );
    case PageType.TAG:
      return <TagsSelect label="Tag" name="tag" disabled={!isNew} />;
    case PageType.STORY:
      return <StoriesSelect label="Story" name="story" disabled={!isNew} />;
    default:
      return null;
  }
};
