import React from 'react';
import PropTypes from 'prop-types';

import { Thumbnail } from '../../../Thumbnail/Thumbnail';

import styles from './Category.module.scss';

function Category({ category, className }) {
  return (
    <div className={className}>
      <Thumbnail
        url={`${category.additional.image}?t[]=161x91q80`}
        alt={category.title}
      />
      <div className={styles.title}>{category.title}</div>
    </div>
  );
}

Category.propTypes = {
  category: PropTypes.shape({
    additional: PropTypes.shape({ image: PropTypes.string }),
    title: PropTypes.string,
  }),
  className: PropTypes.string,
};

export { Category };
