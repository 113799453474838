import React from 'react';

import { Notification } from '@schibsted-svp/react-ui';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { useOktaAuth } from './hooks/useOktaAuth';
import { Router } from './Router';
import { config } from './config';
import { LoginMessage } from './components/LoginMessage/LoginMessage';
import { UserContext } from './contexts/UserContext';
import { useCurrentUserFetch } from './hooks/useCurrentUserFetch';
import { CenteredSpinner } from './components/CenteredSpinner/CenteredSpinner';

import styles from './App.module.scss';

function App() {
  const { authenticated, expired } = useOktaAuth(
    `${config.apiHostname}/auth/login`
  );

  const { user } = useCurrentUserFetch();

  return (
    <UserContext.Provider value={{ user }}>
      <div className={styles.main}>
        {!authenticated ? (
          <LoginMessage />
        ) : user ? (
          <Router />
        ) : (
          <CenteredSpinner />
        )}
        {authenticated && expired && (
          <Notification
            message="Your session has expired. Refresh this page to login (any changes will be discarded)."
            intent="error"
            timeout={0}
          />
        )}
        <Notification.Container />
      </div>
      {user?.role === 'admin' && <ReactQueryDevtools />}
    </UserContext.Provider>
  );
}

export default App;
