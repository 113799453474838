import { type SvpCategoryAdditional } from '@vgtv/api-client/lib/svp_category';

import { getProvider } from '../svpProvider';

import { requestSVP } from './shared/requestSVP';

export async function fetchCategories(params: Record<string, string> = {}) {
  const json = await requestSVP<{
    _embedded: { categories: SvpCategoryAdditional[] };
  }>({
    provider: getProvider(),
    path: '/categories',
    params: {
      ...params,
      additional: 'true',
    },
  });

  return json._embedded ? json._embedded.categories : [];
}

export async function fetchCategoryById(categoryId: number) {
  return await requestSVP<SvpCategoryAdditional>({
    provider: getProvider(),
    path: `/categories/${categoryId}`,
    params: { additional: 'true' },
  });
}
