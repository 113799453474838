import type { DataType } from '@vgtv/api-client/lib/component';

import { getProvider } from '../svpProvider';
import { getLogger } from '../logger';

import { isSuccessResponse, requestAPI } from './shared/requestAPI';
import type { EditorDBO } from './editors';

const logger = getLogger('componentsApi');

type ComponentData = {
  type: DataType;
  source: {
    endpoint:
      | 'svp/categories'
      | 'svp/assets'
      | 'svp/search'
      | 'svp/most-seen'
      | 'svp/episodes'
      | 'staylive/channel'
      | '/profiles';
    params: Partial<{
      interval: string;
      categoryId: number;
      channelId: number;
      filter: Record<string, string>;
    }>;
  };
  preset2: string[];
};

export type ComponentFilter =
  | { type: 'category'; entityId: number }
  | { type: 'tag'; entityId: string }
  | { type: 'story'; entityId: string };

type ComponentOptions = Partial<{
  buttonLabel: string;
  hideSearchField: boolean;
  isFullFledged: boolean;
  layout: 'vertical' | 'horizontal';
  placeholder: string;
  sortByEpisodeNumber: boolean;
  sortByFlightTimes: boolean;
  usePageTaxonomy: boolean;
  useVerticalPosters: boolean;
}>;

type ComponentOverrides = Partial<{
  description: string;
  image: string;
  title: string;
  url: string;
}>;

export type ComponentDBO = {
  id: string;
  provider: 'vgtv' | 'ab' | 'brandstudio';

  type:
    | 'filtered-list'
    | 'list'
    | 'editorial'
    | 'slider'
    | 'splash'
    | 'scheduled'
    | 'carousel'
    | 'continue-watching';
  isShared: boolean;
  sharedName?: string;
  categoryId?: number;
  header?: string;
  content?: string;
  editor: EditorDBO['id'];
  options: ComponentOptions;
  overrides: ComponentOverrides;
  seasons?: { seasonNumber: number; title: string }[];
  filters?: ComponentFilter[];
  data: ComponentData;

  createdAt?: number;
  updatedAt?: number;
  updatedBy?: string;
};

export type ComponentInput = Pick<
  ComponentDBO,
  | 'type'
  | 'isShared'
  | 'sharedName'
  | 'categoryId'
  | 'header'
  | 'content'
  | 'editor'
  | 'options'
  | 'overrides'
  | 'seasons'
  | 'filters'
  | 'data'
>;

export async function createComponent(
  fields: ComponentInput & { pageId?: string; index?: number }
) {
  try {
    const result = await requestAPI<ComponentDBO>(
      `/${getProvider()}/components`,
      {
        method: 'POST',
        body: JSON.stringify(fields),
        headers: { 'Content-Type': 'application/json' },
      }
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to create component', { fields });
    throw error;
  }
}

export async function updateComponent(
  componentId: ComponentDBO['id'],
  fields: Partial<ComponentInput>
) {
  try {
    const result = await requestAPI<ComponentDBO>(
      `/${getProvider()}/components/${componentId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(fields),
        headers: { 'Content-Type': 'application/json' },
      }
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to update component', {
      componentId,
      fields,
    });
    throw error;
  }
}

export async function fetchComponent(componentId: string) {
  try {
    const result = await requestAPI<ComponentDBO>(
      `/${getProvider()}/components/${componentId}`
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to fetch component', { componentId });
    throw error;
  }
}

export async function fetchSharedComponents() {
  try {
    const result = await requestAPI<ComponentDBO[]>(
      `/${getProvider()}/components/shared`
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to fetch shared components');
    throw error;
  }
}
