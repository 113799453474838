import { useQuery } from '@tanstack/react-query';

import { fetchCategoryById } from '../services/svp';
import { withFetchStatus } from '../services/notifier';

const fetchCategoryWithStatus = withFetchStatus(fetchCategoryById, {
  error: 'Failed to fetch category',
});

export const useCategory = (categoryId?: number) => {
  return useQuery(
    ['category', categoryId],
    () => fetchCategoryWithStatus(categoryId!),
    { enabled: Boolean(categoryId), refetchOnWindowFocus: false }
  );
};
