export type Provider = 'vgtv' | 'ab' | 'brandstudio';

let currentProvider: Provider = 'vgtv';
let overriddenProvider = sessionStorage.getItem('provider') as Provider | null;

export const setProvider = (provider: Provider) => {
  currentProvider = provider;
};

export const overrideProvider = (provider: Provider) => {
  sessionStorage.setItem('provider', provider);
  window.location.reload();
};

export const getProvider = () => {
  return overriddenProvider || currentProvider;
};
