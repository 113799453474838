import type { VFC } from 'react';
import React from 'react';

import type { FormikFormProps } from 'formik';
import { Formik, Form as FormikForm } from 'formik';

import type { ValidationError } from '../../services/ValidationError';

type Props = FormikFormProps & {
  children: React.ReactNode | ((formik: any) => React.ReactNode);
  initialValues?: Record<string, any>;
  onSubmit?: (values: any) => Promise<void>;
};

export const Form: VFC<Props> = ({
  children,
  initialValues = {},
  onSubmit,
  ...props
}) => {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, actions) => {
        try {
          await onSubmit?.(values);
        } catch (error) {
          actions.setErrors((error as ValidationError).errors);
        }
      }}
    >
      {typeof children === 'function' ? (
        (formik) => <FormikForm {...props}>{children(formik)}</FormikForm>
      ) : (
        <FormikForm {...props}>{children}</FormikForm>
      )}
    </Formik>
  );
};
