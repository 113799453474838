import type { SvpCategoryAdditional } from '@vgtv/api-client/lib/svp_category';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import { DataType } from '@vgtv/api-client/lib/component';

import type { PresetElement } from './PresetItem/PresetItem';

export const isCategory = (
  item: PresetElement
): item is SvpCategoryAdditional => item.hasOwnProperty('isSeries');
export const isAsset = (item: PresetElement): item is SvpAssetAdditional =>
  item.hasOwnProperty('streamType');

export const getItemDataType = (item: PresetElement, dataType: DataType) => {
  switch (dataType) {
    case DataType.PROFILES:
      return 'profile';
    case DataType.STORIES:
      return 'story';
    case DataType.ASSETS:
      return 'asset';
    case DataType.CATEGORIES:
      return 'category';
    case DataType.MIXED: {
      if (isCategory(item)) {
        return 'category';
      }
      if (isAsset(item)) {
        return 'asset';
      }
    }
  }

  return undefined;
};
