import React, { type VFC } from 'react';

import { useHistory } from 'react-router-dom';
import { mapValues, pick } from 'lodash';
import { type SvpCategoryAdditional } from '@vgtv/api-client/lib/svp_category';

import { updateCategoryMetadata } from '../services/api/categories';
import { CategoriesSearch } from '../components/PresetPicker/CategoriesSearch/CategoriesSearch';
import { CategoryForm } from '../components/CategoryForm/CategoryForm';
import { Form } from '../components/Form/Form';
import { useCategory } from '../hooks/useCategory';
import { withFetchStatus } from '../services/notifier';

type Props = {
  match: {
    params?: {
      categoryId: string;
    };
  };
};

export const Category: VFC<Props> = ({ match }) => {
  const history = useHistory();
  const { params: { categoryId } = {} } = match;

  const {
    data: entity,
    isFetching,
    refetch,
  } = useCategory(categoryId ? parseInt(categoryId, 10) : undefined);

  const onCategoryClick = function (category: SvpCategoryAdditional) {
    history.replace(`/categories/${category.id}`);
  };

  async function onSubmit(category: SvpCategoryAdditional) {
    await withFetchStatus(updateCategoryMetadata, {
      success: 'Category updated',
      error: 'Failed to update category',
    })(
      entity!.id,
      mapValues(
        pick(category.additional.metadata, ['verticalPoster']),
        ({ value, inherited }) => (inherited ? undefined : value)
      )
    );

    await refetch();
  }

  if (!categoryId) {
    return (
      <Form initialValues={{}}>
        <CategoriesSearch
          selectedCategories={[]}
          onAddClick={onCategoryClick}
        />
      </Form>
    );
  }

  if (!entity) {
    return null;
  }

  return (
    <CategoryForm
      category={entity}
      onSubmit={onSubmit}
      isLoading={isFetching}
    />
  );
};
