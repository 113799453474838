import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Field, useFormikContext } from 'formik';
import { DateTimeInput as SvpDateTimeInput } from '@schibsted-svp/react-ui';

import { FormField } from '../shared/FormField/FormField';

import './DateTimeInput.module.scss';
import get from 'lodash/get';

function isUnixTimestamp(time) {
  return time && time.toString().length === 10;
}

function dateToUnixTimestamp(date) {
  return Math.round(date.getTime() / 1000);
}

function DateTimeInput({ label, name, startDate, onClear, ...props }) {
  const { setFieldValue, values, touched } = useFormikContext();

  const isTouched = useMemo(() => touched[name], [touched, name]);

  // if the field is empty, but a startDate is set - use it as the initial value
  const value = useMemo(() => {
    const formValue = get(values, name);

    // we need to both set the startDate here visually and in the useEffect below to make it work
    if (!Boolean(formValue) && startDate && !isTouched) {
      return startDate;
    }

    return formValue;
  }, [isTouched, name, startDate, values]);

  useEffect(() => {
    const formValue = get(values, name);

    if (!Boolean(formValue) && startDate && !isTouched) {
      setFieldValue(name, dateToUnixTimestamp(startDate));
    }
  }, [isTouched, name, setFieldValue, startDate, values]);

  const date = useMemo(
    () =>
      Boolean(value)
        ? new Date(isUnixTimestamp(value) ? value * 1000 : value)
        : undefined,
    [value]
  );

  function onChange(date) {
    const seconds = date ? dateToUnixTimestamp(date) : undefined;

    setFieldValue(name, seconds);

    if (!seconds && typeof onClear === 'function') {
      onClear();
    }
  }

  return (
    <FormField label={label}>
      <Field {...props}>
        {({ field, meta }) => (
          <SvpDateTimeInput
            {...field}
            {...props}
            name={name}
            value={date}
            error={typeof meta.error === 'string' ? meta.error : undefined}
            onChange={onChange}
            datePickerProps={{
              disabledKeyboardNavigation: true,
              todayButton: 'Today',
              dateFormat: 'dd.MM.yyyy HH:mm',
              timeIntervals: 5,
              showTimeSelect: true,
              minDate: startDate,
              openToDate: startDate,
            }}
          />
        )}
      </Field>
    </FormField>
  );
}

DateTimeInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  startDate: PropTypes.object,
  onClear: PropTypes.func,
};

export { DateTimeInput };
