import type { ChangeEvent, VFC } from 'react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { DataType } from '@vgtv/api-client/lib/component';
import type { SvpCategoryAdditional } from '@vgtv/api-client/lib/svp_category';

import * as svp from '../../../services/svp';
import { Input } from '../../Form/Input/Input';
import { CenteredSpinner } from '../../CenteredSpinner/CenteredSpinner';
import { PresetItem } from '../PresetItem/PresetItem';

import styles from './CategoriesSearch.module.scss';

type Props = {
  selectedCategories: SvpCategoryAdditional[];
  onAddClick: (category: SvpCategoryAdditional) => void;
  onRemoveClick?: (category: SvpCategoryAdditional) => void;
  label?: string;
};

export const CategoriesSearch: VFC<Props> = ({
  selectedCategories = [],
  onAddClick,
  onRemoveClick,
  label = 'Search by title',
}) => {
  const [categories, setCategories] = useState<SvpCategoryAdditional[]>([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    async function fetchCategories() {
      const categories = await svp.fetchCategories();

      setCategories(categories);
    }
    fetchCategories();
  }, []);

  const filteredCategories = useMemo(() => {
    return (
      categories
        .filter(({ title }) =>
          title.toLowerCase().includes(filter.toLowerCase())
        )
        // Move archived categories to the bottom
        .sort((a, b) => Number(!a.showCategory) - Number(!b.showCategory))
    );
  }, [categories, filter]);

  return (
    <Fragment>
      <Input
        label={label}
        name="categoryTitle"
        value={filter}
        disabled={categories.length === 0}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = event.target.value;
          setFilter(value);
        }}
      />
      {categories.length === 0 && <CenteredSpinner />}
      <ul className={styles.categoriesList}>
        {filteredCategories.map((category) => {
          const isSelected = selectedCategories
            .map((presetItem) => presetItem.id)
            .includes(category.id);

          return (
            <li key={category.id}>
              <PresetItem
                item={category}
                dataType={DataType.CATEGORIES}
                isSelected={isSelected}
                onClick={() =>
                  isSelected ? onRemoveClick?.(category) : onAddClick(category)
                }
              />
            </li>
          );
        })}
      </ul>
    </Fragment>
  );
};
