import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Separator } from '../../Separator/Separator';

import styles from './ContainerHeader.module.scss';

function ContainerHeader({ children }) {
  return (
    <Fragment>
      <div className={styles.header}>{children}</div>
      <Separator />
    </Fragment>
  );
}

ContainerHeader.propTypes = {
  children: PropTypes.node,
};

export { ContainerHeader };
