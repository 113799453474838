import { useQuery } from '@tanstack/react-query';

import {
  fetchSharedComponents,
  type ComponentDBO,
} from '../services/api/components';
import { withFetchStatus } from '../services/notifier';

const fetchSharedComponentsWithStatus = withFetchStatus(fetchSharedComponents, {
  error: 'Failed to fetch shared components',
});

export const useSharedComponents = () => {
  return useQuery(['components', 'shared'], fetchSharedComponentsWithStatus, {
    initialData: [] as ComponentDBO[],
    refetchOnWindowFocus: false,
  });
};
