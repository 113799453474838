import type { VFC } from 'react';
import React, { useRef } from 'react';

import type { SelectSize } from '@schibsted-svp/react-ui/lib/es/Select/Select';
import type { FieldProps } from 'formik';
import { Field as FormikField } from 'formik';
import { Select as SvpSelect, Spinner } from '@schibsted-svp/react-ui';

import { FormField } from '../shared/FormField/FormField';

import './Select.module.scss';

type Option = { value: string; label: string };

type Props = {
  options: Option[];
  label: string;
  name: string;
  allowEmpty?: boolean;
  disabled?: boolean;
  size?: SelectSize;
  className?: string;
  onChange?: (data: any) => Promise<void>;
  onInputChange?: (query: string) => void;
  isLoading?: boolean;
  placeholder?: string;
  isMulti?: boolean;
};

const transformValueFromOptions = (
  value: string | string[],
  options: Record<string, Option>
) => {
  if (Array.isArray(value)) {
    return value.map((v) => options[v]);
  }

  return options[value];
};

export const Select: VFC<Props> = ({
  options,
  label,
  allowEmpty = true,
  disabled = false,
  isLoading,
  size,
  className,
  onChange,
  onInputChange,
  placeholder,
  isMulti,
  ...props
}) => {
  const optionsCache = useRef<Record<string, Option>>({});

  optionsCache.current = {
    ...optionsCache.current,
    ...options.reduce(
      (acc, item) => {
        acc[item.value] = item;
        return acc;
      },
      {} as Record<string, Option>
    ),
  };

  return (
    <FormField label={label} className={className}>
      <FormikField {...props} splitButton={false}>
        {({ field, form, meta }: FieldProps) => {
          // override the default behaviour since SvpSelect expects an object instead of a primitive value
          let value;

          if (field.value) {
            value = transformValueFromOptions(
              // temporary workaround for expanded category object
              typeof field.value === 'object' && 'id' in field.value
                ? field.value.id
                : field.value,
              optionsCache.current
            );
          }

          // use the first option if the select can't be empty
          if (!value && !allowEmpty) {
            form.setFieldValue(field.name, options[0].value);
          }

          return (
            <SvpSelect
              options={options}
              {...field}
              isDisabled={disabled}
              error={typeof meta.error === 'string' ? meta.error : undefined}
              value={value}
              splitButton={false}
              size={size}
              isClearable={allowEmpty}
              onInputChange={onInputChange}
              onChange={(option?: Option | Option[]) => {
                onChange?.(option);

                if (isMulti) {
                  if (Array.isArray(option) && option.length === 0) {
                    form.setFieldValue(field.name, null);
                  } else {
                    form.setFieldValue(
                      field.name,
                      (option as Option[]).map(({ value }) => {
                        return value;
                      })
                    );
                  }
                } else {
                  form.setFieldValue(
                    field.name,
                    option ? (option as Option).value : null
                  );
                }
              }}
              isLoading={isLoading}
              components={{
                LoadingIndicator: () => <Spinner size="small" />,
              }}
              placeholder={placeholder}
              isMulti={isMulti}
            />
          );
        }}
      </FormikField>
    </FormField>
  );
};
