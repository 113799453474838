import type { CnpStory } from '@vgtv/api-client/lib/cnp_story';

import { getProvider } from '../svpProvider';
import { getLogger } from '../logger';

import { isSuccessResponse, requestAPI } from './shared/requestAPI';
import { createUrlPartial, toSearchParams } from './shared/utils';

const logger = getLogger('storiesApi');

export const fetchStories = async ({
  query,
  ids,
}: {
  query?: string;
  ids?: string[];
}) => {
  if ((query && query.length < 3) || (ids && ids.length < 1)) {
    return [] as CnpStory[];
  }

  const params = toSearchParams({ query, ids });

  try {
    const result = await requestAPI<CnpStory[]>(
      createUrlPartial(`/${getProvider()}/admin/stories`, params)
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result.errors[0].message;
  } catch (error) {
    logger.error('Failed to fetch the list of stories');
    throw error;
  }
};

export const fetchStoryById = async (storyId: string) => {
  try {
    const result = await requestAPI<CnpStory>(
      `/${getProvider()}/admin/stories/${storyId}`
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to fetch story', { storyId });
    throw error;
  }
};
