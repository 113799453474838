import { useQuery } from '@tanstack/react-query';

import { fetchProfiles } from '../services/api/enrichments';
import { withFetchStatus } from '../services/notifier';

const fetchProfilesWithStatus = withFetchStatus(fetchProfiles, {
  error: 'Failed to fetch the profiles',
});

export const useProfiles = (categoryId?: number) => {
  return useQuery(['profiles'], () => fetchProfilesWithStatus(categoryId), {
    initialData: [],
    refetchOnWindowFocus: false,
  });
};
