import { useQuery } from '@tanstack/react-query';

import { fetchTagEnrichment } from '../services/api/enrichments';
import { withFetchStatus } from '../services/notifier';

const fetchTagEnrichmentWithStatus = withFetchStatus(fetchTagEnrichment, {
  error: 'Failed to fetch tag enrichment',
});

export const useTagEnrichment = (tagId?: string) => {
  return useQuery(
    ['enrichment', tagId],
    () => fetchTagEnrichmentWithStatus(tagId!),
    {
      enabled: Boolean(tagId),
      refetchOnWindowFocus: false,
    }
  );
};
