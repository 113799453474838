import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Provider } from '@vgtv/api-client';
import { UserRole } from '@vgtv/api-client/lib/user';

import { config } from '../../config';
import { UserContext } from '../../contexts/UserContext';
import { getProvider } from '../../services/svpProvider';

import { Menu } from './Menu/Menu';
import { SideMenu } from './SideMenu/SideMenu';
import { ProviderSelect } from './ProviderSelect/ProviderSelect';

import styles from './Header.module.scss';

const Logo = ({ provider }) => {
  let logoUrl;
  switch (provider) {
    case Provider.VGTV:
      logoUrl = '/images/vgtv-logo.svg';
      break;
    case Provider.AB:
      logoUrl = '/images/ab-logo.svg';
      break;
    case 'brandstudio':
      logoUrl = '/images/bs-logo.svg';
      break;
    default:
      logoUrl = '/images/stream-logo.svg';
  }

  return (
    <div className={styles.Logo}>
      <img src={logoUrl} alt={provider} />
      {config.env !== 'production' && (
        <span className={styles.EnvLabel}>{config.env}</span>
      )}
    </div>
  );
};

Logo.propTypes = {
  provider: PropTypes.string.isRequired,
};

function Header() {
  const { user } = useContext(UserContext);

  return (
    <div className={styles.Header}>
      <div className={styles.MenuWrapper}>
        {user && user.role === UserRole.ADMIN ? (
          <ProviderSelect>
            <Logo provider={getProvider()} />
          </ProviderSelect>
        ) : (
          <Logo provider={getProvider()} />
        )}
        <Menu />
      </div>
      <SideMenu />
    </div>
  );
}

export { Header };
