import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from '../../../Badge/Badge';

function DataSourceBadge({
  dataType,
  endpoint,
  hasPreset = false,
  small = false,
}) {
  return (
    <Badge variant="green" small={small}>
      {dataType}
      {!hasPreset && endpoint && ` (${endpoint})`}
      {hasPreset && ` «preset»`}
    </Badge>
  );
}

DataSourceBadge.propTypes = {
  dataType: PropTypes.string.isRequired,
  endpoint: PropTypes.string,
  hasPreset: PropTypes.bool,
  small: PropTypes.bool,
};

export { DataSourceBadge };
