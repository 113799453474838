import React from 'react';
import PropTypes from 'prop-types';

import { SubmitButton } from '../Form/SubmitButton/SubmitButton';
import { Form } from '../Form/Form';
import { Input } from '../Form/Input/Input';
import { Select } from '../Form/Select/Select';

import styles from './UserForm.module.scss';

const roles = [
  { label: 'Admin', value: 'admin' },
  { label: 'Journalist', value: 'journalist' },
];

function UserForm({ user, onSubmit }) {
  const isNew = user.id === undefined;

  return (
    <Form initialValues={user} onSubmit={onSubmit}>
      <Input label="First name" name="firstName" />
      <Input label="Last name" name="lastName" />
      <Input label="Email" name="email" disabled={!isNew} />
      {<Select label="Role" name="role" options={roles} disabled={isNew} />}
      <div className={styles.buttons}>
        <SubmitButton>{isNew ? 'Create user' : 'Save'}</SubmitButton>
      </div>
    </Form>
  );
}

UserForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { UserForm };
