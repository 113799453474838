import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@schibsted-svp/react-ui';
import { Link } from 'react-router-dom';

import { Table } from '../Table/Table';

import { EditorRow } from './EditorRow/EditorRow';

/**
 * @param {object} props
 * @param {object[]} props.editors
 * @param {boolean} props.loading
 * @return {React.ReactElement}
 */
function EditorsTable({ editors, loading }) {
  const [filter, setFilter] = useState('');

  const filtered = useMemo(
    () =>
      editors.filter((editor) =>
        editor.name.toLowerCase().includes(filter.toLowerCase())
      ),
    [editors, filter]
  );

  return (
    <Fragment>
      <Table
        items={[...filtered]}
        renderHeader={() => (
          <tr>
            <th width="1%">Image</th>
            <th>Name</th>
            <th>Updated</th>
          </tr>
        )}
        renderRow={(editor) => <EditorRow editor={editor} key={editor.id} />}
        renderActions={() => (
          <Link to="/editors/new">
            <Button type="button">Create a new editor</Button>
          </Link>
        )}
        onFilterChange={setFilter}
        loading={loading}
      />
    </Fragment>
  );
}

EditorsTable.propTypes = {
  editors: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

export { EditorsTable };
