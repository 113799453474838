import { toast } from 'react-toastify';

import { ValidationError } from './ValidationError';

const toastFromError = (errorMessage: string | undefined, error: Error) => {
  if (error instanceof ValidationError) {
    if (error.hasErrors()) {
      toast.warn(`There are errors in the form you've submitted`);
    } else {
      toast.warn(error.message);
    }
  } else {
    toast.error(errorMessage || error.message, {
      autoClose: false,
    });
  }
};

export const withFetchStatus =
  <A extends Parameters<any>, R>(
    fetchFn: (...args: A) => Promise<R>,
    options: { success?: string; error?: string }
  ) =>
  async (...args: A) => {
    return fetchFn(...args)
      .then((result: R) => {
        if (options.success) {
          toast.success(options.success);
        }

        return result;
      })
      .catch((error: Error) => {
        toastFromError(options.error, error);

        throw error;
      });
  };
