import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { fetchCurrentUser } from '../services/api/users';
import { setProvider } from '../services/svpProvider';
import { withFetchStatus } from '../services/notifier';

const fetchCurrentUserWithStatus = withFetchStatus(fetchCurrentUser, {
  error: 'Failed to fetch your session data. Try reloading this page',
});

export const useCurrentUserFetch = () => {
  const { data: user } = useQuery(
    ['user', 'current'],
    fetchCurrentUserWithStatus
  );

  useEffect(() => {
    if (user) {
      setProvider(user.provider);
    }
  }, [user]);

  return { user };
};
