import React, { Fragment, type VFC } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Button } from '@schibsted-svp/react-ui';
import { omit, pick } from 'lodash';

import { CenteredSpinner } from '../components/CenteredSpinner/CenteredSpinner';
import { ContainerHeader } from '../components/Container/ContainerHeader/ContainerHeader';
import { TagForm } from '../components/TagForm/TagForm';
import { useTagEnrichment } from '../hooks/useTagEnrichment';
import type { EnrichmentDBO } from '../services/api/enrichments';
import {
  createTagEnrichment,
  updateTagEnrichment,
} from '../services/api/enrichments';
import { withFetchStatus } from '../services/notifier';

type Props = {
  match: {
    params?: {
      tagId: string;
    };
  };
};

export const Tag: VFC<Props> = ({ match }) => {
  const history = useHistory();
  const { params: { tagId } = {} } = match;
  const {
    data: entity,
    isFetching,
    refetch,
  } = useTagEnrichment(tagId === 'new' ? undefined : tagId);

  async function onSubmit(data: EnrichmentDBO) {
    const isNew = data.id === undefined;

    const blob = pick(data, [
      'categoryId',
      'tagId',
      'isProfile',
      'images',
      'description',
    ]);

    if (isNew) {
      const enrichment = await withFetchStatus(createTagEnrichment, {
        success: 'Enrichment created',
        error: 'Failed to create tag enrichment',
      })(blob);

      history.replace(`/tags/${enrichment!.tagId}`);
    } else {
      await withFetchStatus(updateTagEnrichment, {
        success: 'Enrichment updated',
        error: 'Failed to update enrichment',
      })(blob.tagId!, omit(blob, 'tagId'));
      await refetch();
    }
  }

  return (
    <Fragment>
      <ContainerHeader>
        <Link to="/tags">
          <Button type="button" variant="standard">
            Back to tags
          </Button>
        </Link>
      </ContainerHeader>
      {isFetching ? (
        <CenteredSpinner />
      ) : (
        <TagForm enrichment={entity} onSubmit={onSubmit} />
      )}
    </Fragment>
  );
};
