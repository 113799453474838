import React from 'react';
import PropTypes from 'prop-types';

import { Thumbnail } from '../../../Thumbnail/Thumbnail';

import styles from './Story.module.scss';

function Story({ asset, className }) {
  return (
    <div className={className}>
      <Thumbnail
        url={`${asset.images.main}?t[]=180q80`}
        alt={asset.title}
        aspectRatio="9/16"
      />
      <div className={styles.title}>{asset.title}</div>
    </div>
  );
}

Story.propTypes = {
  asset: PropTypes.shape({
    images: PropTypes.shape({ main: PropTypes.string.isRequired }),
    title: PropTypes.string,
  }),
  className: PropTypes.string,
};

export { Story };
