import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';
import { DataType } from '@vgtv/api-client/lib/component';

import { CollapsibleSection } from '../../Form/CollapsibleSection/CollapsibleSection';
import { Input } from '../../Form/Input/Input';
import styles from '../ComponentForm.module.scss';
import { ImageUploadInput } from '../../Form/ImageUploadInput/ImageUploadInput';

function SplashFields({ inputsSize }) {
  const { values } = useFormikContext();

  const dataType = values?.data?.type;
  const dataTypeLabel = dataType === DataType.ASSETS ? 'asset' : 'category';

  return (
    <Fragment>
      <CollapsibleSection label="Options" initiallyCollapsed={false}>
        <Input label="Button label" name="options.buttonLabel" size="small" />
      </CollapsibleSection>
      <CollapsibleSection
        label="Overrides (optional)"
        initiallyCollapsed={false}
      >
        <p className={styles.splashOverrides}>
          Use these to override the selected {dataTypeLabel} details, or use
          them when no {dataTypeLabel} is selected.
        </p>
        <Input type="text" label="Title" name="overrides.title" size="small" />
        <Input
          type="text"
          label="External url"
          name="overrides.url"
          size="small"
        />
        <Input
          type="textarea"
          label="Description"
          name="overrides.description"
          size={inputsSize}
        />
        <ImageUploadInput
          name="overrides.image"
          label="Image"
          aspectRatio={16 / 9}
        />
      </CollapsibleSection>
    </Fragment>
  );
}

SplashFields.propTypes = {
  inputsSize: PropTypes.string.isRequired,
};

export { SplashFields };
