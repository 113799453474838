import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './Container.module.scss';

function Container({ children, className }) {
  return (
    <div className={classNames(styles.container, className)}>{children}</div>
  );
}

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
};

export { Container };
