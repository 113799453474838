import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Cropper } from 'react-cropper';
import { Button, Checkbox, Dialog } from '@schibsted-svp/react-ui';

import 'cropperjs/dist/cropper.css';
import styles from './ImageCropper.module.scss';

function ImageCropper({
  imageDataUrl,
  onCrop,
  onClose,
  aspectRatio = 16 / 9,
  buttonLabel = 'Crop',
}) {
  const cropperRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPNG, setIsPNG] = useState(false);

  const isOpen = Boolean(imageDataUrl);

  function onButtonClick() {
    cropperRef.current.cropper.getCroppedCanvas().toBlob(
      async (blob) => {
        setIsSubmitting(true);
        await onCrop(blob);
        setIsSubmitting(false);
        setIsPNG(false);
      },
      isPNG ? 'image/png' : 'image/jpeg'
    );
  }

  return (
    <Dialog heading="Crop image" isOpen={isOpen}>
      <Dialog.Section>
        <Cropper
          src={imageDataUrl}
          ref={cropperRef}
          aspectRatio={aspectRatio}
          autoCrop
          autoCropArea={1}
        />
        <div className={styles.checkbox}>
          <Checkbox
            onChange={(event) => setIsPNG(event.target.checked)}
            checked={isPNG}
            label="Save as PNG"
          />
        </div>
      </Dialog.Section>
      <Dialog.Section variant="darker" mode="flexRight">
        <Button type="button" onClick={onClose} variant="standard">
          Cancel
        </Button>
        <Button type="button" onClick={onButtonClick} loading={isSubmitting}>
          {buttonLabel}
        </Button>
      </Dialog.Section>
    </Dialog>
  );
}

ImageCropper.propTypes = {
  imageDataUrl: PropTypes.string,
  onCrop: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  aspectRatio: PropTypes.number,
  buttonLabel: PropTypes.string,
};

export { ImageCropper };
