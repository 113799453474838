import type { VFC } from 'react';
import React, { useState } from 'react';

import { DataType } from '@vgtv/api-client/lib/component';
import { Button, Dialog, LabeledContainer } from '@schibsted-svp/react-ui';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import type { SvpCategoryAdditional } from '@vgtv/api-client/lib/svp_category';

import SortableList from '../SortableList/SortableList';
import type { EnrichedTag } from '../../services/api/enrichments';
import type { ComponentDBO } from '../../services/api/components';

import { PresetItem, type PresetElement } from './PresetItem/PresetItem';
import { CategoriesSearch } from './CategoriesSearch/CategoriesSearch';
import { AssetsSearch } from './AssetsSearch/AssetsSearch';
import { ProfilesSearch } from './ProfilesSearch/ProfilesSearch';
import { StoriesSearch } from './StoriesSearch/StoriesSearch';

import styles from './PresetPicker.module.scss';

type Props = {
  dataType: ComponentDBO['data']['type'];
  presetItems: PresetElement[];
  singleItem?: boolean;
  onChange: (preset: PresetElement[]) => void;
};

export const PresetPicker: VFC<Props> = ({
  dataType,
  presetItems = [],
  onChange,
  singleItem = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const preset = presetItems;

  function onEditButtonClick() {
    setIsOpen(true);
  }

  function onClearButtonClick() {
    onChange([]);
  }

  function onDialogClose() {
    setIsOpen(false);
  }

  function onRemoveClick(item: PresetElement) {
    onChange(preset.filter(({ id }) => id !== item.id));
  }

  function onAddClick(item: PresetElement) {
    if (singleItem) {
      onChange([item]);
    } else {
      onChange([...preset, item]);
    }
  }

  const buttonLabel = `${preset.length > 0 ? 'Edit' : 'Choose'} ${dataType} ${
    preset.length === 0 ? 'manually' : ''
  }`;

  return (
    <LabeledContainer label="Data Preset">
      <div className={styles.container}>
        {preset.length > 0 && (
          <SortableList
            className={styles.sortableList}
            compact
            items={preset}
            renderItem={(item: PresetElement) => (
              <PresetItem
                item={item}
                dataType={dataType}
                isSelected
                isDraggable
                onClick={onRemoveClick}
              />
            )}
            onChange={onChange}
            keyProperty={'id'}
          />
        )}
        <div className={styles.buttons}>
          <Button
            size="small"
            type="button"
            variant="danger"
            onClick={onClearButtonClick}
            disabled={preset.length === 0}
          >
            Remove all
          </Button>
          <Button
            size="small"
            type="button"
            variant="standard"
            onClick={onEditButtonClick}
          >
            {buttonLabel}
          </Button>
        </div>
        <Dialog isOpen={isOpen} onClose={onDialogClose} heading={buttonLabel}>
          <Dialog.Section>
            {(dataType === DataType.CATEGORIES ||
              dataType === DataType.MIXED) && (
              <CategoriesSearch
                selectedCategories={preset as SvpCategoryAdditional[]}
                onAddClick={onAddClick}
                onRemoveClick={onRemoveClick}
                label={
                  dataType === DataType.MIXED
                    ? 'Search by title (categories)'
                    : undefined
                }
              />
            )}
            {(dataType === DataType.ASSETS || dataType === DataType.MIXED) && (
              <AssetsSearch
                selectedAssets={preset as SvpAssetAdditional[]}
                onAddClick={onAddClick}
                onRemoveClick={onRemoveClick}
                label={
                  dataType === DataType.MIXED
                    ? 'Search by title (assets)'
                    : undefined
                }
              />
            )}
            {dataType === DataType.PROFILES && (
              <ProfilesSearch
                selectedProfiles={preset as EnrichedTag[]}
                onRemoveClick={onRemoveClick}
                onAddClick={onAddClick}
              />
            )}
            {dataType === DataType.STORIES && (
              <StoriesSearch
                selectedStories={preset as SvpAssetAdditional[]}
                onRemoveClick={onRemoveClick}
                onAddClick={onAddClick}
              />
            )}
          </Dialog.Section>
          <Dialog.Section variant="darker" mode="flexRight">
            <Button type="button" onClick={onDialogClose} variant="standard">
              Close
            </Button>
          </Dialog.Section>
        </Dialog>
      </div>
    </LabeledContainer>
  );
};
