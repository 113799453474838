import React from 'react';
import PropTypes from 'prop-types';

import { ImageUploadInput } from '../Form/ImageUploadInput/ImageUploadInput';
import { SubmitButton } from '../Form/SubmitButton/SubmitButton';
import { Form } from '../Form/Form';
import { Input } from '../Form/Input/Input';

import styles from './EditorForm.module.scss';

function EditorForm({ editor, onSubmit }) {
  const isNew = editor.id === undefined;

  return (
    <Form initialValues={editor} onSubmit={onSubmit}>
      <Input label="Name" name="name" />
      <ImageUploadInput label="Image" name="image" aspectRatio={1} />
      <div className={styles.buttons}>
        <SubmitButton>{isNew ? 'Create editor' : 'Save'}</SubmitButton>
      </div>
    </Form>
  );
}

EditorForm.propTypes = {
  editor: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { EditorForm };
