import { useQuery } from '@tanstack/react-query';

import { fetchTagById } from '../services/api/tags';
import { withFetchStatus } from '../services/notifier';

const fetchTagWithStatus = withFetchStatus(fetchTagById, {
  error: 'Failed to fetch tag',
});

export const useTag = (tagId: string) => {
  return useQuery(['tag', tagId], () => fetchTagWithStatus(tagId), {
    enabled: Boolean(tagId),
    refetchOnWindowFocus: false,
  });
};
