import React from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';

function FormValuesProvider({
  component: Component,
  valuesPropName = 'values',
  ...props
}) {
  const { values } = useFormikContext();

  const componentProps = { ...props, [valuesPropName]: values };

  // render the component passing the form values as [valuesProp] property and spreading remaining props
  return <Component {...componentProps} />;
}

FormValuesProvider.propTypes = {
  component: PropTypes.elementType.isRequired,
  valuesPropName: PropTypes.string,
};

export { FormValuesProvider };
