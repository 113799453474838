import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@schibsted-svp/react-ui';

import styles from './Filter.module.scss';

function Filter({ filter, onRemoveClick }) {
  const title = filter[filter.type].title;

  return (
    <div className={styles.filter}>
      <span className={styles.type}>{filter.type}:</span>
      <span className={styles.title}>{title}</span>
      <Button
        type="button"
        variant="standard"
        size="compact"
        className={styles.button}
        onClick={() => onRemoveClick(filter)}
      >
        × Remove
      </Button>
    </div>
  );
}

Filter.propTypes = {
  filter: PropTypes.object.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};

export { Filter };
