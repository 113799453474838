import React from 'react';

import PropTypes from 'prop-types';

import { fetchTagById, fetchTags } from '../../../services/api/tags';
import { AsyncSelect } from '../AsyncSelect/AsyncSelect';

const mapItemToOption = ({ id, title }) => ({
  value: id.toString(),
  label: title,
});

const searchTags = (query) => fetchTags({ query });

function TagsSelect(props) {
  return (
    <AsyncSelect
      fetchMany={searchTags}
      fetchOne={fetchTagById}
      mapItemToOption={mapItemToOption}
      {...props}
    />
  );
}

TagsSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  allowEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['big', 'small', 'compact']),
  className: PropTypes.string,
};

export { TagsSelect };
