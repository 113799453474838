import type { SvpCategoryAdditional } from '@vgtv/api-client/lib/svp_category';
import type { CnpTag } from '@vgtv/api-client/lib/cnp_tag';
import type { CnpStory } from '@vgtv/api-client/lib/cnp_story';
import type { PageType } from '@vgtv/api-client/lib/page';

import { getProvider } from '../svpProvider';
import { getLogger } from '../logger';

import type { ComponentDBO } from './components';
import { isSuccessResponse, requestAPI } from './shared/requestAPI';

const logger = getLogger('pagesApi');

export type PageDBO = {
  id: string;
  type: PageType;
  forType?: PageType.CATEGORY | PageType.TAG | PageType.STORY;
  entityId?: string | number;
  slug?: string;
  components: string[];
  useDefaultTemplate: boolean;

  meta?: {
    title?: string;
    description?: string;
    keywords?: string;
  };

  updatedBy: string;
  createdAt: number;
  updatedAt: number;
};

export type ExpandedPage = Omit<PageDBO, 'components'> & {
  category?: SvpCategoryAdditional;
  tag?: CnpTag;
  story?: CnpStory;
  components: ComponentDBO[];
};

export async function fetchPages() {
  try {
    const result = await requestAPI<ExpandedPage[]>(`/${getProvider()}/pages`);
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to fetch the list of pages');
    throw error;
  }
}

export async function fetchPage(pageId: PageDBO['id']) {
  try {
    const result = await requestAPI<ExpandedPage>(
      `/${getProvider()}/pages/${pageId}`
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch {
    logger.error('Failed to fetch page', { pageId });
    return null;
  }
}

export async function createPage(fields: Partial<ExpandedPage>) {
  try {
    const result = await requestAPI<ExpandedPage>(`/${getProvider()}/pages`, {
      method: 'POST',
      body: JSON.stringify(fields),
      headers: { 'Content-Type': 'application/json' },
    });
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to create page', { fields });
    throw error;
  }
}

export async function updatePage(
  pageId: PageDBO['id'],
  fields: Partial<ExpandedPage | PageDBO>
) {
  try {
    const result = await requestAPI<ExpandedPage>(
      `/${getProvider()}/pages/${pageId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(fields),
        headers: { 'Content-Type': 'application/json' },
      }
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to update page', { pageId, fields });
    throw error;
  }
}

export async function removePageComponent(
  pageId: PageDBO['id'],
  componentId: ComponentDBO['id']
) {
  try {
    const result = await requestAPI<ExpandedPage>(
      `/${getProvider()}/pages/${pageId}/components/${componentId}`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      }
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to remove page component', {
      error,
      pageId,
      componentId,
    });
    throw error;
  }
}

export async function addPageComponent(
  pageId: PageDBO['id'],
  componentId: ComponentDBO['id'],
  index: number
) {
  try {
    const result = await requestAPI<ExpandedPage>(
      `/${getProvider()}/pages/${pageId}/components/${componentId}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ index }),
      }
    );
    if (isSuccessResponse(result)) {
      return result!;
    }
    throw result;
  } catch (error) {
    logger.error('Failed to add page component', {
      pageId,
      componentId,
    });
    throw error;
  }
}
