import { capitalizeFirstLetter } from '../utils';
import type { ComponentDBO } from '../services/api/components';

function getComponentTypeLabel(type: ComponentDBO['type']) {
  if (type === 'filtered-list') {
    return 'Filtered List';
  }

  if (type === 'continue-watching') {
    return 'Continue Watching';
  }

  return capitalizeFirstLetter(type);
}

export { getComponentTypeLabel };
