import { useQuery } from '@tanstack/react-query';

import { fetchComponent } from '../services/api/components';
import { withFetchStatus } from '../services/notifier';

const fetchComponentWithStatus = withFetchStatus(fetchComponent, {
  error: 'Failed to fetch component',
});

export const useComponent = (componentId?: string) => {
  return useQuery(
    ['component', componentId],
    () => fetchComponentWithStatus(componentId!),
    { enabled: Boolean(componentId), refetchOnWindowFocus: false }
  );
};
