import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'formik';
import { Input as SvpInput } from '@schibsted-svp/react-ui';

import { FormField } from '../shared/FormField/FormField';

function Input({ label, ...props }) {
  return (
    <FormField label={label}>
      <Field {...props}>
        {({ field, meta }) => (
          <SvpInput
            {...field}
            {...props}
            error={typeof meta.error === 'string' ? meta.error : undefined}
          />
        )}
      </Field>
    </FormField>
  );
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export { Input };
