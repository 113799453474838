import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';
import { Button } from '@schibsted-svp/react-ui';
import get from 'lodash/get';

import { DateTimeInput } from '../../Form/DateTimeInput/DateTimeInput';
import { CollapsibleSection } from '../../Form/CollapsibleSection/CollapsibleSection';

import styles from './FlightTimes.module.scss';

function TogglableInput({ name, startDate, inputLabel, buttonLabel }) {
  const { values, setFieldValue } = useFormikContext();
  const value = get(values, name);
  const [isVisible, setIsVisible] = useState(Boolean(value));

  return isVisible ? (
    <DateTimeInput
      name={name}
      label={inputLabel}
      isClearable
      startDate={startDate}
      onClear={() => {
        setIsVisible(false);

        // overwriting with an empty string is the only way to clear metadata
        setFieldValue(name, '');
      }}
    />
  ) : (
    <Button
      variant="standard"
      className={styles.button}
      type="button"
      onClick={() => setIsVisible(true)}
    >
      {buttonLabel}
    </Button>
  );
}

TogglableInput.propTypes = {
  name: PropTypes.string.isRequired,
  startDate: PropTypes.object,
  inputLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
};

function FlightTimes() {
  const { values } = useFormikContext();

  const flightTimesStart = values?.flightTimes?.start;

  const startDate = new Date(flightTimesStart * 1000);

  return (
    <CollapsibleSection label="Live flight times" initiallyCollapsed={false}>
      <div className={styles.flightTimes}>
        <DateTimeInput
          label="Flight times start"
          name="flightTimes.start"
          readOnly
        />
        <TogglableInput
          startDate={startDate}
          name="additional.metadata.studioStartTime"
          inputLabel="Event studio start"
          buttonLabel="Set custom studio start"
        />
        <TogglableInput
          startDate={startDate}
          name="additional.metadata.eventStartTime"
          inputLabel="Actual event start"
          buttonLabel="Set custom event start"
        />
      </div>
    </CollapsibleSection>
  );
}

export { FlightTimes };
